/** Allgemeine Hilfs- und Konvertierungsfunktionen */
import { ListData } from '../interfaces/list-data';
import { GroupSettings } from '../interfaces/group-settings';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { LazyLoadEvent } from 'primeng';
import { TableColumn } from '../interfaces/table-column';
export class HelperClass {

  /** Key erzeugen
   * @param initKey
   */
  public createZAPinit(initKey: string) {
    if (initKey === '[default]') {
      return environment.scope + '!' + atob('aW5pdHB3') + '01#';
    }
    return initKey;
  }

  /** Höhe des Seiten-Wrappers z.B. zur Anzeige des Loading-Spinners
   * @param winHeight
   * @param offset
   */
  getWrapperHeight(winHeight: number, offset: number = 0): string {
    let scrollHeight: string;
    winHeight += offset;
    if (winHeight < 100) {
      winHeight = 100;
    }
    scrollHeight = (winHeight) + 'px';
    return scrollHeight;
  }

  /** Scrollhöhe für eine statische Tabelle berechnen
   * @param winHeight
   */
  getTableScrollHeight(winHeight: number): string {
    let scrollHeight: string;
    (winHeight > 580) ? winHeight -= 480 : winHeight = 100;
    scrollHeight = ( winHeight ) + 'px';
    return scrollHeight;
  }

  /** Die Gesamtbreite der fixierten Spalten ermitteln
   * @param cols
   */
  getFrozenColumnsWidth(cols: TableColumn[]): string {
    let fw: number = 0;
    cols.map( col => {
      fw += parseInt(col.width, 10);
    });
    return fw.toString() + 'px';
  }

  /** Paragraph-Tags aus Quill-Editor HTML entfernen, wegen doppelter Umbrüche
   * @param htmlText
   */
  public replaceQuillTags(htmlText: string): string {
    htmlText = htmlText.replace(/<p><br><\/p>/g, '<br>');
    htmlText = htmlText.replace(/<p>/g, '');
    htmlText = htmlText.replace(/<\/p>/g, '<br>');
    return htmlText;
  }

  /** Search-Params Objekt für das Lazy-Loading, zur Übergabe an die HTTP-Params für die Services
   * @param event
   */
  public lazyLoadParams(event: LazyLoadEvent): string {
    /* Spring-Boot benötigt die Seite */
    let page: number =  Math.floor(event.first / event.rows);
    return JSON.stringify({
      first: event.first,
      rows: event.rows,
      page: page,
      sortfield: event.sortField,
      sortorder: event.sortOrder,
      filters: event.filters
    });
  }

  /** Wandelt einen deutschen Datums-String in ein Date-Objekt um
   *
   * @param {string} stringDate
   * @returns {Date}
   * @constructor */
  public StringToDate(stringDate: string): Date {
    try {
      let parts: string[];
      let dayMonthYear: string[];
      let isoDate: string;
      /* ggf. Datum und Uhrzeit trennen */
      parts = stringDate.split(' ');
      dayMonthYear = parts[0].split('.');
      isoDate = dayMonthYear[2] + '-' + dayMonthYear[1] + '-' + dayMonthYear[0];
      return new Date(Date.parse(isoDate));
    } catch (e) {
      return null;
    }
  }

  public DateToString(date: Date, time: boolean = false): string {
    if (time) {
      return formatDate(date, 'dd.MM.yyyy HH:mm:ss', 'de');
    } else {
      return formatDate(date, 'dd.MM.yyyy', 'de');
    }
  }

  /** J oder N zu ja bzw. nein konvertieren
   * @param {string} ynIn
   * @returns {string} */
  public ynToYesNo(ynIn: string): string {
    switch (ynIn.toLowerCase()) {
      case 'j':
        return 'ja';
      default:
        return 'nein';
    }
  }

  /** ja oder nein zu true oder false
   * @param {string} ynIn
   * @returns {string} */
  public ynToTrueFalse(ynIn: string): boolean {
    switch (ynIn.toLowerCase()) {
      case 'ja':
        return true;
      default:
        return false;
    }
  }

  /** ja oder nein zu ListData-Item für DropDown konvertieren
   * @param {string} ynIn
   * @returns {ListData} */
  public ynToListData(ynIn: string): ListData {
    switch (ynIn.toLowerCase()) {
      case 'ja':
        return {name: 'ja', code: 1};
      default:
        return {name: 'nein', code: 2};
    }
  }

  /** true oder false zu ListData-Item für DropDown konvertieren
   * @param {string} ynBool
   * @returns {ListData} */
  public boolToListData(ynBool: boolean): ListData {
    if (ynBool) {
      return {name: 'ja', code: 1};
    } else {
      return {name: 'nein', code: 2};
    }
  }

  /** Bestimmten Wert der Gruppeneinstellungen auslesen
   * @param key
   * @param settings
   * @param def */
  public getGroupSettingsValue(key: string, settings: GroupSettings[], def: string = ''): string {
    if ( settings.length > 0 ) {
      if (settings.find(item => item.key === key)) {
        return settings.find(item => item.key === key).value;
      }
    }
    return def;
  }

  /** Tabellenwerte die nicht vorhanden sind dürfen nicht formatiert werden IE11 wirft sonst Fehler
   * @param rowData
   * @param fieldName
   */
  dataIsDefined(rowData: any, fieldName: string): boolean {
    try {
      return (rowData[fieldName] !== null && rowData[fieldName] !== undefined);
    } catch (e) {
      return false;
    }
  }

  /** Hilfsfunktion zur Datumsprüfung bei HTML-Formatierung über Date-Pipe
   * bei Individuellen Metadaten benötigt
   * @param data
   */
  isValidDate(data: any): boolean {
    try {
      let tmpDate: Date = new Date(data);
      if (tmpDate.getDate()) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  /** Mime-Types der freigegebenen Dateitypen */
  public getDocTypes(docType: string = 'ALL'): string {
    let mimeTypes: string = '';
    switch (docType) {
      case 'ALL':
        mimeTypes = 'application/pdf,';
        mimeTypes += 'application/vnd.adobe.xdp+xml,';
        mimeTypes += '.xdp,';
        mimeTypes += 'application/msword,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.template,';
        mimeTypes += 'application/vnd.ms-word.document.macroEnabled.12,';
        mimeTypes += 'application/vnd.ms-word.template.macroEnabled.12';
        break;
      case 'PDF':
        mimeTypes = 'application/pdf,';
        mimeTypes += 'application/vnd.adobe.xdp+xml,';
        mimeTypes += '.xdp,';
        break;
      case 'XDP' || 'TBX':
        mimeTypes = 'application/vnd.adobe.xdp+xml,';
        mimeTypes += '.xdp,';
        break;
      case 'DOC':
        mimeTypes = 'application/msword,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.template,';
        mimeTypes += 'application/vnd.ms-word.document.macroEnabled.12,';
        mimeTypes += 'application/vnd.ms-word.template.macroEnabled.12';
        break;
      case 'ATTACH':
        mimeTypes = 'application/pdf,';
        mimeTypes += 'application/vnd.adobe.xdp+xml,';
        mimeTypes += '.xdp,';
        mimeTypes += 'application/msword,';
        mimeTypes += 'application/msexcel,';
        mimeTypes += 'application/mspowerpoint,';
        mimeTypes += 'application/vnd.ms-powerpoint,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.wordprocessingml.template,';
        mimeTypes += 'application/vnd.ms-word.document.macroEnabled.12,';
        mimeTypes += 'application/vnd.ms-word.template.macroEnabled.12,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument. spreadsheetml.sheet,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.presentationml.presentation,';
        mimeTypes += 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,';
        mimeTypes += 'application/vnd.ms-powerpoint.presentation.macroEnabled.12,';
        mimeTypes += 'application/xml,';
        mimeTypes += 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip,';
        mimeTypes += '.zip,';
        mimeTypes += 'image/gif,';
        mimeTypes += 'image/png,';
        mimeTypes += 'image/jpeg,';
        mimeTypes += 'image/bmp,';
        mimeTypes += 'image/svg+xml';
        break;
    }
    return mimeTypes;
  }

  /** Anhand der Dokumenttyp-Kurzbezeichnung die Langbezeichnung zurückliefern */
  public getDocTypeLabel(docTypeKey: string, plural: boolean = false): string {
    let doctype: string;
    switch (docTypeKey.toLowerCase()) {
      case 'pdf':
        (plural) ? doctype = 'PDF-Formulare' : doctype = 'PDF-Formular';
        break;
      case 'xdp':
        (plural) ? doctype = 'XDP-Vorlagen' : doctype = 'XDP-Vorlage';
        break;
      case 'tbx':
        (plural) ? doctype = 'XDP-Textbaustein' : doctype = 'XDP-Textbaustein';
        break;
      case 'doc':
        (plural) ? doctype = 'Word-Dokument' : doctype = 'Word-Dokument';
        break;
      default:
        doctype = 'unbekannt';
    }
    return doctype;
  }

  /** Definition des deutschen Kalenders */
  public calendarDe(): any {
    return {
      firstDayOfWeek: 1,
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
      monthNamesShort: [ 'Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
      today: 'Heute',
      clear: 'Löschen'
    };
  }

}
