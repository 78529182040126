import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem, Table } from 'primeng';
import { Subscription } from 'rxjs';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { XlsTableExport } from '../../../classes/xls-table-export';
import { FormData } from '../../../interfaces/form-data';
import { LastSelectedRow } from '../../../interfaces/last-selected-row';
import { PublicInstituteData } from '../../../interfaces/public-institute-data';
import { TableColumn } from '../../../interfaces/table-column';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { AuthorizationService } from '../../../services/authorization.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { ZapService } from '../../../services/zap.service';
import { TableFixedState } from '../../custom/table-title-fixed-columns/table-fixed-state';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-config-view-form-clients',
  templateUrl: './config-view-form-clients.component.html',
  styleUrls: ['./config-view-form-clients.component.css']
})
export class ConfigViewFormClientsComponent implements OnInit, AfterViewInit, OnDestroy {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Höhe des Wrappers z.B. zur Anzeige des Loading-Spinners */
  wrapperHeightPx: string;

  /** Flags */
  initializing: boolean;                        // Initialisierung wird durchgeführt
  loading: boolean;                             // Loading-Flag der Komponente für die HTML-Templates
  blockPage: boolean;                           // Block-UI Flag
  restoringTableState: boolean;                 // beim Wiederherstellen des TableState müssen die Change-Subscriptions der tabelle unterdrückt werden, sonst treten Event-Konflikte ein

  /** Parameter für die Turbo-Table */
  frozen: boolean;                      // Tabelle hat fixierte Spalten
  colHeight: string;                    // Style für Zeilenhöhe bei Tabelle mit fixierten Spalten
  dtrows: number;                       // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];              // Mögliche Auswahl für Anzahl der Zeilen pro Seite aus den Settings
  dtScrollVertical: boolean;            // Turbo-Table scollt Vertikal - Header bleiben statisch

  /** Daten für die TurboTable */
  @ViewChildren('ttable') turboTable: QueryList<Table>;
  ttID: string = 'ttconfigformclientsview';                   // Eindeutige ID der Tabelle für den Table-State-Provider
  ttStateStorage: string = 'session';                         // Storage für den TableState
  ttTitle: string = 'FA-Mandantenübersicht Forms-ID: ';       // Tabellenüberschrift, kann sich je nach ViewMode ändern
  tableChangedSubscription: Subscription;                     // Änderungs-Subscription auf die DataTable - wegen Lazy-Loading und fixierter Spalten
  scrollHeight: string;                                       // Bei Tabelle mit fixer Höhe (Table-Header scrollt nicht aus dem Bildschirm) muss die Höhe des Sceollbereichs festgelegt werden
  columns: TableColumn[];                                     // Array mit den Tabellenspalten
  frozenColumns: TableColumn[] = [];                          // Array mit den fixierten Tabellenspalten
  frozenWidth: string;                                        // Breite der fixierten Spalten (Summe) wird von Primeng-Table benötigt
  frozenHeaderHeight: string;                                 // Höhe der Header bei Fixierung (Hähe muss statisch sein, sonst gibt es einen Versatz bei mehrzeiligen Zellen)
  totalRecords: number = 0;                                   // Anzahl der Records in der Table insgesamt
  filteredRecords: number = 0;                                // Anzahl der Records bei aktiven Filtern
  first: number = 0;                                          // Nummer des ersten aktuell angezeigten Records in der Table (zur Berechnng der Seite benötigt - für paging)
  formData: FormData;                                         // Daten des Formulars zu dem die Mandanten-Übersicht angezeigt wird
  assignedClients: number[];                                  // Der FA zugewiesene Mandanten
  institutesData: PublicInstituteData[] = [];                 // Array mit den Tabellendaten
  filteredData: PublicInstituteData[] = [];                   // Array mit den gefilterten Tabellendaten
  selectedInstitute: PublicInstituteData = null;              // Daten der angewählten Tabellenzeile
  lastSelectedRow: LastSelectedRow;                           // Zuletzt angewählte Zeile (Für den TableState-Restore)
  ynOptions: SelectItem[] = [];                               // ja/nein Filter für Tabelle

  /** Settings für CSV Export*/
  csvDelimiter: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authService: AuthorizationService,
              public breadcrumbService: BreadcrumbService,
              public tableStateProvider: TableStateProvider,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private zapService: ZapService) {
  }

  ngOnInit() {
    /** Während die Komponente initialisiert wird - keine HTML-Template-Anzeige */
    this.loading = true;
    /** Höhen für den Wrapper (Loading-Spinner) kalkulieren */
    this.wrapperHeightPx = this.helper.getWrapperHeight(window.innerHeight, -300);
    /** Tabellenparameter */
    this.dtrows = +this.ffbSettings.getOptionValue('DTROWS_PFP', 10);
    this.dtScrollVertical = (this.ffbSettings.getOptionValue('DTSCROLL_PFP', false) === 'true');
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.ffbSettings.getOptionParameters('DTROWS_PFP', [5, 10, 25, 100]).map(value => +value);
    /* Falls der User die Tabellenhöhe auf die Contenthöhe beschränken will (Tabelle scrollt vertikal)... */
    if (this.dtScrollVertical) {
      this.scrollHeight = this.helper.getTableScrollHeight(window.innerHeight);
    } else {
      this.scrollHeight = 'auto';
    }
    /** Filter für Boolean Spalten */
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];
    /** Definition der Tabellenspalten abrufen
     *  Unterscheidung je nach Modus fixiert/nicht fixiert
     */
    let fixedState: TableFixedState = this.tableStateProvider.getTableFrozenState(this.ttID);
    this.frozen = fixedState.fixed;
    this.colHeight = fixedState.colHeight;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    } else {
      this.frozenColumns = [];
    }
    /** Breadcrumb - Initialisierung / Einblenden erst nach erfolgreicher Datenabfrage */
    this.breadcrumbService.setMenuItems([
      {label: 'Administration'},
      {label: 'Formularpool', routerLink: '/configformspool'},
      {label: 'FA-Mandantenübersicht'}
    ]);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(false);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /** Die vom Resize-Service ausgelösten Resize-Events abonnieren, falls der user in den Settings die Tabellenhöhe beschränkt hat */
    if (this.dtScrollVertical) {
      this.resizeSubscription = this.resizeService.onResize$.subscribe(win => {
        this.wrapperHeightPx = this.helper.getWrapperHeight(window.innerHeight, -300);
        this.scrollHeight = this.helper.getTableScrollHeight(win.innerHeight);
      });
    }
    /** Formular-ID ermitteln und die Formular und Mandantendaten abrufen */
    if (this.route.snapshot.params['formid']) {
      const subscription: Subscription = this.formsService.getForm(this.authService.getJWT(), this.route.snapshot.params['formid']).subscribe(data => {
        subscription.unsubscribe();
        this.formData = data;
        this.getFormClientList();
      }, (e: HttpErrorResponse) => {
        subscription.unsubscribe();
        if (e.status === 401) {
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen der FA-Daten',
            detail: 'Beim Abrufen der Daten für die Formularanwendung ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        }
      });
    } else {
      this.loading = false;
      this.messageService.add({
        sticky: true,
        severity: 'warn',
        summary: 'Fehler beim Abrufen des Mandanten',
        detail: 'Es wurde keine oder eine ungültige Formular-ID übergeben.<br>Die Mandanten konnten nicht abgerufen werden.'
      });
    }
  }

  /** Mittels Timeout dieses Event in einem eigenen Thread ausführen, damit gewährleistet ist
   *  dass zuvor alle HTML-Elemente vollständig aufgebaut werden konnten.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    });
  }

  ngOnDestroy(): void {
    /** Save Table State */
    /** Hier vorher Clear da sich die Liste immer ändern kann */
    this.tableStateProvider.clearTableState();
    this.lastSelectedRow = null;
    this.tableStateProvider.saveTableState(this.frozen, this.colHeight, this.lastSelectedRow);
    /** WICHTIG!!! - alle Subscriptions zurücksetzen */
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    if (this.tableChangedSubscription) {
      this.tableChangedSubscription.unsubscribe();
    }
  }

  /** Liste der Mandanten für die FA aus ZAP abrufen
   * @private
   */
  private getFormClientList() {
    /** Liste der Client-ID's aus PFP-Backend abrufen */
    const subscription: Subscription = this.formsService.getFormClientList(this.authService.getJWT(), this.formData.id).subscribe(result => {
      subscription.unsubscribe();
      this.assignedClients = result.payload;
      this.totalRecords = result.totalElements;
      if (result.successful) {
        /** Daten der Clients aus ZAP abrufen */
        const sub2: Subscription = this.zapService.getInstitutesList(this.authService.getJWT()).subscribe(data => {
          sub2.unsubscribe();
          /** Mapping der tatsächlich zugeordneten mandanten in die Tabellendaten */
          this.institutesData = [];
          this.assignedClients.map(id => {
            try {
              this.institutesData.push(data.find(client => client.id.toString() === id.toString()));
            } catch (e) {
            }
          });
          this.breadcrumbService.setToolbarVisible(true);
          this.loading = false;
        }, (e: HttpErrorResponse) => {
          sub2.unsubscribe();
          if (e.status === 401) {
            this.messageService.add({
              life: 3500,
              severity: 'error',
              summary: 'Session ist ungültig oder abgelaufen',
              detail: 'Sie werden automatisch abgemeldet.'
            });
            setTimeout(() => {
              this.router.navigate(['logout']);
            }, 3500);
          } else {
            this.breadcrumbService.setToolbarVisible(true);
            this.loading = false;
            console.log(e);
            this.messageService.add({
              sticky: true,
              severity: 'error',
              summary: 'Fehler beim Abrufen der Mandantendaten',
              detail: 'Beim Abrufen der Mandantendaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
            });
          }
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der FA.Mandantenliste',
          detail: 'Beim Abrufen der FA-Mandantenliste ist ein Fehler aufgetreten:<br>' + result.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.breadcrumbService.setToolbarVisible(true);
        this.loading = false;
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der FA-Mandantenliste',
          detail: 'Beim Abrufen der FA-Mandantenliste ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Die Mandantenübersicht
   * als CSV-Datei exportieren */
  exportClients2Csv(filtered: boolean) {
    let filename: string = 'PFP-FA-Mandantenübersicht-' + this.formData.dsvsId + '-' + this.helper.DateToString(new Date());
    let exportData: PublicInstituteData[];
    filtered ? exportData = this.filteredData : exportData = this.institutesData;
    if (exportData.length > 0) {
      /** Klasse für Excel-Export initialisieren, die Spalten-Definitionsliste übergeben */
      let frozenFlag: boolean = this.frozen;
      this.frozen = false;
      let csvExp: XlsTableExport = new XlsTableExport(this.defineTableColumns(false));
      for (let row of exportData) {
        csvExp.addRow(row);
      }
      csvExp.createExportFile(filename, this.csvDelimiter);
      this.frozen = frozenFlag;
    } else {
      this.messageService.add({
        life: 3500,
        severity: 'warn',
        summary: 'CSV-Datenexport',
        detail: 'Es sind keine Daten für den Export verfügbar!'
      });
    }
  }

  /** Die Zellenhöhe in der fixierten Tabellenansicht wurde geändert
   * @param colHeight
   */
  onColHeightChanged(colHeight) {
    this.colHeight = colHeight;
  }

  /** Funktionen für die Werkzeugleiste
   * @param item
   */
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.getFormClientList();
        break;
      case 'UNFILTER':
        this.tableStateProvider.clearTableState();
        this.changeToolbarItemStatus(true);
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
        break;
      case 'BACK':
        this.router.navigate(['/configformspool']);
        break;
      case 'EXPORT_FIlTERED':
        this.exportClients2Csv(true);
        break;
      case 'EXPORT_ALL':
        this.exportClients2Csv(false);
        break;
    }
  }

  /** Restore-Event wird von der Primeng Table getriggered */
  onTableStateRestore() {
    this.restoringTableState = true;
    if (this.tableStateProvider.activated) {
      setTimeout(() => {
        this.tableStateProvider.restoreTableState();
        if (this.selectedInstitute !== null && this.selectedInstitute !== undefined) {
          this.changeToolbarItemStatus(false);
        }
        this.restoringTableState = false;
      }, 100);
    } else {
      /* TableState zurücksetzen */
      this.restoringTableState = false;
      setTimeout(() => {
        this.tableStateProvider.clearTableState();
        this.filteredRecords = this.totalRecords;
      }, 100);
    }
  }

  /** Event - Tabellenzeile wurde angewählt - im Mandanten-Auswahlmodus (ASSIGN) kann dann
   *  sofort zur Zuweisung im Formularpool navigiert werden
   * @param event
   */
  onRowSelect(event) {
    this.changeToolbarItemStatus(false);
  }

  /** Event - Tabellenzeile wurde abgewählt */
  onRowUnselect(event) {
    this.changeToolbarItemStatus(true);
  }

  /** Filter-Event wurde ausgelöst
   * Object selectedCr muss zurückgesetzt werden */
  onFilter(event) {
    if (!this.restoringTableState) {
      this.selectedInstitute = null;
    }
    this.changeToolbarItemStatus(true);
    this.filteredData = event.filteredValue;
    this.filteredRecords = event.filteredValue.length;
    if (this.filteredRecords !== this.totalRecords) {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
      this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', false, true);
    } else {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
      this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
    }
  }

  /** Sort-Event wurde ausgelöst
   */
  onSort() {
    this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
  }

  /** Frozen-Columns (fixierte Spalten) wurde aktiviert/deaktiviert
   * @param state
   */
  onFixedChanged(state) {
    this.tableChangedSubscription.unsubscribe();
    /** dtrows zwischenspeichern und nach dem TableStateClear wiederherstellen */
    let dtrowsSave: number = this.tableStateProvider.ttable._rows;
    this.tableStateProvider.clearTableState();
    this.frozen = state.fixed;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    }
    this.colHeight = state.colHeight;
    /** dtrows wiederherstellen */
    this.dtrows = dtrowsSave;
    setTimeout(() => {
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      });
    }, 100);
  }

  /** Toolbar-Item Status generell setzen bei verschiedenen Events, z.B.
   *  beim Anwählen/Abwählen eines Records
   * @param disabled
   */
  private changeToolbarItemStatus(disabled: boolean) {
  }

  /** Initialisierung der Toolbar items
   * @private
   * @returns ToolbarItem[]
   */
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'REFRESH', icon: 'fas fas-refresh', disabled: false, tooltip: 'Übersicht aktualisieren', faicon: true});
    tbItems.push({id: 'UNFILTER', iconstack: ['fas fas-filter', 'fas fas-times fas-stacked'], disabled: true, tooltip: 'Alle Filter und Sortierung zurücksetzen', faicon: true});
    tbItems.push({id: 'BACK', icon: 'library_books', disabled: false, tooltip: 'Zurück zum Formularpool', faicon: false});
    /** CSV-Export */
    subItems.push({id: 'EXPORT_FIlTERED', iconstack: ['fas fas-file-excel-o', 'fas fas-filter fas-stacked'], disabled: true, tooltip: 'Gefilterte Tabelle exportieren', faicon: true});
    subItems.push({id: 'EXPORT_ALL', iconstack: ['fas fas-file-excel-o', 'fas fas-plus fas-stacked'], disabled: false, tooltip: 'Vollständige Tabelle exportieren', faicon: true});
    tbItems.push({id: 'EXPORT', icon: 'fas fas-file-excel-o', disabled: false, tooltip: 'Mandantenübersicht exportieren (csv)', faicon: true, submenu: subItems});
    subItems = [];
    return tbItems;
  }

  /** Tabellenspalten-Initialisierung (Unterscheidung zwischen fixierten und dynamischen Spalten).
   *  Die Primeng Turbotable benötigt die Breite der fixierten Spalten
   *  (frozenWidth wird über die helper-Klasse automatisch berechnet)
   * @param frozenCols
   */
  private defineTableColumns(frozenCols: boolean): TableColumn[] {
    this.frozenWidth = null;
    let cols: TableColumn[] = [];
    if ((this.frozen && frozenCols) || (!this.frozen && !frozenCols)) {
      cols.push({field: 'id', header: 'ID', sort: false, filter: '', hidden: true, style: 'left', width: '0'});
      cols.push({field: 'inst_name', header: 'Name Mandant', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '580px'});
      this.frozenWidth = this.helper.getFrozenColumnsWidth(cols);
      this.frozenHeaderHeight = '40px';
    }
    if (!frozenCols) {
      cols.push(
        {field: 'inst_strasse', header: 'Straße Nr.', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '380px'},
        {field: 'inst_plz', header: 'PLZ', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '100px'},
        {field: 'inst_ort', header: 'Ort', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '400px'}
      );
    }
    return cols;
  }

}
