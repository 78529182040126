import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ConfigViewClientsComponent } from './config-view-clients.component';
import { ConfirmationService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class ConfigViewClientsDeactivateGuard implements CanDeactivate<ConfigViewClientsComponent> {

  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(component: ConfigViewClientsComponent): Observable<boolean> | Promise<boolean> | boolean {

    if (component.viewMode === component.viewModeType.BATCHASSIGN && (component.institutesData.filter(item => item.isAssigned === true).length > component.assignedCompanyIDs.length)) {
      return new Observable((observer: Observer<boolean>) => {
        this.confirmationService.confirm({
          message: 'Die letzten Änderungen an der Zuweisung wurden noch nicht gespeichert.<br>Wollen Sie diese Seite jetzt verlassen?',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
              component.breadcrumbService.setToolbarVisible(true);
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return true;
    }
  }

}
