import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../../services/authorization.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'pfp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(public breadcrumbService: BreadcrumbService,
              private messageService: MessageService,
              public authService: AuthorizationService,
              public notificationService: NotificationService) { }

  ngOnInit() {
    /** Items in der Breadcrumb-Bar initialisieren (Breadcrumb-Verlauf und Werkzeugleiste) */
    this.breadcrumbService.setMenuItems([]);
    this.breadcrumbService.setToolbarVisible(false);
    /** Falls die Notifications noch nicht abgeholt wurden, dann jetzt die Initialisierung durchführen */
    if (!this.notificationService.isInitialized) {
      this.notificationService.initializeUserNotifications(this.authService.getJWT());
      console.log('Initialisiere Nachrichtendienst');
    }
  }
}
