import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, SelectItem, Table } from 'primeng';
import { Observable, Subject, Subscription } from 'rxjs';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { XlsTableExport } from '../../../classes/xls-table-export';
import { FormData } from '../../../interfaces/form-data';
import { LastSelectedRow } from '../../../interfaces/last-selected-row';
import { TableColumn } from '../../../interfaces/table-column';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { AuthorizationService } from '../../../services/authorization.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { TableFixedState } from '../../custom/table-title-fixed-columns/table-fixed-state';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-forms-pool-view',
  templateUrl: './forms-stock-view.component.html',
  styleUrls: ['./forms-stock-view.component.css']
})
export class FormsStockViewComponent implements OnInit, AfterViewInit, OnDestroy {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;

  /** Flags */
  initializing: boolean;                // Initialisierung wird durchgeführt
  loading: boolean;                     // Loading-Flag der Komponente für die HTML-Templates
  firstSessionCall: boolean;            // Neue Session - für TableStateProvider
  blockPage: boolean;                   // Block-UI Flag
  restoringTableState: boolean;         // beim Wiederherstellen des TableState müssen die Change-Subscriptions der tabelle unterdrückt werden, sonst treten Event-Konflikte ein
  fetchingData: boolean;                // Doppelten Aufruf des Services wegen LazyLoading verhindern

  /** Parameter für die Turbo-Table */
  frozen: boolean;                      // Tabelle hat fixierte Spalten
  colHeight: string;                    // Style für Zeilenhöhe bei Tabelle mit fixierten Spalten
  lazyLoad: boolean;                    // Lazy-Loading Option
  lazyOnInit: boolean;                  // Lazy Loading bei Initialisierung darf nicht bei Recall durchgeführt werden
  hasFilter: boolean;                   // nur für Lazy Loading, andere Summenanzeige im Tabellenfuß
  dtrows: number;                       // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];              // Mögliche Auswahl für Anzahl der Zeilen pro Seite aus den Settings
  dtScrollVertical: boolean;            // Turbo-Table scollt Vertikal - Header bleiben statisch

  /* Kalender-Einstellungen */
  yearrange: string;
  de: any;

  /** Daten für die TurboTable */
  @ViewChildren('ttable') turboTable: QueryList<Table>;
  ttID: string = 'ttpoolview';                                // Eindeutige ID der Tabelle für den Table-State-Provider
  ttStateStorage: string = 'session';                         // Storage für den TableState
  tableChangedSubscription: Subscription;                     // Änderungs-Subscription auf die DataTable - wegen Lazy-Loading und fixierter Spalten
  scrollHeight: string;                                       // Bei Tabelle mit fixer Höhe (Table-Header scrollt nicht aus dem Bildschirm) muss die Höhe des Sceollbereichs festgelegt werden
  columns: TableColumn[];                                     // Array mit den Tabellenspalten
  frozenColumns: TableColumn[] = [];                          // Array mit den fixierten Tabellenspalten
  frozenWidth: string;                                        // Breite der fixierten Spalten (Summe) wird von Primeng-Table benötigt
  frozenHeaderHeight: string;                                 // Höhe der Header bei Fixierung (Hähe muss statisch sein, sonst gibt es einen Versatz bei mehrzeiligen Zellen)
  totalRecords: number = 0;                                   // Anzahl der Records in der Table insgesamt
  first: number = 0;                                          // Nummer des ersten aktuell angezeigten Records in der Table (zur Berechnng der Seite benötigt - für paging)
  formsStockData: FormData[] = [];                            // Array mit den Tabellendaten
  exportStockData: FormData[] = [];                            // Array mit den Tabellendaten für den CSV-Export
  selectedForm: FormData;                                     // Daten der angewählten Tabellenzeile
  lastSelectedRow: LastSelectedRow;                           // Zuletzt angewählte Zeile (Für den TableState-Restore)
  ynOptions: SelectItem[] = [];                               // SelectItem für das 0/1 zu ja/nein - mapping in der Tabelle
  exportDataFetched = new Subject<boolean>();                 // Für die Subscription auf den Datenservice für den Export

  /** Settings für CSV Export*/
  csvDelimiter: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authService: AuthorizationService,
              public breadcrumbService: BreadcrumbService,
              public tableStateProvider: TableStateProvider,
              private messageService: MessageService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private fileDownloadService: FileDownloadService) {
  }

  ngOnInit() {
    /* Während die Komponente initialisiert wird - keine HTML-Template-Anzeige */
    this.loading = true;
    /** Flag zur Vermeidung des doppelten Aufrufs des Daten-Backends, abhängig
     *  vom gesetzten Status für die "statische Tabelle", da bei der Initialisierung
     *  der TurboTable der Event für den Datenabruf ausgelöst wird (wegen Lazy-Loading-Flag)
     */
    this.initializing = !this.tableStateProvider.activated;
    /* Bestandsübersicht ist immer lazy */
    this.lazyLoad = true;
    /* wenn die Tabelle in dieser Session noch nicht geöffnet war, dann muss der Lazy-Loading-Event
     * beim Öffnen der Komponente ausgeführt werden. */
    this.lazyOnInit = this.tableStateProvider.firstSessionCall(this.ttID);
    this.firstSessionCall = this.lazyOnInit;
    /* Filter für Boolean Spalten - Mapping zu ja/nein */
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];
    /** Tabellenparameter */
    this.dtrows = +this.ffbSettings.getOptionValue('DTROWS_PFP', 10);
    this.dtScrollVertical = (this.ffbSettings.getOptionValue('DTSCROLL_PFP', false) === 'true');
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.ffbSettings.getOptionParameters('DTROWS_PFP', [5, 10, 25, 100]).map(value => +value);
    /* Falls der User die Tabellenhöhe auf die Contenthöhe beschränken will (Tabelle Scrollt Vertikal)... */
    if (this.dtScrollVertical) {
      this.scrollHeight = this.helper.getTableScrollHeight(window.innerHeight);
    } else {
      this.scrollHeight = 'auto';
    }
    /** Definition der Tabellenspalten abrufen
     *  Unterscheidung je nach Modus fixiert/nicht fixiert
     */
    let fixedState: TableFixedState = this.tableStateProvider.getTableFrozenState(this.ttID);
    this.frozen = fixedState.fixed;
    this.colHeight = fixedState.colHeight;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    } else {
      this.frozenColumns = [];
    }
    /** Kalender - Initialisierung */
    this.de = this.helper.calendarDe();
    /** Breadcrumb - Initialisierung / Einblenden erst nach erfolgreicher Datenabfrage */
    /**Toolbar initialisierung*/
    this.breadcrumbService.setMenuItems([
      {label: 'Bestandsübersicht'}
    ]);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(false);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /** Die vom Resize-Service ausgelösten Resize-Events abonnieren, falls der user in den Settings die Tabellenhöhe beschränkt hat */
    if (this.dtScrollVertical) {
      this.resizeSubscription = this.resizeService.onResize$.subscribe(win => {
        this.scrollHeight = this.helper.getTableScrollHeight(win.innerHeight);
      });
    }
    /** Falls die Tabelle in dieser Session bereits geöffnet war, so darf
     * getUserDataLazy nicht automatisiert aufgerufen werden, da die Daten auf
     * Stand des letzten LazyLoadEvents aufgerufen werden müssen.
     * Wenn der TableState wiederhergestellt werden kann, wird der Abruf der Daten initialisiert.
     */
    if (!this.lazyOnInit) {
      this.getFormsDataLazy(this.tableStateProvider.getLazyLoadInitValue(this.ttID), true);
    }
  }

  /** Mittels Timeout dieses Event in einem eigenen Thread ausführen, damit gewährleistet ist
   *  dass zuvor alle HTML-Elemente vollständig aufgebaut werden konnten.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializing = false;
      this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    });
  }

  ngOnDestroy(): void {
    /** Save Table State */
    this.tableStateProvider.saveTableState(this.frozen, this.colHeight, this.lastSelectedRow);
    /** WICHTIG!!! - alle Subscriptions zurücksetzen */
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    if (this.tableChangedSubscription) {
      this.tableChangedSubscription.unsubscribe();
    }
  }

  /** In der Formularpool-Übersicht wird immer lazy-Loading verwendet
   * @param lazyLoadEvent = Event-Object der Primeng Table
   * @param restoreTable = Flag ob Table-State wiederhergestellt werden soll
   */
  getFormsDataLazy(lazyLoadEvent: LazyLoadEvent, restoreTable: boolean = false) {
    if (!this.fetchingData && !this.initializing) {
      this.fetchingData = true;
      this.tableStateProvider.lazyLoadEvent = lazyLoadEvent;
      /** Andere Footer-Anzeige wenn Filter vorhanden sind, siehe HTML-Template */
      try {
        if (lazyLoadEvent.hasOwnProperty('filters')) {
          this.hasFilter = (Object.keys(lazyLoadEvent.filters).length > 0);
        } else {
          this.hasFilter = false;
        }
      } catch (e) {
        this.hasFilter = false;
      }
      if (this.hasFilter) {
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', false, true);
      } else {
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
      }
      /** Forms-Service Formulardaten über lazy-Loading abrufen
       *  dieser Service wird auch für die Betsandsansicht verwendet, deshalb
       *  wird hier noch spezifisch die Route mit übergeben */
      const subscription: Subscription = this.formsService.getFormsLazy('forms', this.authService.getJWT(), this.helper.lazyLoadParams(lazyLoadEvent)).subscribe(data => {
        subscription.unsubscribe();
        if (data.successful) {
          this.formsStockData = data.payload;
          this.totalRecords = data.totalElements;
        } else {
          this.totalRecords = 0;
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen der Formulardaten',
            detail: 'Status: ' + data.message + '<br>' + data.details
          });
        }
        this.breadcrumbService.setToolbarVisible(true);
        this.loading = false;
        this.fetchingData = false;
        if (restoreTable) {
          this.restoreTableState();
        }
      }, (e: HttpErrorResponse) => {
        subscription.unsubscribe();
        this.totalRecords = 0;
        this.loading = false;
        this.fetchingData = false;
        if (e.status === 401) {
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          this.breadcrumbService.setToolbarVisible(true);
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen der Formulardaten',
            detail: 'Beim Abrufen der Formulardaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        }
      });
    }
  }

  /** Daten für den csv-Export abrufen
   * @param lazyLoadEvent
   */
  getFormsExportData(lazyLoadEvent: LazyLoadEvent): Observable<boolean> {
    let serviceRoute: string;
    const subscription: Subscription = this.formsService.getFormsLazy('forms', this.authService.getJWT(), this.helper.lazyLoadParams(lazyLoadEvent)).subscribe(data => {
      subscription.unsubscribe();
      if (data.successful) {
        this.exportStockData = data.payload;
        this.exportDataFetched.next(true);
      } else {
        this.exportStockData = [];
        this.exportDataFetched.next(false);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der Exportdaten',
          detail: 'Status: ' + data.message + '<br>' + data.details
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.exportDataFetched.next(false);
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der Exportdaten',
          detail: 'Beim Abrufen der Exportdaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
    return this.exportDataFetched.asObservable();
  }

  /** Download eines einzelnen Formularpakets (zip-datei mit war, pdf...)
   * @private
   */
  private downloadForm() {
    const subscription: Subscription = this.fileDownloadService.downloadFile(this.authService.getJWT(), this.selectedForm.id).subscribe(success => {
      subscription.unsubscribe();
      if (success === true) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Datei herunterladen',
          detail: 'Die Datei wird heruntergeladen'
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Herunterladen',
          detail: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten. <br>' + 'Datei-ID = ' + this.selectedForm.zipFile.id + '<br>Bitte wenden Sie sich an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Dateidownload',
          detail: e.message
        });
      }
    });
  }

  /** Download eines einzelnen Formularpakets (zip-datei mit war, pdf...)
   * @private
   */
  private downloadZipForm() {
    const subscription: Subscription = this.fileDownloadService.downloadZipFile(this.authService.getJWT()).subscribe(success => {
      subscription.unsubscribe();
      if (success === true) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Datei herunterladen',
          detail: 'Die Datei wird heruntergeladen'
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Herunterladen',
          detail: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten. <br>Bitte wenden Sie sich an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Dateidownload',
          detail: e.message
        });
      }
    });
  }

  /** Restore-Event wird von der Primeng Table getriggered */
  onTableStateRestore() {
    /* Nicht aufrufen, falls es noch keine Sessiondaten gibt */
    if (!this.firstSessionCall) {
      this.firstSessionCall = false;
      this.restoringTableState = true;
      if (this.tableStateProvider.activated) {
        setTimeout(() => {
          this.tableStateProvider.restoreTableState();
          if (this.selectedForm !== null && this.selectedForm !== undefined) {
            this.changeToolbarItemStatus(false);
          }
          this.restoringTableState = false;
        }, 100);
      } else {
        /* TableState zurücksetzen */
        this.restoringTableState = false;
        setTimeout(() => {
          this.tableStateProvider.clearTableState();
        }, 100);
      }
    }
  }

  /** Aufruf erfolgt beim TablestateResore event und auch nochmals wenn die
   * Daten gelesen wurden (aus ngOninit) falls das Lesen der Daten länger benötigt
   * und somit der restoreTableState noch vor dem tatsächlichen aufbau der tabelle erfolgt.
   */
  private restoreTableState() {
    setTimeout(() => {
      this.tableStateProvider.restoreTableState();
      this.dtrows = this.tableStateProvider.lazyLoadEvent.rows;
      if (this.tableStateProvider.lastSelectedRow !== null) {
        this.lastSelectedRow = this.tableStateProvider.lastSelectedRow;
        this.selectedForm = this.formsStockData.find(item => item.id === this.lastSelectedRow.rowID);
        this.changeToolbarItemStatus(false);
      } else {
        this.changeToolbarItemStatus(true);
      }
    }, 100);
  }

  /** Event - Tabellenzeile wurde angewählt
   * @param event
   */
  onRowSelect(event) {
    this.changeToolbarItemStatus(false);
    this.lastSelectedRow = {
      rowIndex: event.index,
      rowID: event.data.id
    };
  }

  /** Event - Tabellenzeile wurde abgewählt
   * @param event
   */
  onRowUnselect(event) {
    this.changeToolbarItemStatus(true);
    this.lastSelectedRow = null;
  }

  /** Eigene Filterung über das Backend
   * @param value
   * @param field
   * @param matchMode
   */
  customFilter(value: any, field: any, matchMode: any) {
    if (!this.restoringTableState) {
      this.selectedForm = null;
    }
    this.changeToolbarItemStatus(true);
    if (this.tableStateProvider.ttable.filterTimeout) {
      clearTimeout(this.tableStateProvider.ttable.filterTimeout);
    }
    if (!this.tableStateProvider.ttable.isFilterBlank(value)) {
      this.tableStateProvider.ttable.filters[field] = {value: value, matchMode: matchMode};
    } else if (this.tableStateProvider.ttable.filters[field]) {
      delete this.tableStateProvider.ttable.filters[field];
    }
    this.tableStateProvider.ttable.filterTimeout = setTimeout(() => {
      if (this.tableStateProvider.ttable.first > 0) {
        let filters: any = this.tableStateProvider.ttable.filters;
        this.tableStateProvider.ttable.lazy = false;   // damit beim Reset kein LazyLoad-Event ausgelöst wird.
        this.tableStateProvider.ttable.reset();
        this.tableStateProvider.ttable.lazy = true;
        this.tableStateProvider.ttable.filters = filters;
      }
      this.tableStateProvider.lazyLoadEvent.first = 0;
      this.tableStateProvider.lazyLoadEvent.filters = this.tableStateProvider.ttable.filters;
      this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
    }, this.tableStateProvider.ttable.filterDelay);
  }

  /** Sort-Event wurde ausgelöst */
  onSort() {
    this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
  }

  /** Frozen-Columns (fixierte Spalten) wurde aktiviert/deaktiviert
   * @param state
   */
  onFixedChanged(state) {
    this.tableChangedSubscription.unsubscribe();
    /** dtrows zwischenspeichern und nach dem TableStateClear wiederherstellen */
    let dtrowsSave: number = this.tableStateProvider.ttable._rows;
    this.tableStateProvider.clearTableState();
    this.frozen = state.fixed;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    }
    this.colHeight = state.colHeight;
    /** dtrows wiederherstellen */
    this.dtrows = dtrowsSave;
    setTimeout(() => {
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    }, 100);
  }

  /** Die Formularpool-Übersicht
   * als CSV-Datei exportieren */
  exportForms2Csv(filtered: boolean) {
    let filename: string = 'PFP-Bestandsübersicht-' + this.helper.DateToString(new Date());
    let exportLazyLoad: LazyLoadEvent = {
      first: 0,
      rows: 99999,
      filters: (filtered) ? this.tableStateProvider.lazyLoadEvent.filters : {},
      sortField: this.tableStateProvider.lazyLoadEvent.sortField,
      sortOrder: this.tableStateProvider.lazyLoadEvent.sortOrder
    };
    let exportDataSubscription: Subscription = this.getFormsExportData(exportLazyLoad).subscribe(result => {
      exportDataSubscription.unsubscribe();
      if (result) {
        if (this.exportStockData.length > 0) {
          /** Klasse für Excel-Export initialisieren, die Spalten-Definitionsliste übergeben */
          let frozenFlag: boolean = this.frozen;
          this.frozen = false;
          let csvExp: XlsTableExport = new XlsTableExport(this.defineTableColumns(false), false);
          for (let row of this.exportStockData) {
            csvExp.addRow(row);
          }
          csvExp.createExportFile(filename, this.csvDelimiter);
          this.frozen = frozenFlag;
        } else {
          this.messageService.add({
            life: 3500,
            severity: 'warn',
            summary: 'CSV-Datenexport',
            detail: 'Es sind keine Daten für den Export verfügbar!'
          });
        }
      }
    });
  }

  /** Die Zellenhöhe in der fixierten Tabellenansicht wurde geändert
   * @param colHeight
   */
  onColHeightChanged(colHeight) {
    this.colHeight = colHeight;
  }

  /** Funktionen für die Werkzeugleiste
   * @param item
   */
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.loading = true;
        this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
        break;
      case 'UNFILTER':
        this.tableStateProvider.clearTableState();
        this.changeToolbarItemStatus(true);
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
        break;
      case 'DETAILS':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/formdetailsread/' + this.selectedForm.id]);
        break;
      case 'DOWNLOAD_SINGLE':
        this.downloadForm();
        break;
      case 'DOWNLOAD_ALL':
        this.downloadZipForm();
        break;
      case 'EXPORT_ALL':
        this.exportForms2Csv(false);
        break;
      case 'EXPORT_FIlTERED':
        this.exportForms2Csv(true);
        break;
    }
  }

  /** Toolbar-Item Status generll setzen bei verschiedenen Events, z.B.
   *  beim Anwählen/Abwählen eines Records
   * @param disabled
   */
  private changeToolbarItemStatus(disabled: boolean) {
    this.breadcrumbService.setToolbarItemDisabled('DOWNLOAD_SINGLE', disabled, true);
    this.breadcrumbService.setToolbarItemDisabled('DETAILS', disabled, false);
  }

  /** Initialisierung der Toolbar items */
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'REFRESH', icon: 'fas fas-refresh', disabled: false, tooltip: 'Übersicht aktualisieren', faicon: true});
    tbItems.push({id: 'UNFILTER', iconstack: ['fas fas-filter', 'fas fas-times fas-stacked'], disabled: true, tooltip: 'Alle Filter und Sortierung zurücksetzen', faicon: true});

    tbItems.push({id: 'DETAILS', icon: 'info', disabled: true, tooltip: 'Metadaten der Formularanwendung anzeigen', faicon: false});

    let downRight: boolean = false;
    if (this.authService.hasRight('PFP_DOWNLOAD_FORMS_STOCK_SINGLE')) {
      subItems.push({id: 'DOWNLOAD_SINGLE', icon: 'description', disabled: true, tooltip: 'Angewählte Formularanwendung herunterladen (Einzeldownload)', faicon: false});
      downRight = true;
    }
    if (this.authService.hasRight('PFP_DOWNLOAD_FORMS_STOCK_ALL')) {
      subItems.push({id: 'DOWNLOAD_ALL', icon: 'library_books', disabled: false, tooltip: 'Gesamtpaket aller Formularanwendungen anfordern (Massendownload)', faicon: false});
      downRight = true;
    }
    if (downRight) {
      tbItems.push({id: 'DOWNLOAD', icon: 'cloud_download', disabled: false, tooltip: 'Formularanwendung(en) herunterladen', faicon: false, submenu: subItems});
      subItems = [];
    }

    /** CSV-Export */
    subItems.push({id: 'EXPORT_FIlTERED', iconstack: ['fas fas-file-excel-o', 'fas fas-filter fas-stacked'], disabled: true, tooltip: 'Gefilterte Tabelle exportieren', faicon: true});
    subItems.push({id: 'EXPORT_ALL', iconstack: ['fas fas-file-excel-o', 'fas fas-plus fas-stacked'], disabled: false, tooltip: 'Vollständige Tabelle exportieren', faicon: true});
    tbItems.push({id: 'EXPORT', icon: 'fas fas-file-excel-o', disabled: false, tooltip: 'Übersicht der Formularanwendungen exportieren (csv)', faicon: true, submenu: subItems});
    subItems = [];

    return tbItems;
  }

  /** Tabellenspalten-Initialisierung (Unterscheidung zwischen fixierten und dynamischen Spalten).
   *  Die Primeng Turbotable benötigt die Breite der fixierten Spalten
   *  (frozenWidth wird über die helper-Klasse automatisch berechnet)
   * @param frozenCols
   */
  private defineTableColumns(frozenCols: boolean): TableColumn[] {
    this.frozenWidth = null;
    let cols: TableColumn[] = [];
    if ((this.frozen && frozenCols) || (!this.frozen && !frozenCols)) {
      cols.push(
        {field: 'id', header: 'ID', sort: false, filter: '', hidden: true, style: 'left', width: '0'},
        {field: 'title', header: 'Formularname', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '700px'}
      );
      this.frozenWidth = this.helper.getFrozenColumnsWidth(cols);
      this.frozenHeaderHeight = '40px';
    }
    if (!frozenCols) {
      cols.push(
        {field: 'dsvsId', header: 'Forms-ID', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '160px'},
        {field: 'khId', header: 'PDF-Content', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '220px'},
        // {field: 'khVersion', header: 'Kohlh.-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '160px'},
        {field: 'releaseNotes', header: 'Release Notes', sort: true, filter: 'STR', hidden: true, style: 'left', filterModel: '', width: '190px'},
        // {field: 'isStandard', header: 'Standard', sort: true, filter: 'BOOL', filterModel: null, hidden: false, format: 'YN', width: '120px'},
        {field: 'formsAssistantVersion', header: 'Standard-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '170px'},
        {field: 'iversion', header: 'Individual-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '170px'},
        {field: 'lastModifiedDate', header: 'Letzte Änderung', sort: true, filter: '', hidden: false, style: 'left', format: 'DATE', width: '160px'}
      );
    }
    return cols;
  }

}
