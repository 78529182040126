import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem, Table } from 'primeng';
import { Observable, Subject, Subscription } from 'rxjs';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { XlsTableExport } from '../../../classes/xls-table-export';
import { FormAssignData } from '../../../interfaces/form-assign-data';
import { FormCheckState } from '../../../interfaces/form-check-state';
import { FormData } from '../../../interfaces/form-data';
import { FormsCompanyAssignResign } from '../../../interfaces/forms-company-assign-resign';
import { LastSelectedRow } from '../../../interfaces/last-selected-row';
import { PublicInstituteData } from '../../../interfaces/public-institute-data';
import { TableColumn } from '../../../interfaces/table-column';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { AuthorizationService } from '../../../services/authorization.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { ZapService } from '../../../services/zap.service';
import { TableFixedState } from '../../custom/table-title-fixed-columns/table-fixed-state';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

export enum ViewMode {
  MANAGE = 'MANAGE',
  ASSIGN = 'ASSIGN',
}

@Component({
  selector: 'pfp-config-forms-pool',
  templateUrl: './config-forms-pool.component.html',
  styleUrls: ['./config-forms-pool.component.css']
})

export class ConfigFormsPoolComponent implements OnInit, AfterViewInit, OnDestroy {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Style des confirmation-Dialogs "confirm-warning" = roter Text "confirm-default" = schwarzer Text */
  confirmationStyle: string = 'confirm-default';

  /** Flags */
  initializing: boolean;                  // Initialisierung wird durchgeführt
  loading: boolean;                       // Loading-Flag der Komponente für die HTML-Templates
  firstSessionCall: boolean;              // Neue Session - für TableStateProvider
  blockPage: boolean;                     // Block-UI Flag
  restoringTableState: boolean;           // beim Wiederherstellen des TableState müssen die Change-Subscriptions der tabelle unterdrückt werden, sonst treten Event-Konflikte ein
  fetchingData: boolean;                  // Doppelten Aufruf des Services wegen LazyLoading verhindern
  viewMode: ViewMode = ViewMode.MANAGE;   // Ansicht für DSVS-Verwaltung oder für die Institutszuweisung
  viewModeType = ViewMode;
  confirmFinalDelete: boolean;            // Modaler Dialog für die Bestätigung des Löschens mit Masterpasswort
  deleteMasterPw: string = '';            // Master-Passwort für das Löschen

  /** Parameter für die Turbo-Table */
  frozen: boolean;                      // Tabelle hat fixierte Spalten
  colHeight: string;                    // Style für Zeilenhöhe bei Tabelle mit fixierten Spalten
  lazyLoad: boolean;                    // Lazy-Loading Option
  lazyOnInit: boolean;                  // Lazy Loading bei Initialisierung darf nicht bei Recall durchgeführt werden
  hasFilter: boolean;                   // nur für Lazy Loading, andere Summenanzeige im Tabellenfuß
  dtrows: number;                       // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];              // Mögliche Auswahl für Anzahl der Zeilen pro Seite aus den Settings
  dtScrollVertical: boolean;            // Turbo-Table scollt Vertikal - Header bleiben statisch

  /* Kalender-Einstellungen */
  yearrange: string;
  de: any;

  /** Daten für die Instituts-Zuweisung */
  instituteId: string;
  institute: PublicInstituteData = null;

  /** Daten für die TurboTable */
  @ViewChildren('ttable') turboTable: QueryList<Table>;
  ttID: string = 'ttconfigpoolview';                          // Eindeutige ID der Tabelle für den Table-State-Provider
  ttStateStorage: string = 'session';                         // Storage für den TableState
  tableChangedSubscription: Subscription;                     // Änderungs-Subscription auf die DataTable - wegen Lazy-Loading und fixierter Spalten
  scrollHeight: string;                                       // Bei Tabelle mit fixer Höhe (Table-Header scrollt nicht aus dem Bildschirm) muss die Höhe des Sceollbereichs festgelegt werden
  columns: TableColumn[];                                     // Array mit den Tabellenspalten
  frozenColumns: TableColumn[] = [];                          // Array mit den fixierten Tabellenspalten
  frozenWidth: string;                                        // Breite der fixierten Spalten (Summe) wird von Primeng-Table benötigt
  frozenHeaderHeight: string;                                 // Höhe der Header bei Fixierung (Hähe muss statisch sein, sonst gibt es einen Versatz bei mehrzeiligen Zellen)
  totalRecords: number = 0;                                   // Anzahl der Records in der Table insgesamt
  first: number = 0;                                          // Nummer des ersten aktuell angezeigten Records in der Table (zur Berechnng der Seite benötigt - für paging)
  formsPoolData: FormData[] = [];                             // Array mit den Tabellendaten
  exportPoolData: FormData[] = [];                             // Array mit den Tabellendaten für den CSV-Export
  selectedForm: FormData;                                     // Daten der angewählten Tabellenzeile
  lastSelectedRow: LastSelectedRow;                           // Zuletzt angewählte Zeile (Für den TableState-Restore)
  ynOptions: SelectItem[] = [];                               // SelectItem für das 0/1 zu ja/nein - mapping in der Tabelle
  formsCheckList: FormCheckState[] = [];                      // Array mit den geänderten Check-States (Zuweisung) der Formulare
  exportDataFetched = new Subject<boolean>();                 // Für die Subscription auf den Datenservice für den Export

  /** Settings für CSV Export*/
  csvDelimiter: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthorizationService,
              public breadcrumbService: BreadcrumbService,
              public tableStateProvider: TableStateProvider,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private fileDownloadService: FileDownloadService,
              private zapService: ZapService) {
  }

  ngOnInit() {
    /* Während die Komponente initialisiert wird - keine HTML-Template-Anzeige */
    this.loading = true;
    /** Falls eine Instituts-ID in der Route angegeben ist muss der Modus geändert und
     * die Institutsdaten abgerufen werden werden */
    if (this.route.snapshot.params['instid']) {
      this.viewMode = ViewMode.ASSIGN;
      this.instituteId = this.route.snapshot.params['instid'];
      const subscription: Subscription = this.zapService.getInstituteData(this.authService.getJWT(), this.instituteId).subscribe(data => {
        subscription.unsubscribe();
        this.institute = data;
      }, (e: HttpErrorResponse) => {
        subscription.unsubscribe();
        if (e.status === 401) {
          this.viewMode = ViewMode.MANAGE;
          this.breadcrumbService.setToolbarItems(this.initToolbar());
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen des Mandanten',
            detail: 'Beim Abrufen des Mandanten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        }
      });
    }
    /** Flag zur Vermeidung des doppelten Aufrufs des Daten-Backends, abhängig
     *  vom gesetzten Status für die "statische Tabelle", da bei der Initialisierung
     *  der TurboTable der Event für den Datenabruf ausgelöst wird (wegen Lazy-Loading-Flag)
     */
    this.initializing = !this.tableStateProvider.activated;
    /* Config-Pool-View ist immer lazy */
    this.lazyLoad = true;
    /* wenn die Tabelle in dieser Session noch nicht geöffnet war, dann muss der Lazy-Loading-Event
     * beim Öffnen der Komponente ausgeführt werden. */
    this.lazyOnInit = this.tableStateProvider.firstSessionCall(this.ttID);
    this.firstSessionCall = this.lazyOnInit;
    /* Filter für Boolean Spalten - Mapping zu ja/nein */
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];
    /** Tabellenparameter */
    this.dtrows = +this.ffbSettings.getOptionValue('DTROWS_PFP', 10);
    this.dtScrollVertical = (this.ffbSettings.getOptionValue('DTSCROLL_PFP', false) === 'true');
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.ffbSettings.getOptionParameters('DTROWS_PFP', [5, 10, 25, 100]).map(value => +value);
    /* Falls der User die Tabellenhöhe auf die Contenthöhe beschränken will (Tabelle Scrollt Vertikal)... */
    if (this.dtScrollVertical) {
      this.scrollHeight = this.helper.getTableScrollHeight(window.innerHeight);
    } else {
      this.scrollHeight = 'auto';
    }
    /** Definition der Tabellenspalten abrufen
     *  Unterscheidung je nach Modus fixiert/nicht fixiert
     */
    let fixedState: TableFixedState = this.tableStateProvider.getTableFrozenState(this.ttID);
    this.frozen = fixedState.fixed;
    this.colHeight = fixedState.colHeight;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    } else {
      this.frozenColumns = [];
    }
    /** Kalender - Initialisierung */
    this.de = this.helper.calendarDe();
    /** Breadcrumb - Initialisierung / Einblenden erst nach erfolgreicher Datenabfrage */
    /**Toolbar initialisierung*/
    if (this.viewMode === ViewMode.MANAGE) {
      this.breadcrumbService.setMenuItems([
        {label: 'Administration'},
        {label: 'Formularpool'},
        {label: 'Verwaltung'}
      ]);
    } else {
      this.breadcrumbService.setMenuItems([
        {label: 'Administration'},
        {label: 'Formularpool'},
        {label: 'Zuweisung'}
      ]);
    }
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(false);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /** Die vom Resize-Service ausgelösten Resize-Events abonnieren, falls der user in den Settings die Tabellenhöhe beschränkt hat */
    if (this.dtScrollVertical) {
      this.resizeSubscription = this.resizeService.onResize$.subscribe(win => {
        this.scrollHeight = this.helper.getTableScrollHeight(win.innerHeight);
      });
    }
    /** Falls die Tabelle in dieser Session bereits geöffnet war, so darf
     * getUserDataLazy nicht automatisiert aufgerufen werden, da die Daten auf
     * Stand des letzten LazyLoadEvents aufgerufen werden müssen.
     * Wenn der TableState wiederhergestellt werden kann, wird der Abruf der Daten initialisiert.
     */
    if (!this.lazyOnInit) {
      this.getFormsDataLazy(this.tableStateProvider.getLazyLoadInitValue(this.ttID), true);
    }
  }

  /** Mittels Timeout dieses Event in einem eigenen Thread ausführen, damit gewährleistet ist
   *  dass zuvor alle HTML-Elemente vollständig aufgebaut werden konnten.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializing = false;
      this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    });
  }

  ngOnDestroy(): void {
    /** Save Table State */
    this.tableStateProvider.saveTableState(this.frozen, this.colHeight, this.lastSelectedRow);
    /** WICHTIG!!! - alle Subscriptions zurücksetzen */
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    if (this.tableChangedSubscription) {
      this.tableChangedSubscription.unsubscribe();
    }
  }

  /** Zurücksetzen des ViewMode in den Verwaltungsmodus */
  private resetViewMode() {
    this.viewMode = ViewMode.MANAGE;
    this.institute = null;
    this.instituteId = '';
    this.formsCheckList = [];
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    } else {
      this.frozenColumns = [];
    }
    /**Toolbar initialisierung*/
    this.breadcrumbService.setMenuItems([
      {label: 'Administration'},
      {label: 'Formularpool'},
      {label: 'Verwaltung'}
    ]);
    this.breadcrumbService.setToolbarVisible(false);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(true);
    this.tableStateProvider.clearTableState(true);
    this.getFormsDataLazy(this.tableStateProvider.getLazyLoadInitValue(this.ttID), false);
  }

  /** In der Formularpool-Übersicht wird immer lazy-Loading verwendet */
  getFormsDataLazy(lazyLoadEvent: LazyLoadEvent, restoreTable: boolean = false) {
    if (!this.fetchingData && !this.initializing) {
      this.fetchingData = true;
      this.tableStateProvider.lazyLoadEvent = lazyLoadEvent;
      /** Andere Footer-Anzeige wenn Filter vorhanden sind, siehe HTML-Template */
      try {
        if (lazyLoadEvent.hasOwnProperty('filters')) {
          this.hasFilter = (Object.keys(lazyLoadEvent.filters).length > 0);
        } else {
          this.hasFilter = false;
        }
      } catch (e) {
        this.hasFilter = false;
      }
      if (this.hasFilter) {
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', false, true);
      } else {
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
      }
      /** Forms-Service Formulardaten über lazy-Loading abrufen
       *  dieser Service wird für die Manage und die Assign verwendet, deshalb
       *  wird hier noch spezifisch die Route mit übergeben */
      let serviceRoute: string;
      this.viewMode === ViewMode.MANAGE ? serviceRoute = 'forms/admin' : serviceRoute = 'forms/admin/' + this.instituteId.toString();
      const subscription: Subscription = this.formsService.getFormsLazy(serviceRoute, this.authService.getJWT(), this.helper.lazyLoadParams(lazyLoadEvent)).subscribe(data => {
        subscription.unsubscribe();
        if (data.successful) {
          this.formsPoolData = data.payload;
          this.totalRecords = data.totalElements;
          /** ggf. durchgeführte, noch nicht gespeicherte Zuweisungsänderungen in dieser Session den Formularen der aktuellen Page zuweisen */
          if (this.formsCheckList.length > 0) {
            this.formsPoolData.map(form => {
              if (this.formsCheckList.find(item => item.uuid === form.id)) {
                form.isAssigned = this.formsCheckList.find(item => item.uuid === form.id).checkState;
              }
            });
          }
          this.loading = false;
          this.fetchingData = false;
          if (restoreTable) {
            this.restoreTableState();
          }
          this.breadcrumbService.setToolbarVisible(true);
        } else {
          this.totalRecords = 0;
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen der Formulardaten',
            detail: 'Status: ' + data.message + '<br>' + data.details
          });
          this.loading = false;
          this.fetchingData = false;
          this.breadcrumbService.setToolbarVisible(true);
        }
      }, (e: HttpErrorResponse) => {
        subscription.unsubscribe();
        this.totalRecords = 0;
        this.loading = false;
        this.fetchingData = false;
        if (e.status === 401) {
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          this.breadcrumbService.setToolbarVisible(true);
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler beim Abrufen der Formulardaten',
            detail: 'Beim Abrufen der Formulardaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        }
      });
    }
  }

  /** Daten für den csv-Export abrufen
   * @param lazyLoadEvent
   */
  getFormsExportData(lazyLoadEvent: LazyLoadEvent): Observable<boolean> {
    /** Forms-Service Formulardaten über lazy-Loading abrufen
     *  dieser Service wird für die Manage und die Assign verwendet, deshalb
     *  wird hier noch spezifisch die Route mit übergeben */
    let serviceRoute: string;
    this.viewMode === ViewMode.MANAGE ? serviceRoute = 'forms/admin' : serviceRoute = 'forms/admin/' + this.instituteId.toString();
    const subscription: Subscription = this.formsService.getFormsLazy(serviceRoute, this.authService.getJWT(), this.helper.lazyLoadParams(lazyLoadEvent)).subscribe(data => {
      subscription.unsubscribe();
      if (data.successful) {
        this.exportPoolData = data.payload;
        this.exportDataFetched.next(true);
      } else {
        this.exportPoolData = [];
        this.exportDataFetched.next(false);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der Exportdaten',
          detail: 'Status: ' + data.message + '<br>' + data.details
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.exportDataFetched.next(false);
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der Exportdaten',
          detail: 'Beim Abrufen der Exportdaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
    return this.exportDataFetched.asObservable();
  }

  /** Auswahl "Zuweisung zu Institut" hat sich geändert - muss wegen LazyLoading sofort in Backend aktualisiert werden
   *
   * @param formId
   * @param checked
   */
  assignmentChanged(formId: string, checked: boolean) {
    // Nicht wie in FoPo sofort speichern, sonder in ein formsCheckList-Array speichern
    // Im Array stehen Objekte vom Interface-Typ formCheckState
    // wenn eine UUID in diesem array bereits vorhanden ist, so kann sie bei erneuter Änderung
    // bevor gespeichert wurde einfach wieder entfernt werden, ansonsten wird sie mit dem checkValue (true/false)
    // unter der UUID als Key ins Array aufgenommen. Mit Speichern wird dann das ganze array nacheinander gespeichert.
    // So müssen Änderngen nicht sofort ins Backend übernommen werden und man kann Änderungen auch verwerfen
    // this.updateStock(formId, String(checked));
    // this.blockPage = false;
    if (this.formsCheckList.find(form => form.uuid === formId)) {
      this.formsCheckList = this.formsCheckList.filter(form => form.uuid !== formId);
    } else {
      this.formsCheckList.push({
        uuid: formId,
        checkState: checked
      });
    }
    if (this.formsCheckList.length > 0) {
      this.breadcrumbService.setToolbarItemDisabled('SAVE', false);
    } else {
      this.breadcrumbService.setToolbarItemDisabled('SAVE', true);
    }
  }

  /** Restore-Event wird von der Primeng Table getriggered */
  onTableStateRestore() {
    /* Nicht aufrufen, falls es noch keine Sessiondaten gibt */
    if (!this.firstSessionCall) {
      this.firstSessionCall = false;
      this.restoringTableState = true;
      if (this.tableStateProvider.activated) {
        setTimeout(() => {
          this.tableStateProvider.restoreTableState();
          if (this.selectedForm !== null && this.selectedForm !== undefined) {
            this.changeToolbarItemStatus(false);
          }
          this.restoringTableState = false;
        }, 100);
      } else {
        /* TableState zurücksetzen */
        this.restoringTableState = false;
        setTimeout(() => {
          this.tableStateProvider.clearTableState();
        }, 100);
      }
    }
  }

  /** Aufruf erfolgt beim TablestateResore event und auch nochmals wenn die
   * Daten gelesen wurden (aus ngOninit) falls das Lesen der Daten länger benötigt
   * und somit der restoreTableState noch vor dem tatsächlichen aufbau der tabelle erfolgt.
   */
  private restoreTableState() {
    setTimeout(() => {
      this.tableStateProvider.restoreTableState();
      this.dtrows = this.tableStateProvider.lazyLoadEvent.rows;
      if (this.tableStateProvider.lastSelectedRow !== null) {
        this.lastSelectedRow = this.tableStateProvider.lastSelectedRow;
        this.selectedForm = this.formsPoolData.find(item => item.id === this.lastSelectedRow.rowID);
        this.changeToolbarItemStatus(false);
      } else {
        this.changeToolbarItemStatus(true);
      }
    }, 100);
  }

  /** Bestätigung der Formularzuweisung im Batchassign-Mode */
  private confirmAssign() {
    this.confirmationService.confirm({
      message: 'Die Zuweisung der Formularanwendungen wird gespeichert.<br>Sind Sie sicher?',
      header: 'Bestätigung',
      icon: '',
      accept: () => {
        this.assignForms();
      }
    });
  }

  /** Speichern der Formularzuweisung
   * @private
   */
  private assignForms() {
    this.blockPage = true;
    let forms: FormAssignData[] = [];
    this.formsCheckList.map(item => {
      forms.push({
        id: item.uuid,
        assign: item.checkState
      });
    });
    let formsCompany: FormsCompanyAssignResign = {
      companyId: [+this.instituteId],
      entityId: forms
    };
    const subscription: Subscription = this.formsService.assignResignFormsToCompany(this.authService.getJWT(), formsCompany).subscribe(result => {
      subscription.unsubscribe();
      this.blockPage = false;
      this.formsCheckList = [];
      if (result.successful) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Formularanwendungen zuweisen/entziehen',
          detail: 'Die Zuweisung der Formularanwendungen wurde erfolgreich gespeichert.'
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formularanwendungen zuweisen/entziehen',
          detail: 'Beim Speichern der Zuweisung ein Fehler aufgetreten:<br>' + result.comment + '<br>Sollte dies wiederholt auftreten, so wenden Sie sich bitte an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formularanwendungen zuweisen/entziehen',
          detail: 'Beim Speichern der Zuweisung ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Download eines einzelnen Formularpakets (zip-datei mit war, pdf...)
   * @private
   */
  private downloadForm() {
    const subscription: Subscription = this.fileDownloadService.downloadFile(this.authService.getJWT(), this.selectedForm.id).subscribe(success => {
      subscription.unsubscribe();
      if (success === true) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Datei herunterladen',
          detail: 'Die Datei wird heruntergeladen'
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Herunterladen',
          detail: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten. <br>' + 'Datei-ID = ' + this.selectedForm.zipFile.id + '<br>Bitte wenden Sie sich an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Dateidownload',
          detail: e.message
        });
      }
    });
  }

  /** Beim klick auf den Löschen Button wird zunächst der Confirmation Service ausgeführt (Sicherheitsabfrage) */
  private confirmDelete(hard: boolean = false) {
    this.confirmationStyle = 'confirm-warning';
    let msgExtra: string;
    msgExtra = hard ? ' UNWIDERRUFLICH ' : ' ';
    this.confirmationService.confirm({
      message: 'Wollen Sie das angewählte Formular<br>"' + this.selectedForm.title + '"<br>wirklich' + msgExtra + 'löschen?',
      header: 'Bestätigung',
      icon: '',
      accept: () => {
        /** Keine Masterpasswortabfrage mehr, Prüfung auf noch zugewiesene Mandanten erfolgt aus Backend */
        // hard ? this.confirmFinalDelete = true : this.deleteForm();
        hard ? this.deleteFormFinally() : this.deleteForm();
      }
    });
  }

  /** Formular löschen (soft delete)
   * @private
   */
  private deleteForm() {
    this.blockPage = true;
    const subscription: Subscription = this.formsService.deleteForm(this.authService.getJWT(), this.selectedForm.id).subscribe(result => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (result.successful) {
        /* Formular ggf. auch aus der Liste der Zuweisungsänderungen entfernen */
        if (this.formsCheckList.find(form => form.uuid === this.selectedForm.id)) {
          this.formsCheckList = this.formsCheckList.filter(form => form.uuid !== this.selectedForm.id);
        }
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Formular löschen',
          detail: 'Das Formular wurde erfolgreich gelöscht.'
        });
        this.selectedForm = null;
        this.changeToolbarItemStatus(true);
        this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formular löschen',
          detail: 'Beim Löschen des Formulars ist ein Fehler aufgetreten:<br>' + result.comment + '<br>Sollte dies wiederholt auftreten, so wenden Sie sich bitte an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formular löschen',
          detail: 'Beim Löschen des Formulars ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Formular löschen (hard delete)
   * @private
   */
  deleteFormFinally() {
    this.confirmFinalDelete = false;
    this.blockPage = true;
    const subscription: Subscription = this.formsService.deleteFormHard(this.authService.getJWT(), this.selectedForm.id, this.deleteMasterPw).subscribe(result => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (result.successful) {
        /* Formular ggf. auch aus der Liste der Zuweisungsänderungen entfernen */
        if (this.formsCheckList.find(form => form.uuid === this.selectedForm.id)) {
          this.formsCheckList = this.formsCheckList.filter(form => form.uuid !== this.selectedForm.id);
        }
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Formular löschen',
          detail: 'Das Formular wurde erfolgreich gelöscht.'
        });
        this.selectedForm = null;
        this.changeToolbarItemStatus(true);
        this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formular löschen',
          detail: 'Beim Löschen des Formulars ist ein Fehler aufgetreten:<br>' + result.comment + '<br>Sollte dies wiederholt auftreten, so wenden Sie sich bitte an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 409) {
        this.messageService.add({
          sticky: true,
          severity: 'warn',
          summary: 'Löschen nicht möglich',
          detail: 'Dieses Formular ist noch Mandanten zugewiesen.<br>Vor dem Löschen müssen alle Zuweisungen entzogen werden!'
        });
      } else if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formular löschen',
          detail: 'Beim Löschen des Formulars ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Event - Tabellenzeile wurde angewählt
   * @param event
   */
  onRowSelect(event) {
    this.lastSelectedRow = {
      rowIndex: event.index,
      rowID: event.data.id
    };
    if (event.index > -1) {
      this.changeToolbarItemStatus(false);
    }
    ;
  }

  /** Event - Tabellenzeile wurde abgewählt
   * @param event
   */
  onRowUnselect(event) {
    this.changeToolbarItemStatus(true);
    this.lastSelectedRow = null;
  }

  /** Eigene Filterung über das Backend
   * @param value
   * @param field
   * @param matchMode
   */
  customFilter(value: any, field: any, matchMode: any) {
    if (!this.restoringTableState) {
      this.selectedForm = null;
    }
    this.changeToolbarItemStatus(true);
    if (this.tableStateProvider.ttable.filterTimeout) {
      clearTimeout(this.tableStateProvider.ttable.filterTimeout);
    }
    if (!this.tableStateProvider.ttable.isFilterBlank(value)) {
      this.tableStateProvider.ttable.filters[field] = {value: value, matchMode: matchMode};
    } else if (this.tableStateProvider.ttable.filters[field]) {
      delete this.tableStateProvider.ttable.filters[field];
    }
    this.tableStateProvider.ttable.filterTimeout = setTimeout(() => {
      if (this.tableStateProvider.ttable.first > 0) {
        let filters: any = this.tableStateProvider.ttable.filters;
        this.tableStateProvider.ttable.lazy = false;   // damit beim Reset kein LazyLoad-Event ausgelöst wird.
        this.tableStateProvider.ttable.reset();
        this.tableStateProvider.ttable.lazy = true;
        this.tableStateProvider.ttable.filters = filters;
      }
      this.tableStateProvider.lazyLoadEvent.first = 0;
      this.tableStateProvider.lazyLoadEvent.filters = this.tableStateProvider.ttable.filters;
      this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
    }, this.tableStateProvider.ttable.filterDelay);
  }

  /** Sort-Event wurde ausgelöst */
  onSort() {
    this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
  }

  /** Frozen-Columns (fixierte Spalten) wurde aktiviert/deaktiviert
   * @param state
   */
  onFixedChanged(state) {
    this.tableChangedSubscription.unsubscribe();
    /** dtrows zwischenspeichern und nach dem TableStateClear wiederherstellen */
    let dtrowsSave: number = this.tableStateProvider.ttable._rows;
    this.tableStateProvider.clearTableState();
    this.frozen = state.fixed;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    }
    this.colHeight = state.colHeight;
    /** dtrows wiederherstellen */
    this.dtrows = dtrowsSave;
    setTimeout(() => {
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    }, 100);
  }

  /** Die Formularpool-Übersicht
   * als CSV-Datei exportieren */
  exportForms2Csv(filtered: boolean) {
    let filename: string = 'PFP-Formularpoolübersicht-' + this.helper.DateToString(new Date());
    let exportLazyLoad: LazyLoadEvent = {
      first: 0,
      rows: 99999,
      filters: (filtered) ? this.tableStateProvider.lazyLoadEvent.filters : {},
      sortField: this.tableStateProvider.lazyLoadEvent.sortField,
      sortOrder: this.tableStateProvider.lazyLoadEvent.sortOrder
    };
    let exportDataSubscription: Subscription = this.getFormsExportData(exportLazyLoad).subscribe(result => {
      exportDataSubscription.unsubscribe();
      if (result) {
        if (this.exportPoolData.length > 0) {
          /** Klasse für Excel-Export initialisieren, die Spalten-Definitionsliste übergeben */
          let frozenFlag: boolean = this.frozen;
          this.frozen = false;
          let csvExp: XlsTableExport = new XlsTableExport(this.defineTableColumns(false));
          for (let row of this.exportPoolData) {
            csvExp.addRow(row);
          }
          csvExp.createExportFile(filename, this.csvDelimiter);
          this.frozen = frozenFlag;
        } else {
          this.messageService.add({
            life: 3500,
            severity: 'warn',
            summary: 'CSV-Datenexport',
            detail: 'Es sind keine Daten für den Export verfügbar!'
          });
        }
      }
    });
  }

  /** Die Zellenhöhe in der fixierten Tabellenansicht wurde geändert
   * @param colHeight
   */
  onColHeightChanged(colHeight) {
    this.colHeight = colHeight;
  }

  /** Funktionen für die Werkzeugleiste
   * @param item
   */
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.loading = true;
        this.getFormsDataLazy(this.tableStateProvider.lazyLoadEvent);
        break;
      case 'UNFILTER':
        this.tableStateProvider.clearTableState();
        this.changeToolbarItemStatus(true);
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
        break;
      case 'EDIT':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/formdetails/update/' + this.selectedForm.id]);
        break;
      case 'ADD':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/formdetails/create']);
        break;
      case 'ADDMULTI':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/formsaddmulti']);
        break;
      case 'DELETE':
        this.confirmDelete();
        break;
      case 'HARD_DELETE':
        this.confirmDelete(true);
        break;
      case 'MODE_MANAGE':
        this.resetViewMode();
        break;
      case 'MODE_ASSIGN':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/configviewclients/assign']);
        break;
      case 'SAVE':
        this.confirmAssign();
        break;
      case 'FORM_CLIENTS':
        this.breadcrumbService.setToolbarVisible(false);
        this.router.navigate(['/configviewformclients/' + this.selectedForm.id]);
        break;
      case 'DOWNLOAD_FORM':
        this.downloadForm();
        break;
      case 'EXPORT_ALL':
        this.exportForms2Csv(false);
        break;
      case 'EXPORT_FIlTERED':
        this.exportForms2Csv(true);
        break;
    }
  }

  /** Toolbar-Item Status generll setzen bei verschiedenen Events, z.B.
   *  beim Anwählen/Abwählen eines Records
   * @param disabled
   */
  private changeToolbarItemStatus(disabled: boolean) {
    this.breadcrumbService.setToolbarItemDisabled('EDIT', disabled);
    this.breadcrumbService.setToolbarItemDisabled('DOWNLOAD_FORM', disabled);
    this.breadcrumbService.setToolbarItemDisabled('FORM_CLIENTS', disabled);
    this.breadcrumbService.setToolbarItemDisabled('DELETE', disabled);
    this.breadcrumbService.setToolbarItemDisabled('HARD_DELETE', disabled);
  }

  /** Initialisierung der Toolbar items */
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'REFRESH', icon: 'fas fas-refresh', disabled: false, tooltip: 'Übersicht aktualisieren', faicon: true});
    tbItems.push({id: 'UNFILTER', iconstack: ['fas fas-filter', 'fas fas-times fas-stacked'], disabled: true, tooltip: 'Alle Filter und Sortierung zurücksetzen', faicon: true});
    /** Auswahl des Modus: Verwaltung oder Zuweisung */
    let hasModeSelectRight: boolean = false;
    if (this.viewMode === ViewMode.MANAGE) {
      if (this.authService.hasRight('PFP_CONFIG_MANAGE_FORMS')) {
        subItems.push({id: 'MODE_MANAGE', icon: 'playlist_add', disabled: true, tooltip: 'Allgemeine Formularverwaltung', faicon: false});
        hasModeSelectRight = true;
      }
      if (this.authService.hasRight('PFP_CONFIG_ASSIGN_FORMS')) {
        subItems.push({id: 'MODE_ASSIGN', icon: 'playlist_add_check', disabled: false, tooltip: 'Mandantenspezifische Formularzuweisung', faicon: false});
        hasModeSelectRight = true;
      }
    } else {
      if (this.authService.hasRight('PFP_CONFIG_MANAGE_FORMS')) {
        subItems.push({id: 'MODE_MANAGE', icon: 'playlist_add', disabled: false, tooltip: 'Allgemeine Formularverwaltung', faicon: false});
        hasModeSelectRight = true;
      }
      if (this.authService.hasRight('PFP_CONFIG_ASSIGN_FORMS')) {
        subItems.push({id: 'MODE_ASSIGN', icon: 'people_outline', disabled: false, tooltip: 'Zu anderem Mandanten wechseln', faicon: false});
        hasModeSelectRight = true;
      }
    }
    if (hasModeSelectRight) {
      tbItems.push({id: 'SELECT_MODE', icon: 'touch_app', disabled: false, tooltip: 'Wechsel zwischen Verwaltungs- und Zuweisungsmodus bzw. Mandantenwechsel im Zuweisungsmodus', faicon: false, submenu: subItems});
      subItems = [];
    }

    /** Ändern von Metadaten und Hinzufügen von Formularen  nur im "Manage-Mode" */
    if (this.viewMode === ViewMode.MANAGE) {
      /** Metadaten bearbeiten */
      if (this.authService.hasRight('PFP_CONFIG_EDIT_FORMS_DATA')) {
        tbItems.push({id: 'EDIT', icon: 'edit', disabled: true, tooltip: 'Metadaten der Formularanwendung bearbeiten', faicon: false});
      }
      /** Formular hinzufügen */
      if (this.authService.hasRight('PFP_CONFIG_ADD_FORMS')) {
        tbItems.push({id: 'ADD', icon: 'note_add', disabled: false, tooltip: 'Neue Formularanwendung zum Formularpool hinzufügen', faicon: false});
      }
      /** Formular hinzufügen */
      if (this.authService.hasRight('PFP_CONFIG_ADD_MULTIPLE_FORMS')) {
        tbItems.push({id: 'ADDMULTI', icon: 'playlist_add', disabled: false, tooltip: 'Massenupload -> neue Formularanwendungen zum Formularpool hinzufügen', faicon: false});
      }
      /** Mandantenübersicht einer spezifischen FA aufrufen */
      if (this.authService.hasRight('PFP_CONFIG_VIEW_FORM_CLIENTS')) {
        tbItems.push({id: 'FORM_CLIENTS', icon: 'groups', disabled: true, tooltip: 'Übersicht der Mandanten aufrufen, denen die angewählte FA zugewiesen wurde', faicon: false});
      }
    }
    if (this.viewMode === ViewMode.ASSIGN) {
      if (this.authService.hasRight('PFP_CONFIG_CHANGE_FORMS_ASSIGN')) {
        tbItems.push({id: 'SAVE', icon: 'save', disabled: true, tooltip: 'Geänderte Zuweisungen übernehmen', faicon: false});
      }
    }
    /** Download */
    if (this.authService.hasRight('PFP_CONFIG_DOWNLOAD_FORMS_POOL')) {
      tbItems.push({id: 'DOWNLOAD_FORM', icon: 'cloud_download', disabled: true, tooltip: 'ZIP-Paket für ausgewählte Formularanwendung herunterladen', faicon: false});
    }

    /** Löschen von Formularen nur im "Manage-Mode" */
    if (this.viewMode === ViewMode.MANAGE) {
      /** Formular löschen */
      if (this.authService.hasRight('PFP_CONFIG_DELETE_FORMS')) {
        tbItems.push({id: 'DELETE', icon: 'delete', disabled: true, tooltip: 'Formularanwendung aus dem Formularpool löschen (Löschkennzeichnung)', faicon: false});
      }
      /** Formular löschen */
      if (this.authService.hasRight('PFP_CONFIG_HARDDELETE_FORMS')) {
        tbItems.push({id: 'HARD_DELETE', icon: 'delete_forever', disabled: true, tooltip: 'Formularanwendung endgültig aus dem Formularpool löschen (Unwiderruflich)', faicon: false});
      }
    }

    /** CSV-Export */
    subItems.push({id: 'EXPORT_FIlTERED', iconstack: ['fas fas-file-excel-o', 'fas fas-filter fas-stacked'], disabled: true, tooltip: 'Gefilterte Tabelle exportieren', faicon: true});
    subItems.push({id: 'EXPORT_ALL', iconstack: ['fas fas-file-excel-o', 'fas fas-plus fas-stacked'], disabled: false, tooltip: 'Vollständige Tabelle exportieren', faicon: true});
    tbItems.push({id: 'EXPORT', icon: 'fas fas-file-excel-o', disabled: false, tooltip: 'Übersicht der Formularanwendungen exportieren (csv)', faicon: true, submenu: subItems});
    subItems = [];
    return tbItems;
  }

  /** Tabellenspalten-Initialisierung (Unterscheidung zwischen fixierten und dynamischen Spalten).
   *  Die Primeng Turbotable benötigt die Breite der fixierten Spalten
   *  (frozenWidth wird über die helper-Klasse automatisch berechnet)
   * @param frozenCols
   */
  private defineTableColumns(frozenCols: boolean): TableColumn[] {
    this.frozenWidth = null;
    let cols: TableColumn[] = [];
    if ((this.frozen && frozenCols) || (!this.frozen && !frozenCols)) {
      cols.push({field: 'id', header: 'ID', sort: false, filter: '', hidden: true, style: 'left', width: '0'});
      if (this.viewMode === ViewMode.ASSIGN) {
        let disabled: boolean = !this.authService.hasRight('PFP_CONFIG_CHANGE_FORMS_ASSIGN');
        cols.push({field: 'isAssigned', header: 'Zugewiesen', sort: false, filter: 'BOOL', format: 'CHECK', hidden: false, disabled: disabled, style: 'center', filterModel: null, width: '120px', tooltip: 'Formular wurde dem ausgewählten Mandanten zugewiesen ja/nein'});
      }
      cols.push({field: 'title', header: 'Formularname', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '700px'});
      this.frozenWidth = this.helper.getFrozenColumnsWidth(cols);
      this.frozenHeaderHeight = '40px';
    }
    if (!frozenCols) {
      cols.push(
        {field: 'dsvsId', header: 'Forms-ID', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '180px'},
        {field: 'khId', header: 'PDF-Content', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '220px'},
        {field: 'khVersion', header: 'PDF-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '160px'},
        {field: 'formsAssistantVersion', header: 'Forms-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '190px'},
        {field: 'isStandard', header: 'Standard', sort: true, filter: 'BOOL', filterModel: null, hidden: false, format: 'YN', width: '120px'},
        {field: 'composerVersion', header: 'Composer-Version', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '170px'},
        {field: 'releaseNotes', header: 'Release Notes', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '700px'},
        {field: 'lastModifiedDate', header: 'Letzte Änderung', sort: true, filter: '', hidden: false, style: 'left', format: 'DATE', width: '160px'}
      );
    }
    return cols;
  }

}
