import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ConfigFormsPoolComponent } from './config-forms-pool.component';
import { ConfirmationService } from 'primeng';

@Injectable({
  providedIn: 'root'
})
export class ConfigFormsPoolDeactivateGuard implements CanDeactivate<ConfigFormsPoolComponent> {

  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(component: ConfigFormsPoolComponent): Observable<boolean> | Promise<boolean> | boolean {

    if (component.formsCheckList.length === 0) {
      return true;
    }
    return new Observable((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message: 'Die letzten Änderungen an der Zuweisung wurden noch nicht gespeichert.<br>Wollen Sie diese Seite jetzt verlassen?',
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
            component.breadcrumbService.setToolbarVisible(true);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

}
