import { Table } from 'primeng/table';
import { TableState } from '../interfaces/table-state';
import { TableFilterState } from '../interfaces/table-filter-state';
import { TableColumn } from '../interfaces/table-column';
import { LastSelectedRow } from '../interfaces/last-selected-row';
import { LazyLoadEvent } from 'primeng/api';
import { TableFixedState } from '../components/custom/table-title-fixed-columns/table-fixed-state';

export class TableStateProvider {

  /* TurboTable ID (EWindeutiger Name der Tabelle) */
  private _ttID: string;
  /* Referenz auf die Turbo-Table */
  private _ttable: Table;
  /* Spalten der TurboTable */
  private ttColumns: TableColumn[] = [];
  /* Zuletzt angewählte Zeile */
  private _lastSelectedRow: LastSelectedRow = null;
  /* TableState Objekte */
  private tableStateParams: { [ttID: string]: TableState } = {};
  /* Einstellung aus den User-Settings ob der TableState aktiviert ist */
  private _activated: boolean;
  /* Daten des letzten LazyLoadEvents */
  private _lazyLoadEvent: LazyLoadEvent = null;
  /* Flag ob bei deaktiviertem TableState die Filter etc. zurückgesetzt wurden */
  private cleared: boolean;

  get ttID(): string {
    return this._ttID;
  }

  set ttID(value: string) {
    this._ttID = value;
  }

  get ttable(): Table {
    return this._ttable;
  }

  get activated(): boolean {
    return this._activated;
  }

  set activated(value: boolean) {
    this._activated = value;
  }

  get lastSelectedRow(): LastSelectedRow {
    return this._lastSelectedRow;
  }

  get lazyLoadEvent(): LazyLoadEvent {
    return this._lazyLoadEvent;
  }

  set lazyLoadEvent(value: LazyLoadEvent) {
    this._lazyLoadEvent = value;
  }

  /** Abfrage ob diese Tabelle in der Session bereits geöffnet wurde und TableState-Daten existieren
   *
   * @param ttID = ID der Tabelle
   * @param forceInit = Initialisierung erzwingen
   * @param checklazy = Bei LazyLoading auf null-Objekt prüfen sonst bitte false übergeben
   */
  firstSessionCall(ttID: string, forceInit: boolean = false, checklazy = true): boolean {
    this.cleared = false; // Damit beim ersten Aufruf - falls TableState deaktiviert ist - die Tabelle zurückgesetzt wird
    if (this.tableStateParams[ttID] === null || this.tableStateParams[ttID] === undefined || forceInit || (this.tableStateParams[ttID].lazyLoadEvent === null && checklazy)) {
      this.tableStateParams[ttID] = {
        first: 0,
        filters: null,
        selectedRow: null,
        selection: null,
        lazyLoadEvent: null,
        frozen: false,
        colHeight: ''
      };
      return true;
    }
    return false;
  }

  /** Einen bestimmnten para,eterwert aus den aktuellen tableStateParams zurückliefern
   *
   * @param key
   */
  public getParamValue(key: string): any {
    try {
      let params: TableState = this.tableStateParams[this._ttID];
      return params[key];
    } catch (e) {
      return null;
    }
  }

  /** Frozen-Parameter einer tabelle abfragen */
  public getTableFrozenState(ttID: string): TableFixedState {
    try {
      return {
        fixed: this.tableStateParams[ttID].frozen,
        colHeight: this.tableStateParams[ttID].colHeight
      };
    } catch (e) {
      return {
        fixed: false,
        colHeight: '20px',
      };
    }
  }

  /** Beim erneuten aufruf einer tabelle müssen die Daten noch vor der Initialisierung
   * der Tabelle über LazyLoading abgerufen werden (kein LazyLoadOnInit) hierzu wird der
   * im TableStateProvider gespeicherte wertz für diese tabelle benötigt.
   * @param ttID
   */
  public getLazyLoadInitValue(ttID: string): LazyLoadEvent {
    if (!this.tableStateParams[ttID]) {
      return null;
    } else {
      return this.tableStateParams[ttID].lazyLoadEvent;
    }
  }

  /* Tabelle im OnInit initialisieren */
  public initTable(ttID: string, ttable: Table, columns: TableColumn[]) {
    this._ttID = ttID;
    this._ttable = ttable;
    this.ttColumns = columns;
    this._lastSelectedRow = null;
    if (!this.activated && !this.cleared) {
      this.clearTableState();
      this.cleared = true;
    }
  }

  /** Status der Tabelle speichern
   *
   * @param frozen
   * @param colHeight
   * @param selectedRow
   */
  public saveTableState(frozen = false, colHeight = '', selectedRow: LastSelectedRow = null) {
    if (!this.activated) {
      return;
    }
    try {
      this.tableStateParams[this._ttID].first = this._ttable.first;
      this.tableStateParams[this._ttID].selectedRow = selectedRow;
      this.tableStateParams[this._ttID].selection = this._ttable.selection;
      this.tableStateParams[this._ttID].lazyLoadEvent = this.lazyLoadEvent;
      this.tableStateParams[this._ttID].frozen = frozen;
      this.tableStateParams[this._ttID].colHeight = colHeight;
    } catch (e) {
      this.tableStateParams[this._ttID] = {
        first: 0,
        filters: null,
        selectedRow: null,
        selection: null,
        lazyLoadEvent: null,
        frozen: false,
        colHeight: ''
      };
    }
    let filters: TableFilterState[] = [];
    for (let col of this.ttColumns) {
      if (col.filter.length > 0) {
        let filter: TableFilterState = {
          field: col.field,
          filterModel: col.filterModel,
        };
        filters.push(filter);
      }
      this.tableStateParams[this._ttID].filters = filters;
    }
  }

  /** Status der Tabelle abrufen
   */
  public restoreTableState() {
    if (!this.activated || !this._ttable) {
      return;
    }
    /* Filtereinträge wiedergerstellen */
    if (!this.tableStateParams[this._ttID]) {
      this.saveTableState();
    }
    let filters: TableFilterState[] = this.tableStateParams[this._ttID].filters;
    try {
      for (let filter of filters) {
        this.ttColumns.find(col => col.field === filter.field).filterModel = filter.filterModel;
      }
    } catch (e) {
    }
    try {
      this._lastSelectedRow = this.tableStateParams[this._ttID].selectedRow;
    } catch (e) {
      this._lastSelectedRow = null;
    }
    try {
      this._ttable.first = this.tableStateParams[this._ttID].first;
    } catch (e) {
      this._ttable.first = 0;
    }
    try {
      this._ttable.selection = this.tableStateParams[this._ttID].selection;
    } catch (e) {
      this._ttable.selection = null;
    }
  }

  /** Status der Tabelle zurücksetzen
   * @param clearSelection
   */
  public clearTableState(clearSelection: boolean = true) {
    for (let col of this.ttColumns) {
      switch (col.filter) {
        case 'STR':
          col.filterModel = '';
          break;
        case 'BOOL':
        case 'CHECK':
        case 'DATE':
        case 'SELECT':
          col.filterModel = null;
          break;
      }
    }
    if (this._ttable) {
      this._ttable.reset();
      this._ttable.clearState();
    }
    try {
      sessionStorage.removeItem(this._ttID);
    } catch (e) {
    }
    try {
      clearSelection ? this.saveTableState(this.tableStateParams[this._ttID].frozen, this.tableStateParams[this._ttID].colHeight) : this.saveTableState(this.tableStateParams[this._ttID].frozen, this.tableStateParams[this._ttID].colHeight, this.tableStateParams[this._ttID].selectedRow);
    } catch (e) {
      this.saveTableState();
    }
  }

  /** Die Nummer der anzuzeigenden Seite im Table-Storage setzen */
  public setFirst(first: number) {
    let ttStorage: any = JSON.parse(sessionStorage.getItem(this._ttID));
    if (ttStorage !== null && ttStorage !== undefined) {
      ttStorage.first = first;
      sessionStorage.setItem(this._ttID, JSON.stringify(ttStorage));
    }
  }

  /** Die selektierte Zeile im Table-Storage setzen */
  public setSelectedRow(selectedRow: any) {
    let ttStorage: any = JSON.parse(sessionStorage.getItem(this._ttID));
    if (ttStorage !== null && ttStorage !== undefined) {
      ttStorage.selection = selectedRow;
      sessionStorage.setItem(this._ttID, JSON.stringify(ttStorage));
    }
  }

  /** Die Expanded Rows in den State-Storage schreiben, da es sonst Probleme beim Expanden bzw. Collapsen aller Rows aus der Anwendung gibgt */
  public setExpandedRows(expandedRows: { [key: string]: boolean }) {
    let ttStorage: any = JSON.parse(sessionStorage.getItem(this._ttID));
    if (ttStorage !== null && ttStorage !== undefined) {
      ttStorage.expandedRowKeys = expandedRows;
      sessionStorage.setItem(this._ttID, JSON.stringify(ttStorage));
    }
  }

}
