import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { MenuItem } from 'primeng';

@Injectable()
export class BreadcrumbService {

  private menuItemsSource = new Subject<MenuItem[]>();
  private toolbarItems: ToolbarItem[];
  private toolbarItemsSource = new Subject<ToolbarItem[]>();
  private showToolbar = new Subject<boolean>();
  private toolClicked = new Subject<ToolbarItem>();
  private resetToolbarSubmenu = new Subject<boolean>();
  private _mainToolbarItemClicked: boolean;

  menuItemsHandler = this.menuItemsSource.asObservable();
  toolbarItemsHandler = this.toolbarItemsSource.asObservable();
  showToolbarHandler = this.showToolbar.asObservable();
  toolClickedHandler = this.toolClicked.asObservable();
  resetToolbarSubmenuHandler = this.resetToolbarSubmenu.asObservable();

  /** Menü-Items für Breadcrumb initialisieren
    * @param {MenuItem[]} items */
  setMenuItems(items: MenuItem[]) {
    this.menuItemsSource.next(items);
    this.showToolbar.next(false);
  }

  /** Toolbar-Items initialisieren
   * @param {ToolbarItem[]} items */
  setToolbarItems(items: ToolbarItem[]) {
    this.toolbarItems = items;
    this.toolbarItemsSource.next(items);
  }

  /** Sichtbarkeit der Toolbar
   * @param {boolean} visible */
  setToolbarVisible(visible: boolean) {
    this.showToolbar.next(visible);
  }

  /** Event für die Subscriber auslösen
   * @param {ToolbarItem} item */
  raiseToolClickedEvent(item: ToolbarItem) {
    this.toolClicked.next(item);
  }

  /** Den disabled-Status eine Toolbar-Items ändern
   * @param {string} id
   * @param {boolean} disabled
   * @param {boolean} subitem */
  setToolbarItemDisabled(id: string, disabled: boolean, subitem: boolean = false) {
    if (subitem) {
      for (let tbItem of this.toolbarItems) {
        if (tbItem.submenu) {
          let actItem: ToolbarItem = tbItem.submenu.find(item => item.id === id);
          if (actItem) {
            tbItem.submenu.find(item => item.id === id).disabled = disabled;
          }
        }
      }
    } else {
      if (this.toolbarItems.find(item => item.id === id)) {
        this.toolbarItems.find(item => item.id === id).disabled = disabled;
      }
    }
    this.setToolbarItems(this.toolbarItems);
  }

  /** Submenu in der Toolbar wieder ausblenden
   *  @param {boolean} reset */
  resetSubmenu(reset: boolean) {
    if (!this.mainToolbarItemClicked) {
      this.resetToolbarSubmenu.next(reset);
    }
    this.mainToolbarItemClicked = false;
  }

  /** Flag wenn ein Toolbar-Item mit einem Submenü abgeklickt wurde
   * damit nicht auch der Wrapper-Click ausgelöst wird und somit
   * das Submenü nicht gleich wieder geschlossen wird
   * @returns {boolean}
   */
  get mainToolbarItemClicked(): boolean {
    return this._mainToolbarItemClicked;
  }
  set mainToolbarItemClicked(value: boolean) {
    this._mainToolbarItemClicked = value;
  }
}
