import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({
  name: 'selectKeyToValue'
})
export class SelectKeyToValuePipe implements PipeTransform {

  /** Pipe zur Transformation eines SelectItem Keys in das entsprechende
   * Label der SelectItem[] Liste
   * @param key
   * @param selectList
   */
  transform(key: string, selectList: SelectItem[]): any {
    try {
      if (selectList.find(item => item.value === key)) {
        return selectList.find(item => item.value === key).label;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

}
