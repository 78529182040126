import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthorizationService } from '../../../services/authorization.service';

@Component({
  /* Alt: Langing-Page als HTML-Template
    template: `<ffb-landing-page [subMessage]="logoutMessage"></ffb-landing-page>`,
    */
  /* Neu, leeres template */
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
              private authService: AuthorizationService) {
  }

  logoutMessage: string;

  ngOnInit() {
    const salutation = this.authService.getSalutation();
    this.authService.logout().then(loginState => {
      if (environment.production) {
        this.document.location.href = 'https://s-management-services.de/umfrage_anwendungen_pfp.html';
      } else {
        this.document.location.href = environment.apourl;
      }
      this.logoutMessage = 'Auf Wiedersehen ' + salutation + ', Sie haben sich erfolgreich abgemeldet.';
    });
  }

}
