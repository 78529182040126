import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FfbSettings } from '../../../classes/ffb-settings';
import { AppSettings } from '../../../interfaces/app-settings';
import { SettingsService } from '../../../services/settings-service';
import { UserSettings } from '../../../interfaces/user-settings';
import { HttpErrorResponse } from '@angular/common/http';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { MessageService } from 'primeng';

@Component({
  selector: 'ffb-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @ViewChild('settingsForm') settingsForm: NgForm;
  settings: AppSettings;

  constructor(private breadcrumbService: BreadcrumbService,
              private messageService: MessageService,
              private authService: AuthorizationService,
              private optSettings: SettingsService,
              private apoSettings: FfbSettings,
              public tableStateProvider: TableStateProvider) { }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Einstellungen'},
    ]);
    this.settings = this.apoSettings.getSettings();
  }

  /** Cookie-Settings müssen immer in den LocalStorage zurückgeschrieben werden. */
  updateSettings() {
    const uid: number = +this.authService.getUserData().result.id;
    let userSettings: UserSettings[] = [];
    for ( let setting of this.apoSettings.settings) {
      if (setting.visible !== 0) {
        if (setting.selectvalue) {
          setting.value = setting.selectvalue.key;
          userSettings.push({
            scope: this.apoSettings.scope,
            user_id: uid,
            key: setting.key,
            value: setting.value,
          });
        }
        if (setting.persist === 'COOKIE') {
          localStorage.setItem('pfp_' + setting.key, setting.value);
        }
      }
    }
    this.optSettings.updateUserSettings(userSettings).subscribe( res => {
      if (res.success) {
        this.messageService.add({life: 3500, severity: 'success', summary: 'Benutzereinstellungen', detail: 'Ihre persönlichen Einstellungen wurden aktualisiert.'});
        this.settingsForm.form.markAsPristine();
        /* Table State Provider ggf. aktivieren/deaktivieren */
        this.tableStateProvider.activated = (this.apoSettings.getOptionValue('SAVE_TABLE_STATE', true) === 'true');
      } else {
        this.messageService.add({life: 3500, severity: 'warn', summary: 'Benutzereinstellungen', detail: 'Beim aktualisieren der Einstellungen ist ein Fehler aufgetreten:' + '<br>' + res.msg});
      }
    }, (e: HttpErrorResponse) => {
      this.messageService.add({life: 3500, severity: 'warn', summary: 'Benutzereinstellungen', detail: 'Beim aktualisieren der Einstellungen ist ein Fehler aufgetreten:' + '<br>' + e.message});
    });

  }

  resetSettings() {
    this.optSettings.getAppSettings().subscribe( data => {
      this.settingsForm.reset();
      this.apoSettings.initApp(data);
      this.optSettings.getUserSettings().subscribe( data => {
        this.apoSettings.initUser(data);
        this.settings = this.apoSettings.getSettings();
        /* Table State Provider ggf. aktivieren/deaktivieren */
        this.tableStateProvider.activated = (this.apoSettings.getOptionValue('SAVE_TABLE_STATE', true) === 'true');
      });
    });
  }

}

