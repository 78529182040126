import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Comp2compMessage } from '../classes/comp2comp-message';

@Injectable()
export class ComponentCommunicationService {

  private msgsubject = new Subject<Comp2compMessage>();
  constructor() { }

  sendMessage(msg: Comp2compMessage) {
    this.msgsubject.next(msg);
  }

  getMessage(): Observable<Comp2compMessage> {
    return this.msgsubject.asObservable();
  }

  clearMessage() {
    this.msgsubject.next();
  }

}
