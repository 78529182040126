import { AppSettings } from '../interfaces/app-settings';
import { UserSettings } from '../interfaces/user-settings';
import { Option } from '../interfaces/option';
import { GroupSettings } from '../interfaces/group-settings';
import { Injectable } from '@angular/core';

@Injectable()
export class FfbSettings implements AppSettings {

  clientid: string;
  scope: string;
  url: string;
  success: boolean = false;
  msg?: string;
  settings: [{
    key: string;
    desc: string;
    type: string;
    default: string;
    persist: string;
    visible: number;
    options?: Option[];
    value?: string;
    selectvalue?: Option;
  }];

  groupSettings: GroupSettings[] = [];

  /** Initialisierung der Settings für die App hier werden
   * alle für diese App möglichen Settings vor dem Login eines Users ausgelesen.
   * In diesem Fall müssen die Werte der Settings die mit persist=COOKIE
   * gekennzeichnet sind, falls vorhanden, aus dem LocalStorage (Cookie) ausgelesen werden.
   * Dies wird für alle Werte benötigt die schon vor dem Login eines Users
   * greifen müssen (z.B. die bevorzugte Login-Art (E-Mail oder User-ID)
   * @param {AppSettings} data
   */
  initApp(data: AppSettings): void {
    this.clientid = data.clientid;
    this.scope = data.scope;
    this.url = data.url;
    this.success = data.success;
    this.msg = data.msg;
    for (let setting of data.settings) {
      setting.value = setting.default;
      if (setting.persist === 'COOKIE') {
        if (localStorage.getItem('pfp_' + setting.key)) {
          setting.value = localStorage.getItem('pfp_' + setting.key);
        }
      }
      if (setting.type === 'BOOL') {
        setting.options = [
          { key: 'true', desc: 'ja' },
          { key: 'false', desc: 'nein' }
        ];
      }
      if (setting.type !== 'TEXT') {
        setting.selectvalue =  setting.options.find(item => item.key === setting.value);
      }
    }
    this.settings = data.settings;
  }

  /** ggf. die Settings mit den vom Anwender geänderten Werten überschreiben
   * @param {UserSettings[]} us
   */
  initUser(us: UserSettings[]) {
    for (let setting of us) {
      let app_s = this.settings.find(item => item.key == setting.key);
      if (app_s) {
        app_s.value = setting.value;
        if (app_s.type !== 'text') {
          app_s.selectvalue =  app_s.options.find(item => item.key == setting.value);
        }
      }
    }
  }

  /** Die Allkgemeinen Gruppeneinstellungen initialisieren */
  initGroupSettings(gs: GroupSettings[]) {
    this.groupSettings = gs;
  }

  /** Einstellung aus den Settings über den Key auslesen
   * @param {string} key
   * @param _default
   * @returns {any}
   */
  getOptionValue(key: string, _default: any): any {
    try {
      let setting = this.settings.find(item => item.key == key);
      if (setting) {
        return setting.value;
      } else {
        return _default;
      }
    } catch (e) {
      return _default;
    }
  }

  /** Optionen einer Einstellungen (bei Auswahlfeldern) über den Key auslesen
   * @param {string} key
   * @param {any[]} def
   * @returns {any[]}
   */
  getOptionParameters(key: string, def: any[]): any[] {
    let setting = this.settings.find(item => item.key === key);
    if (setting) {
      let params: any[] = [];
      for (let param of setting.options ) {
        params.push(param.key);
      }
      return params;
    } else {
      return def;
    }
  }

  /** Methode zum Abruf der Settings
   * @returns {AppSettings}
   */
  getSettings(): AppSettings {
    return {
      clientid: this.clientid,
      scope: this.scope,
      url: this.url,
      success: this.success,
      msg: this.msg,
      settings: this.settings
    };
  }

  /** Eine bestimmte allgemeine Gruppeneinstellung zurückliefern */
  getGroupSetting(key: string, defaultValue: any = ''): string {
    if ( this.groupSettings.find( item => item.key === key)) {
      return this.groupSettings.find( item => item.key === key).value;
    }
    return defaultValue;
  }

  /* Die Basis-URL der App abrufen */
  getAppURL() {
    return this.url;
  }

}
