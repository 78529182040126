import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ToolbarItem } from '../interfaces/toolbar-item';

@Directive({
  selector: '[pfpToolbarTool]'
})
export class ToolbarToolDirective {

  @Input() pfpToolbarTool: ToolbarItem;
  @Output() toolbarItemClick = new EventEmitter();
  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (!this.pfpToolbarTool.disabled) {
      this.pfpToolbarTool.offsetLeft = event.target.offsetLeft - 9 + 'px';
      this.toolbarItemClick.emit(this.pfpToolbarTool);
    }
  }
}
