import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {

  constructor(private auth: AuthorizationService, private router: Router) {}

  canActivate(): boolean {
    if (!this.auth.checkLogin()) {
      console.log('FFB - Ihnen fehlt die Berechtigung zum Öffnen dieser Seite!');
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
