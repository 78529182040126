import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { FormData } from '../../../interfaces/form-data';
import { AuthorizationService } from '../../../services/authorization.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-form-details-read',
  templateUrl: './form-details-read.component.html',
  styleUrls: ['./form-details-read.component.css']
})
export class FormDetailsReadComponent implements OnInit, OnDestroy {
  /*Error Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /** Flags und Standardtexte */
  loading: boolean;
  blockPage: boolean = false;
  recall: boolean;
  idChanged: boolean;
  backRoute: string;
  backTooltip: string;
  ynOptions: SelectItem[] = [];
  composerOptions: SelectItem[] = [];
  formChanged: boolean = false;
  isFormAdmin: boolean = false;
  ready2Save: boolean = false;

  @ViewChild('editForm') editForm: NgForm;
  /** Parameter für Dialog */
  readonly: boolean = false;
  formData: FormData;
  formId: string = '';

  de: any;
  first: number = 0;

  /** Parameter für Confirm Dialoge */
  displayConfirmFileDeleteDialog: boolean;
  displayConfirmSaveDialog: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public breadcrumbService: BreadcrumbService,
              private authService: AuthorizationService,
              private messageService: MessageService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private fileDownloadService: FileDownloadService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      /** Falls direkt umgeschalten wurde zwischen Gesamtübersicht und Archivübersicht
       * dann muss auf jeden Fall unten getFormsAll() aufgerufen werden, da kein LazyLoad-Event
       * getriggert wird, da die Komonente ja dieselbe ist, deshalb der modeChanged Flag
       */
      if (params.id) {
        if (this.formId && this.formId !== params.id) {
          this.idChanged = true;
        }
        this.formId = params.id;
      }
    });

    this.initParams();

    this.backTooltip = 'Zurück zur Beitragsübersicht';

    this.backRoute = '/formsstockview'; // Todo backRoute aktualisieren
    this.breadcrumbService.setMenuItems([
      {label: 'Formulardetails'}
    ]);

    this.de = this.helper.calendarDe();

    this.blockPage = false;
  }

  initParams() {
    this.unSubscribeAll();
    this.loading = true;

    this.getForm();

    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];

    this.loading = false;
  }

  ngOnDestroy() {
    this.unSubscribeAll();
  }

  unSubscribeAll() {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  /**
   * Servicefunktionen
   */
  /** Formulardaten laden und anzeigen
   *
   */
  getForm() {
    const subscription: Subscription = this.formsService.getForm(this.authService.getJWT(), this.formId).subscribe(data => {
      subscription.unsubscribe();
      this.formData = data;
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      /* Auf Authentifizierung prüfen (Abgelaufener bzw. ungültiger JWT */
      if (e.status === 401) {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          }
        );
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler beim Abrufen der Daten',
          detail: 'Beim Abrufen der Formulardaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /**
   * Dialoge und Views
   */

  /** Formulardetail-Maske schliessen
   *
   */
  closeDetails() {
    this.router.navigate(['/formsstockview']);
    this.recall = true;
  }

  /**
   *
   *
   * Dateioperationen
   */

  /** Mime-Types der freigegebenen Dateitypen */
  getDocTypes(): string {
    let mimeTypes: string = 'application/zip';
    return mimeTypes;
  }

  /** Download attachment file */
  downloadFile() {
    const subscription: Subscription = this.fileDownloadService.downloadFile(this.authService.getJWT(), this.formData.id).subscribe(success => {
      subscription.unsubscribe();
      if (success === true) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Datei herunterladen',
          detail: 'Die Datei wurde heruntergeladen'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Herunterladen',
          detail: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten.<br>Bitte wenden Sie sich an den Support'
        });
      }
    });
  }

  /**
   *  Allgemeine Funktion für Fehlerhinweise
   */
  private showErrorMessage(e: HttpErrorResponse, msg: string) {
    this.error.hasError = true;
    this.error.msgTitle = msg;
    this.error.msgBody = 'Status: ' + e.status + '<br>' + e.message;
    console.log(e);
    this.messageService.add({
      sticky: true,
      severity: 'error',
      summary: this.error.msgTitle,
      detail: this.error.msgBody
    });
  }
}
