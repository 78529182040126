<p-blockUI [blocked]="blockPage"></p-blockUI>
<p-dialog header="Speichern" [modal]="true" [(visible)]="displayConfirmSaveDialog"
          class="ui-dialog-sm"
          (onHide)="closeConfirmSaveDialog()">
  <p-panel>
    <p-header>
      Wirklich speichern?
    </p-header>
    <form #fileDeleteForm="ngForm" id="saveForm" (ngSubmit)="saveForm()">
      <div class="ui-g">
        <div class="ui-g-6 ui-md-6 mt-5" style="text-align: right">
          <button pButton type="submit" label="Speichern" [disabled]="disableConfDialog"
                  class="dsvs-btn ui-button formBtn ui-button-text ui-corner-all"
                  icon="fa ui-icon-check"></button>
        </div>
        <div class="ui-g-6 ui-md-6 mt-5">
          <button pButton type="button" label="Abbrechen" (click)="closeConfirmSaveDialog()"
                  icon="fa ui-icon-undo"
                  class="ui-button-text dsvs-btn ui-corner-all mr-btn"></button>
        </div>
      </div>
    </form>
  </p-panel>
</p-dialog>

<p-confirmDialog header="Achtung" icon="" acceptLabel="Ja" rejectLabel="Nein" [closable]="false"
                 [style]="{'max-width':'400px','max-height':'200px'}"></p-confirmDialog>

<div class="ui-g" *ngIf="!loading">
  <div class="ui-lg-8 ui-md-10 ui-sm-12 ui-lg-offset-2 ui-md-offset-1">
    <div class="card">
      <h4 *ngIf="mode==='create'">Formular erstellen</h4>
      <h4 *ngIf="mode==='update'">Formulardaten bearbeiten</h4>
      <h4 *ngIf="mode==='read'">Formulardaten anzeigen</h4>
      <p-panel header="Formularanwendung und Metadaten">
        <form #editForm="ngForm" id="editForm" (ngSubmit)="openConfirmSaveDialog()">
          <div class="mt-1 mb-2">
            <div class="ui-g ui-fluid form-group">
              <div class="ui-g-6">
                <label style="color: #264b94">Forms-ID</label>
                <span class="md-inputfield mt-3">
                <input type="text" pInputText name="dsvsId" maxlength="10" required [(ngModel)]="dsvsId"
                       [style]="{'min-width':'240px'}" [disabled]="readonly">
              </span>
              </div>
              <div *ngIf="!metaFromZip" class="ui-g-4">
                <p-fileUpload #importfile
                              class="ui-button-text dsvs-btn ui-corner-all"
                              [disabled]="false"
                              mode="basic"
                              name="importJson"
                              accept=".json"
                              [customUpload]="true"
                              (onSelect)="initJsonFile($event,importfile)"
                              (uploadHandler)="importJsonFile($event,importfile)"
                              chooseLabel="JSON-Importdatei">
                </p-fileUpload>
              </div>
              <div class="ui-g-2">
                <button *ngIf="importSelected && !loading && !metaFromZip"
                        pButton
                        type="button"
                        class="red-btn ui-corner-all ui-button-icon-only ui-button-round"
                        pTooltip="Auswahl zurücksetzen"
                        icon="fa ui-icon-delete"
                        (click)="jsonFileBrowser?.clear()"></button>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">Formulartitel</label>
                <span class="md-inputfield mt-3">
                  <input type="text" pInputText name="title" maxlength="500" required [(ngModel)]="title"
                         [style]="{'min-width':'240px'}" [disabled]="readonly">
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">PDF-Content</label>
                <span class="md-inputfield mt-3">
                  <input type="text" pInputText name="khId" maxlength="500" required [(ngModel)]="khId"
                         [style]="{'min-width':'240px'}" [disabled]="readonly">
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">PDF-Version</label>
                <span class="md-inputfield mt-3">
                  <input type="text" pInputText name="khVersion" maxlength="500" [(ngModel)]="khVersion"
                         [style]="{'min-width':'240px'}" [disabled]="readonly">
                </span>
              </div>
              <div class="ui-g-6">
                <label style="color: #264b94">Forms-Version</label>
                <span class="md-inputfield mt-3">
                  <input type="text" pInputText name="formsAssistantVersion" maxlength="500" required
                         [(ngModel)]="formsAssistantVersion" [style]="{'min-width':'240px'}" [disabled]="readonly">
                </span>
              </div>
              <div class="ui-g-6">
                <label style="color: #264b94">Standardversion</label>
                <span class="md-inputfield mt-3">
                    <p-dropdown [options]="ynOptions" placeholder="Bitte auswählen"
                                name="isStandard" [(ngModel)]="isStandard"
                                id="isStandard"
                                [style]="{'min-width':'100px'}" [disabled]="readonly" required>
                    </p-dropdown>
                  </span>
              </div>
              <div class="ui-g-6">
                <label style="color: #264b94">Individual-Version</label>
                <span class="md-inputfield mt-3">
                  <input type="text" pInputText name="iVersion" maxlength="500"
                         [(ngModel)]="iVersion" [style]="{'min-width':'240px'}" [disabled]="readonly">
                </span>
              </div>
              <div class="ui-g-6">
                <label style="color: #264b94">Benutzte Composer-Version</label>
                <span class="md-inputfield mt-3">
                    <input type="text" pInputText name="composerVersion" maxlength="500" required [(ngModel)]="composerVersion"
                           [style]="{'min-width':'100px'}" [disabled]="readonly">
                  </span>
              </div>
              <div *ngIf="this.mode !=='create'" class="ui-g-12">
                <label style="color: #264b94">Release Notes</label>
                <span class="md-inputfield mt-3">
                  <textarea pInputTextarea [rows]="3"  name="releaseNotes" [(ngModel)]="releaseNotes"></textarea>
                </span>
              </div>
              <div *ngIf="this.mode !=='create'" class="ui-g-6">
                <label style="color: #264b94">erstellt am</label>
                <div class="mt-3">{{ createdDate | date: 'dd.MM.yyyy' }}</div>
              </div>
              <div *ngIf="this.mode !=='create' && this.isFormAdmin" class="ui-g-6">
                <label style="color: #264b94">erstellt von</label>
                <div class="mt-3">{{ createdBy }}</div>
              </div>
              <div *ngIf="this.mode !=='create'" class="ui-g-6">
                <label style="color: #264b94">geändert am</label>
                <div class="mt-3">{{ lastModifiedDate | date: 'dd.MM.yyyy' }}</div>
              </div>
              <div *ngIf="this.mode !=='create' && this.isFormAdmin" class="ui-g-6">
                <label style="color: #264b94">geändert von</label>
                <div class="mt-3">{{ lastModifiedBy }}</div>
              </div>
            </div>
            <div *ngIf="this.mode !== 'create'" class="flex-card ui-corner-all  ui-g-12"
                 style="padding-top: 1.25em; padding-bottom: 1.25em">
              <div class="ui-g ui-g-nopad">
                <div class="ui-g-12">
                  <span style="font-size: 14px;color: #264b94">Formularanwendung</span>
                  <table class="table-border mt-5">
                    <tbody>
                    <tr>
                      <td width="160px" class="label">
                        Dateiname
                      </td>
                      <td style="word-wrap: break-word">
                        <span *ngIf="this.FAFile">{{ this.FAFile.originalFileName }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="160px" class="label">
                        Bereitgestellt am
                      </td>
                      <td>
                        <span *ngIf="this.FAFile">{{ this.FAFile.createdDate | date: 'dd.MM.yyyy' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <button pButton type="button" [disabled]="!this.FAFile" (click)="downloadFile()"
                                class="ui-button-text-icon-left dsvs-btn ui-corner-all" style="width: 100%"
                                label="Formularanwendung herunterladen"
                                icon="fa ui-icon-file-download"></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <ng-container *ngIf="this.isFormAdmin && !this.readonly">
              <p-fieldset legend="Formularanwendung hochladen">
                <div class="mb-3">
                  <p-checkbox name="metaFromZip" [(ngModel)]="this.metaFromZip" [binary]="true" label="Metadaten aus Formularanwendung (zip) auslesen" [value]="true"></p-checkbox>
                </div>
                <p-fileUpload #docBrowser
                              class="ui-button-text dsvs-btn ui-corner-all"
                              [accept]="getDocTypes()"
                              (onSelect)="initDoc($event, docBrowser)"
                              [multiple]="false"
                              [showUploadButton]="false"
                              [showCancelButton]="false"
                              chooseLabel="Datei auswählen"
                              invalidFileTypeMessageSummary="{0}: Ungültiger Dokumententyp."
                              invalidFileTypeMessageDetail="Erlaubte Typen: *.zip"
                              [disabled]="readonly">
                </p-fileUpload>
              </p-fieldset>
            </ng-container>
            <div class="ui-g-12 mt-2">
              <div class="ui-g-3">
                <button
                    pButton
                    type="button"
                    class="ui-button-text dsvs-btn ui-corner-all mr-btn"
                    label="zurück"
                    (click)="closeDetails()"
                    icon="fa ui-icon-undo">
                </button>
              </div>
              <div *ngIf="mode!=='read'" class="ui-g-offset-1 ui-g-3">
                <button
                    pButton
                    type="submit"
                    name="erstellen"
                    class="dsvs-btn ui-corner-all"
                    [label]="labelSaveBtn"
                    icon="pi pi-pencil"
                    [disabled]="editForm.invalid">
                </button>
              </div>
              <div *ngIf="mode==='create'" class="ui-g-offset-1 ui-g-3">
                <button
                    pButton
                    type="button"
                    name="erstellenzuordnen"
                    class="dsvs-btn ui-corner-all"
                    label="Erstellen + Zuordnen"
                    [disabled]="editForm.invalid"
                    (click)="createAndAssign()">
                </button>
              </div>
            </div>
            <div class="ui-g-12">
              <div class="ui-g-3"></div>
              <div *ngIf="mode==='update'" class="ui-g-offset-1 ui-g-3">
                <p-checkbox name="sendMail" [(ngModel)]="this.sendMail" [binary]="true" label="mit E-Mailversand"
                            [value]="true"></p-checkbox>
              </div>
            </div>
            <div style="clear:both">
            </div>
          </div>
        </form>
      </p-panel>
    </div>
  </div>
</div>
