<p-blockUI [blocked]="blockPage"></p-blockUI>
<p-confirmDialog header="Bestätigung" icon="" [style]="{'max-width': '640px'}" acceptLabel="Ja" rejectLabel= "Nein"></p-confirmDialog>

<div class="ui-g">
  <div class="ui-g-12 card card-w-title">
    <ffb-fix-columns-title *ngIf="!loading" title="Bestandsübersicht Formularanwendungen" [isFixed]="frozen" [colHeight]="colHeight" [showfix]="true" (fixedChanged)="onFixedChanged($event)" (colHeightChanged)="onColHeightChanged($event)"></ffb-fix-columns-title>

    <ng-container *ngIf="!frozen">
      <p-table #ttable [loading]="loading" loadingIcon="fa ui-icon-sync" [lazy]="lazyLoad" [lazyLoadOnInit]="lazyOnInit" [totalRecords]="totalRecords"
               [resetPageOnSort]="false" (onLazyLoad)="getFormsDataLazy($event)" [first]="first" [scrollable]="true" [scrollHeight]="scrollHeight"
               [columns]="columns" [value]="formsStockData" [rows]="dtrows" [(selection)]="selectedForm" selectionMode="single" (onRowSelect)="onRowSelect($event)"
               (onRowUnselect)="onRowUnselect($event)" (onSort)="onSort()" dataKey="id" [paginator]="true" [pageLinks]="5"
               [rowsPerPageOptions]="dtrowsPerPage" [responsive]="false" [stateStorage]="ttStateStorage" [stateKey]="ttID" (onStateRestore)="onTableStateRestore()">
        <ng-template pTemplate="caption">
          <div>
            <span>Bestandsübersicht - {{authService.getInstituteData().name}}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup >
            <col *ngFor="let col of columns" [hidden]="col.hidden" [width]="col.width">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [hidden]="col.hidden" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sort" [pTooltip]="col.tooltip" tooltipPosition="top">
              {{col.header}}
              <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [hidden]="col.hidden" [ngSwitch]="col.filter">
            <span *ngSwitchCase="'STR'">
              <input pInputText type="text" [(ngModel)]="col.filterModel" (input)="customFilter($event.target.value, col.field,'contains')" style="width: calc(100% - 1.25rem)"><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
              <span *ngSwitchCase="'BOOL'">
               <p-dropdown [options]="ynOptions" [(ngModel)]="col.filterModel" (onChange)="customFilter($event.value, col.field,'equals')" appendTo="body" styleClass="tt-dropdown-filter"></p-dropdown><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
              <span *ngSwitchCase="'SELECT'">
                <p-dropdown [options]="col.selectList" [(ngModel)]="col.filterModel" (onChange)="customFilter($event.value, col.field,'equals')" appendTo="body" styleClass="tt-dropdown-filter"></p-dropdown><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" >
            <td *ngFor="let col of columns" [hidden]="col.hidden" [pTooltip]="rowData['dsvsId']" tooltipPosition="bottom" [escape]="false" [ngStyle]="{'text-align': col.style }">
              <ng-container *ngIf="helper.dataIsDefined(rowData, col.field)">
                <span *ngIf="!col.format">{{rowData[col.field]}}</span>
                <span *ngIf="col.format=='DATE'">{{ helper.isValidDate(rowData[col.field]) ? (rowData[col.field] | date : 'dd.MM.yyyy') : null }}</span>
                <span *ngIf="col.format=='DATETIME'">{{ helper.isValidDate(rowData[col.field]) ? (rowData[col.field] | date : 'dd.MM.yyyy HH:mm') : null }}</span>
                <span *ngIf="col.format=='YN'">{{rowData[col.field] | boolToYesNo }}</span>
                <span *ngIf="col.format=='SELECT'">{{rowData[col.field] | selectKeyToValue : col.selectList }}</span>
                <span *ngIf="col.format=='LENGTH'">{{rowData[col.field] | arrayLength }}</span>
              </ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length-1">
              <span>Keine Formulare gefunden...</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="mt-4 text-dsvs tt-footer-info">
        <span *ngIf="!hasFilter" style="margin-right: 1rem">Formulare insgesamt: {{ totalRecords }}</span>
        <span *ngIf="hasFilter" style="margin-right: 1rem">Gefilterte Formulare: {{ totalRecords }}</span>
        <!--
        <span *ngIf="filteredRecords !== totalRecords" style="margin-left: 1rem">
          <i class="fas fas-filter tt-filter-icon"></i>: {{ filteredRecords }}
        </span>
        -->
      </div>
    </ng-container>

    <ng-container *ngIf="frozen">
      <p-table #ttable [loading]="loading" loadingIcon="fa ui-icon-sync" [lazy]="lazyLoad" [lazyLoadOnInit]="lazyOnInit" [totalRecords]="totalRecords"
               [resetPageOnSort]="false" (onLazyLoad)="getFormsDataLazy($event)" [first]="first" [scrollable]="true" [scrollHeight]="scrollHeight"
               [columns]="columns" [value]="formsStockData" [rows]="dtrows" [(selection)]="selectedForm" selectionMode="single" (onRowSelect)="onRowSelect($event)"
               (onRowUnselect)="onRowUnselect($event)" (onSort)="onSort()" dataKey="id" [paginator]="true" [pageLinks]="5"
               [rowsPerPageOptions]="dtrowsPerPage" [responsive]="false" [stateStorage]="ttStateStorage" [stateKey]="ttID" (onStateRestore)="onTableStateRestore()"
               [frozenColumns]="frozenColumns" [frozenWidth]="frozenWidth">
        <ng-template pTemplate="caption">
          <div>
            <span>Bestandsübersicht - {{authService.getInstituteData().name}}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup >
            <col *ngFor="let col of columns" [hidden]="col.hidden" [width]="col.width">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [hidden]="col.hidden" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sort">
              {{col.header}}
              <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [hidden]="col.hidden" [ngSwitch]="col.filter" [ngStyle]="{'height': frozenHeaderHeight}">
            <span *ngSwitchCase="'STR'">
              <input pInputText type="text" [(ngModel)]="col.filterModel" (input)="customFilter($event.target.value, col.field,'contains')" style="width: calc(100% - 1.25rem)"><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
              <span *ngSwitchCase="'BOOL'">
               <p-dropdown [options]="ynOptions" [(ngModel)]="col.filterModel" (onChange)="customFilter($event.value, col.field,'equals')" appendTo="body" styleClass="tt-dropdown-filter"></p-dropdown><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
              <span *ngSwitchCase="'SELECT'">
                <p-dropdown [options]="col.selectList" [(ngModel)]="col.filterModel" (onChange)="customFilter($event.value, col.field,'equals')" appendTo="body" styleClass="tt-dropdown-filter"></p-dropdown><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
            </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td *ngFor="let col of columns" [hidden]="col.hidden" [pTooltip]="rowData['articleNumber']" tooltipPosition="bottom" [escape]="false" [ngStyle]="{'text-align': col.style }">
              <div *ngIf="helper.dataIsDefined(rowData, col.field)" class="div-h-center tt-fixed-column" [ngStyle]="{'height': colHeight}">
                <span *ngIf="!col.format">{{rowData[col.field]}}</span>
                <span *ngIf="col.format=='DATE'">{{ helper.isValidDate(rowData[col.field]) ? (rowData[col.field] | date : 'dd.MM.yyyy') : null }}</span>
                <span *ngIf="col.format=='DATETIME'">{{ helper.isValidDate(rowData[col.field]) ? (rowData[col.field] | date : 'dd.MM.yyyy HH:mm') : null }}</span>
                <span *ngIf="col.format=='YN'">{{rowData[col.field] | boolToYesNo }}</span>
                <span *ngIf="col.format=='SELECT'">{{rowData[col.field] | selectKeyToValue : col.selectList }}</span>
                <span *ngIf="col.format=='LENGTH'">{{rowData[col.field] | arrayLength }}</span>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td *ngIf="columns.length === frozenColumns.length" [attr.colspan]="columns.length-1">
              <span>Keine Formulare gefunden...</span>
            </td>
            <td *ngIf="columns.length !== frozenColumns.length" [attr.colspan]="columns.length">
              <span>&nbsp;</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="mt-4 text-dsvs tt-footer-info">
        <span *ngIf="!hasFilter" style="margin-right: 1rem">Formulare insgesamt: {{ totalRecords }}</span>
        <span *ngIf="hasFilter" style="margin-right: 1rem">Gefilterte Formulare: {{ totalRecords }}</span>
      </div>
    </ng-container>
  </div>
</div>
