export const environment = {
  // version: '0.9.0.1',
  version: (<any>window).docker.version,
  production: true,
  scope: 'PFP',
  zapurl: (<any>window).docker.zapUrl,
  apourl: (<any>window).docker.apoUrl,
  backend: '/api/v1/',
  zapinit: (<any>window).docker.zapInit
};
