import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUpload, MessageService, SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { AttachmentFile } from '../../../classes/attachment-file';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { FileType } from '../../../enums/file-type.enum';
import { FileData } from '../../../interfaces/file-data';
import { FormData } from '../../../interfaces/form-data';
import { JsonData } from '../../../interfaces/json-data';
import { AuthorizationService } from '../../../services/authorization.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { ZapService } from '../../../services/zap.service';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import * as JSZip from 'jszip';


@Component({
  selector: 'pfp-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.css']
})
export class FormDetailsComponent implements OnInit, OnDestroy {
  /*Error Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /** Flags und Standardtexte */
  loading: boolean;
  blockPage: boolean = false;
  mode: string;                 // mode=DSVS: Formular-AG Informationen  /  mode=FA: FA Rundschreiben
  modeChanged: boolean;         // es wurde direkt zwischen Archiv- und Gesamtansicht gewechselt - bei Lazy-Loading muss ein LazyLoad-Event getriggert werden
  recall: boolean;
  idChanged: boolean;
  backRoute: string;
  backTooltip: string;
  ynOptions: SelectItem[] = [];
  composerOptions: SelectItem[] = [];
  formChanged: boolean = false;
  isFormAdmin: boolean = false;
  ready2Save: boolean = false;
  disableConfDialog: boolean = false;

  @ViewChild('editForm') editForm: NgForm;
  /** Parameter für Dialog */
  readonly: boolean = false;
  formData: FormData;
  formId: string = '';
  dsvsId: string = '';
  title: string = '';
  khId: string = '';
  khVersion: string = '';
  formsAssistantVersion: string = '';
  isStandard: boolean = false;
  composerVersion: string = '';
  uploadFile: AttachmentFile;
  createdDate: Date | string = '';
  createdBy: string = '';
  lastModifiedDate: Date | string = '';
  lastModifiedBy: string = '';
  deletedDate: Date | string = '';
  deletedBy: string = '';
  FAFile: FileData = null;
  sendMail: boolean = true;
  metaFromZip: boolean = true;
  releaseNotes: string = '';
  iVersion: string = '';

  importSelected: boolean = false;
  importFilesSelected: boolean = false;

  de: any;
  labelSaveBtn: string = 'Speichern';

  first: number = 0;

  /** Parameter für Confirm Dialoge */
  displayConfirmFileDeleteDialog: boolean;
  displayConfirmSaveDialog: boolean;

  /** Json Import */
  fileObject: File;
  fileName: string;
  fileSize: number;
  fileContent: string | ArrayBuffer | null;
  metaData: JsonData[] = [];
  jsonFileBrowser: FileUpload;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public breadcrumbService: BreadcrumbService,
              private authService: AuthorizationService,
              private messageService: MessageService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private fileDownloadService: FileDownloadService,
              private fileUploadService: FileUploadService,
              private zapService: ZapService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      /** Falls direkt umgeschalten wurde zwischen Gesamtübersicht und Archivübersicht
       * dann muss auf jeden Fall unten getFormsAll() aufgerufen werden, da kein LazyLoad-Event
       * getriggert wird, da die Komonente ja dieselbe ist, deshalb der modeChanged Flag
       */
      /*
      * modes 'create', 'update', 'read'
      *
      *  */
      if (this.mode && this.mode !== params.mode) {
        this.modeChanged = true;
      }
      this.mode = params.mode;
      if (this.mode === 'read') {
        this.readonly = true;
      } else {
        this.readonly = false;
      }

      if (params.id) {
        if (this.formId && this.formId !== params.id) {
          this.idChanged = true;
        }
        this.formId = params.id;
      }
    });

    this.initParams();

    this.backTooltip = 'Zurück zur Beitragsübersicht';

    if (this.mode === 'create') {
      this.backRoute = '/formspoolview'; // Todo backRoute aktualisieren
      this.breadcrumbService.setMenuItems([
        {label: 'Formulareintrag erstellen'}
      ]);
      this.labelSaveBtn = 'Erstellen';
    } else {
      this.backRoute = '/formspoolview'; // Todo backRoute aktualisieren
      this.breadcrumbService.setMenuItems([
        {label: 'Formulareintrag ändern'}
      ]);
    }
    this.de = this.helper.calendarDe();

    this.blockPage = false;
  }

  initParams() {
    this.unSubscribeAll();
    this.loading = true;

    if (this.mode !== 'create') {
      this.getForm();
    }

    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];

    this.composerOptions = [
      {label: '', value: null},
      {label: '2020.05', value: '2020.05'}
    ];

    switch (this.mode) {
      case 'create':
        this.breadcrumbService.setMenuItems([
          {label: 'Formulareintrag erstellen/Neues Formular'}
        ]);
        break;
      case 'read':
        this.breadcrumbService.setMenuItems([
          {label: 'Formulareintrag anzeigen/' + this.dsvsId}
        ]);
        break;
      case 'update':
        this.breadcrumbService.setMenuItems([
          {label: 'Formulareintrag ändern/' + this.dsvsId}
        ]);
        break;
    }
    if (this.authService.hasRight('PFP_CONFIG_EDIT_FORMS_DATA')) {
      this.isFormAdmin = true;
    }

    this.loading = false;
  }

  ngOnDestroy() {
    this.unSubscribeAll();
  }

  unSubscribeAll() {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  /**
   * Servicefunktionen
   */
  /** Formulardaten laden und anzeigen
   *
   */
  getForm() {
    const subscription: Subscription = this.formsService.getForm(this.authService.getJWT(), this.formId).subscribe(data => {
      subscription.unsubscribe();
      this.formData = data;
      this.dsvsId = this.formData.dsvsId;
      this.title = this.formData.title;
      this.khId = this.formData.khId;
      this.khVersion = this.formData.khVersion;
      this.formsAssistantVersion = this.formData.formsAssistantVersion;
      this.releaseNotes = this.formData.releaseNotes;
      this.isStandard = this.formData.isStandard;
      this.composerVersion = this.formData.composerVersion;
      this.iVersion = this.formData.iversion;
      this.FAFile = this.formData.zipFile;
      this.releaseNotes = this.formData.releaseNotes;
      this.createdDate = this.formData.createdDate;
      this.lastModifiedDate = this.formData.lastModifiedDate;
      this.deletedDate = this.formData.deletedDate;

      /* Vollständige Namen abrufen */
      if (this.formData.createdById) {
        const sub2: Subscription = this.zapService.getUserName(this.authService.getJWT(), this.formData.createdById).subscribe(user => {
          sub2.unsubscribe();
          this.createdBy = user.fullname;
        });
      }
      if (this.formData.lastModifiedById) {
        const sub2: Subscription = this.zapService.getUserName(this.authService.getJWT(), this.formData.lastModifiedById).subscribe(user => {
          sub2.unsubscribe();
          this.lastModifiedBy = user.fullname;
        });
      }
      if (this.formData.deletedById) {
        const sub2: Subscription = this.zapService.getUserName(this.authService.getJWT(), this.formData.deletedById).subscribe(user => {
          sub2.unsubscribe();
          this.deletedBy = user.fullname;
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      /* Auf Authentifizierung prüfen (Abgelaufener bzw. ungültiger JWT */
      if (e.status === 401) {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          }
        );
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler beim Abrufen der Daten',
          detail: 'Beim Abrufen der Formulardaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  saveForm() {
    this.disableConfDialog = true;
    if (this.mode === 'create') {
      this.createForm();
    } else {
      this.updateForm();
    }
  }

  /** Formular(daten) anlegen und Formularanwendung hochladen
   *
   */
  createForm() {
    // this.blockPage = true;
    let newForm: FormData;
    newForm = {
      dsvsId: this.dsvsId,
      title: this.title,
      khId: this.khId,
      khVersion: this.khVersion,
      formsAssistantVersion: this.formsAssistantVersion,
      releaseNotes: this.releaseNotes,
      isStandard: this.isStandard,
      composerVersion: this.composerVersion,
      iversion: this.iVersion
    };
    const subscription: Subscription = this.formsService.createForm(this.authService.getJWT(), newForm).subscribe((data) => {
      subscription.unsubscribe();
      this.formChanged = false;
      this.editForm.form.markAsPristine();
      if (this.uploadFile) {
        const sub2: Subscription = this.fileUploadService.uploadFAFile(this.authService.getJWT(), this.uploadFile.fileObject, data.id.toString()).subscribe(result => {
          sub2.unsubscribe();
          this.blockPage = false;
          if (result.successful) {
            this.messageService.add({
              severity: 'success',
              summary: 'Neuer Formularassistent',
              detail: 'Der Formularassistent wurde angelegt!'
            });
          } else {
            this.messageService.add({
              life: 3500,
              severity: 'error',
              summary: 'Dateiupload',
              detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.<br>Bitte erneut versuchen.'
            });
          }
          this.closeDetails();
        }, (e: HttpErrorResponse) => {
          this.blockPage = false;
          sub2.unsubscribe();
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Dateiupload',
            detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 401) {
        this.messageService.add({
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else if (e.status === 405 || e.status === 500) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Doppelte Forms-ID',
          detail: 'Die Forms-ID existiert bereits.'
        });
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Neuer Formularassistent',
          detail: 'Beim Anlegen des Formularassistenten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Formulardaten speichern und Formularanwendung hochladen
   *
   */
  updateForm() {
    // this.blockPage = true;
    let newForm: FormData;
    newForm = {
      dsvsId: this.dsvsId,
      title: this.title,
      khId: this.khId,
      khVersion: this.khVersion,
      formsAssistantVersion: this.formsAssistantVersion,
      releaseNotes: this.releaseNotes,
      isStandard: this.isStandard,
      composerVersion: this.composerVersion,
      iversion: this.iVersion,
    };
    const subscription: Subscription = this.formsService.saveForm(this.authService.getJWT(), this.formId, newForm).subscribe((data) => {
      subscription.unsubscribe();
      this.formChanged = false;
      this.editForm.form.markAsPristine();
      if (this.uploadFile) {
        const sub2: Subscription = this.fileUploadService.uploadFAFile(this.authService.getJWT(), this.uploadFile.fileObject, data.id.toString()).subscribe(result => {
          this.blockPage = false;
          sub2.unsubscribe();
          if (!result.successful) {
            this.messageService.add({
              life: 3500,
              severity: 'error',
              summary: 'Dateiupload',
              detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.<br>Bitte erneut versuchen.'
            });
          }
          this.closeDetails();
        }, (e: HttpErrorResponse) => {
          sub2.unsubscribe();
          this.blockPage = false;
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Dateiupload',
            detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        });
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Neuer Formularassistent',
        detail: 'Der Formularassistent wurde aktualisiert.'
      });
      if (this.sendMail) {
        this.notifyInstitutes();
      }
      this.closeDetails();
    }, (e: HttpErrorResponse) => {
      this.blockPage = false;
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else if (e.status === 405) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Doppelte Forms-ID',
          detail: 'Die Forms-ID existiert bereits.'
        });
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Neuer Formularassistent',
          detail: 'Beim Anlegen des Formularassistenten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Formular(daten) anlegen, Formularanwendung hochladen und Zuordnungstabelle (Company) aufrufen
   *
   */
  createAndAssign() {
    this.blockPage = true;
    let newForm: FormData;
    newForm = {
      dsvsId: this.dsvsId,
      title: this.title,
      khId: this.khId,
      khVersion: this.khVersion,
      formsAssistantVersion: this.formsAssistantVersion,
      releaseNotes: this.releaseNotes,
      isStandard: this.isStandard,
      composerVersion: this.composerVersion,
      iversion: this.iVersion
    };
    if (!this.uploadFile) {
      this.blockPage = false;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Zip-Datei fehlt',
        detail: 'Bitte vor dem Speichern eine Zip-Datei auswählen.'
      });
      return false;
    }
    const subscription: Subscription = this.formsService.createForm(this.authService.getJWT(), newForm).subscribe((data) => {
      subscription.unsubscribe();
      this.formChanged = false;
      this.editForm.form.markAsPristine();
      if (this.uploadFile) {
        const sub2: Subscription = this.fileUploadService.uploadFAFile(this.authService.getJWT(), this.uploadFile.fileObject, data.id.toString()).subscribe(result => {
          sub2.unsubscribe();
          this.blockPage = false;
          if (result.successful) {
            this.messageService.add({
              severity: 'success',
              summary: 'Neuer Formularassistent',
              detail: 'Der Formularassistent wurde angelegt!'
            });
            this.closeDetails();
            /* weiter zur Zuordnung (Company) */
            this.router.navigate(['/configviewclients/batchassign/' + data.id.toString()]);
          } else {
            this.messageService.add({
              life: 3500,
              severity: 'error',
              summary: 'Dateiupload',
              detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.<br>Bitte erneut versuchen.'
            });
            this.blockPage = false;
            this.closeDetails();
          }
        }, (e: HttpErrorResponse) => {
          sub2.unsubscribe();
          this.blockPage = false;
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Dateiupload',
            detail: 'Beim Hochladen der Datei ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 401) {
        this.messageService.add({
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else if (e.status === 405 || e.status === 500) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Doppelte Forms-ID',
          detail: 'Die Forms-ID existiert bereits.'
        });
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Neuer Formularassistent',
          detail: 'Beim Anlegen des Formularassistenten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });

  }

  /** Mandanten benachrichtigen
   *
   */
  notifyInstitutes() {
    const subscription: Subscription = this.formsService.notifyInstitutes(this.authService.getJWT(), this.formId).subscribe((data) => {
      subscription.unsubscribe();
      if (data.successful) {
        this.messageService.add({
          severity: 'success',
          summary: 'Benachrichtigung',
          detail: 'Die Mandanten wurden benachrichtigt!'
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Benachrichtigung',
          detail: 'Diese Formularanwendung ist keinen Mandanten zugeordnet.'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Benachrichtigung',
          detail: 'Beim Benachrichtigen der Mandanten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /**
   * Dialoge und Views
   */

  /** Formulardetail-Maske schliessen
   *
   */
  closeDetails() {
    if (this.mode === 'create' || this.mode === 'update') {
      this.router.navigate(['/configformspool']);
      this.recall = true;
    } else if (this.mode === 'read') {
      this.router.navigate(['/formsstockview']);
      this.recall = true;
    }
  }

  openConfirmFileDeleteDialog(file: File) {
    this.uploadFile.fileObject = file;
    this.displayConfirmFileDeleteDialog = true;
  }

  closeConfirmSaveDialog() {
    this.displayConfirmSaveDialog = false;
    this.disableConfDialog = false;
  }

  openConfirmSaveDialog() {
    if (this.mode === 'create' && !this.uploadFile) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Zip-Datei fehlt',
        detail: 'Bitte vor dem Speichern eine Zip-Datei auswählen.'
      });
      return false;
    } else {
      this.displayConfirmSaveDialog = true;
    }

  }

  /**
   *
   *
   * Dateioperationen
   */

  /** Mime-Types der freigegebenen Dateitypen */
  getDocTypes(): string {
    let mimeTypes: string = 'application/zip,.zip';
    return mimeTypes;
  }

  /** Download attachment file */
  downloadFile() {
    const subscription: Subscription = this.fileDownloadService.downloadFile(this.authService.getJWT(), this.formData.id).subscribe(success => {
      subscription.unsubscribe();
      if (success === true) {
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Datei herunterladen',
          detail: 'Die Datei wurde heruntergeladen'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Herunterladen',
          detail: 'Beim Herunterladen der Datei ist ein Fehler aufgetreten.<br>Bitte wenden Sie sich an den Support'
        });
      }
    });
  }

  /** Check file */
  initDoc(event, docBrowser: FileUpload) {
    for (let file of event.files) {
      this.uploadFile = new AttachmentFile();
      this.uploadFile.initFile(file);
      if (this.uploadFile.fileType === FileType.Invalid) {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Ungültiges Dateiformat!',
          detail: 'Dieses Dateiformat wird von FFB PFP für diesen Prozess nicht unterstützt.'
        });
        docBrowser.clear();
      } else if (this.uploadFile.fileSize > 20480) {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Ungültige Dateigröße!',
          detail: 'Die Größe der Datei überschreitet die maximal zulässige Größe von 20 MB.'
        });
        docBrowser.clear();
      } else if (this.uploadFile.fileSize === 0) {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Ungültige Dateigröße!',
          detail: 'Die Datei hat keinen Inhalt'
        });
        docBrowser.clear();
      } else {
        this.formChanged = true;
        if (this.metaFromZip) {
          this.extractMetaFromZip();
        }
        if (!this.editForm.invalid) {

        }
      }
    }
  }

  /** Metadaten direkt aus der Formularanwendung (zip) auslesen
   *
   * @private
   */
  private extractMetaFromZip() {
    JSZip.loadAsync(this.uploadFile.fileObject).then(zip => {
      if (zip.file('metadaten.json')) {
        try {
          zip.file('metadaten.json').async('blob').then(metadata => {
            const reader: FileReader = new FileReader();
            reader.readAsText(metadata);
            reader.onloadend = (( e: any ) => {
              const jsonData: string = e.target.result;
              this.metaData = JSON.parse(jsonData);
              this.loading = true;
              this.dsvsId = this.metaData[0].formsid;
              this.title = this.metaData[0].titel;
              this.khId = this.metaData[0].pdfcontent;
              this.khVersion = this.metaData[0].pdfversion;
              this.formsAssistantVersion = this.metaData[0].version;
              if (this.metaData[0].standard === 'ja') {
                this.isStandard = true;
              } else {
                this.isStandard = false;
              }
              this.composerVersion = this.metaData[0].composerversion;
              this.releaseNotes = this.metaData[0].releasenotes;
              this.iVersion = this.metaData[0].iversion;
              this.loading = false;
            });
          });
        } catch (e) {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Fehler in JSON-Importdatei!',
            detail: 'Beim Datenimport ist ein Fehler aufgetreten.<br>'
          });
        }
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler in JSON-Importdatei!',
          detail: 'Beim Datenimport ist ein Fehler aufgetreten.<br>'
        });
      }
    });
  }

  /** JSON-Importfile */
  /** Check File */
  initJsonFile(event, fileupload: FileUpload) {
    this.jsonFileBrowser = fileupload;
    if (event.files.length > 0) {
      this.initFile(event.files[0]);

      if (!this.isValidFile()) {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Ungültiges Dateiformat!',
          detail: 'Dieses Dateiformat wird von FMS für den Datenimport nicht unterstützt.'
        });
        this.importSelected = false;
        fileupload.clear();
      } else {
        this.importSelected = true;
      }
    }
  }

  initFile(file: File) {
    this.fileObject = file;
    this.fileContent = '';
    this.fileName = file.name;
    this.fileSize = file.size;
  }

  /** auf valides JSON File prüfen */
  isValidFile(): boolean {
    return (this.fileName.toLowerCase().endsWith('.json'));
  }

  /** Import File + Mapping */
  importJsonFile(event, fileupload: FileUpload) {
    let reader: FileReader = new FileReader();

    reader.readAsText(this.fileObject, 'UTF-8');

    reader.onloadend = (data) => {
      this.metaData = JSON.parse(reader.result.toString());
      // console.log(this.metaData);
      this.loading = true;

      this.dsvsId = this.metaData[0].formsid;
      this.title = this.metaData[0].titel;
      this.khId = this.metaData[0].pdfcontent;
      this.khVersion = this.metaData[0].pdfversion;
      this.formsAssistantVersion = this.metaData[0].version;
      if (this.metaData[0].standard === 'ja') {
        this.isStandard = true;
      } else {
        this.isStandard = false;
      }
      this.composerVersion = this.metaData[0].composerversion;
      this.releaseNotes = this.metaData[0].releasenotes;
      this.iVersion = this.metaData[0].iversion;
      this.loading = false;
    };

    reader.onerror = (e) => {
      console.log(e);
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Fehler in JSON-Importdatei!',
        detail: 'Beim Datenimport ist ein Fehler aufgetreten.<br>'
      });
      this.importSelected = false;
      fileupload.clear();
    };

  }

  /** Delete file */
  deleteFile() {
    // Löschen nicht zulässig -> Überschreiben mit neuer Datei
  }

  /**
   *  Allgemeine Funktion für Fehlerhinweise
   */
  private showErrorMessage(e: HttpErrorResponse, msg: string) {
    this.error.hasError = true;
    this.error.msgTitle = msg;
    this.error.msgBody = 'Status: ' + e.status + '<br>' + e.message;
    console.log(e);
    this.messageService.add({
      sticky: true,
      severity: 'error',
      summary: this.error.msgTitle,
      detail: this.error.msgBody
    });
  }
}
