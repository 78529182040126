import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableFixedState } from './table-fixed-state';

@Component({
  selector: 'ffb-fix-columns-title',
  templateUrl: './table-title-fixed-columns.component.html',
  styleUrls: ['./table-title-fixed-columns.component.css']
})
export class TableTitleFixedColumnsComponent implements OnInit {

  constructor() { }

  @Input() title: string;
  @Input() showfix: boolean;
  @Input() isFixed: boolean;
  @Input() colHeight: string;
  @Output() fixedChanged = new EventEmitter();
  @Output() colHeightChanged = new EventEmitter();

  private colHeightValue: number;

  ngOnInit() {
    if (this.colHeight === '' || this.colHeight === null) {
      this.colHeight = '20px';
    }
    this.colHeightValue = +this.colHeight.replace('px', '');
  }

  /* Event für Callback bei Änderung des Status */
  onFixedChanged() {
    let rowStyle: string;
    let fixState: TableFixedState = {
      fixed: this.isFixed,
      colHeight: this.colHeight,
    };
    this.fixedChanged.emit(fixState);
  }

  onColHeightChange(updown: string) {
    switch (updown) {
      case 'up':
          if (this.colHeightValue < 200) {
            this.colHeightValue += 10;
          }
        break;
      case 'down':
        if (this.colHeightValue > 20) {
          this.colHeightValue -= 10;
        }
        break;
    }
    this.colHeight = this.colHeightValue.toString() + 'px';
    this.colHeightChanged.emit(this.colHeight);
  }
}
