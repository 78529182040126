import { Injectable } from '@angular/core';
import { FfbErrorHandler } from '../classes/ffb-error-handler';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { interval } from 'rxjs';
import { NotificationData } from '../interfaces/notification-data';

@Injectable()
export class NotificationService {

  /** Services-URL für Backend */
  private endpointRoot: string = environment.backend;

  /** Error-Handler für Fehlermeldungen */
  errorHandler: FfbErrorHandler = new FfbErrorHandler;

  /** Flag ob der Notification-Service bereits initialisiert wurde
   * und die Gesamtliste der Benachrichtigung nach der Anmeldung ausgelesen wurde
   */
  isInitialized: boolean;

  /** Flag ob die Notification-View Komponente aktiv ist
   * falls ja muss in der Topbar kein Routing erfolgen
   * wenn ein Notification-MenuItem geclickt wird, es muss
   * dann aber der notificationItemClicked getriggert werden
   */
  isInNotificationView: boolean;

  /** Settings für den Notification-Service ggf. später über
   * die globalen App-Settings aus ZAP auslesen */
  private pollInterval: number;   // Polling alle 15 Sekunden

  /** private Subjects für die Notification Überwachung */
  private notificationIncomeTrigger = new Subject<Boolean>();       // Es sind zur Laufzeit neue Notifications eingegangen
  private notificationRefreshTrigger = new Subject<Boolean>();      // Das Notifications-Menü muss aktualisiert werden
  private notificationItemClickedTrigger = new Subject<number>();  // Im Notification-Menü wurde ein Item angeclickt

  /** private Subscriptions für die Notification Überwachung */
  private pollTimer: Observable<any>;
  private pollTimerSubscription: Subscription;
  private pollServiceSubscription: Subscription;

  /** Service Händler, werden von den Komponenten Abonniert */
  notificationAlert = this.notificationIncomeTrigger.asObservable();                  // Es sind zur Laufzeit neue Benachrichtigungen eingegangen
  notificationRefresh = this.notificationRefreshTrigger.asObservable();               // Das Notifications-Menü muss aktualisiert werden
  notificationItemClicked = this.notificationItemClickedTrigger.asObservable();       // Ein Item im Notifications-Menü wurde abgeclickt die aktuelle ID wird eingestellt (für die automatische Auswahl in der Notification View)

  /** Gesamt-Liste der Benachrichtigungen */
  notificationListTotal: NotificationData[] = [];
  /** Liste der ungelesenen Nachrichten */
  notificationListUnread: NotificationData[] = [];
  /** Anzahl der ungelesenen neuen in der Liste Nachrichten */
  newNotificationCount: number;

  constructor(private http: HttpClient) { }

  /** Alle Notifications für den angemeldeten User auslesen
   * @param {string} jwt
   */
  initializeUserNotifications(jwt: string) {
    this.newNotificationCount = 0;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get<NotificationData[]>( this.endpointRoot + 'notification/user/getall', { headers }).subscribe( data => {
      if (data.length > 0) {
        /** Alle Nachrichten als abgeholt markieren */
        this.markNotificationsAsFetched(jwt);
      }
      this.notificationListTotal = [];
      this.notificationListUnread = [];
      for (let notify of data) {
        if (notify.readType === 0) {
          /** alte ungelesene Nachrichten */
          this.notificationListUnread.push(notify);
        }
        if (notify.readType === -1) {
          /** neue nachrichten */
          this.newNotificationCount++;
          notify.readType = 0;
          this.notificationListUnread.push(notify);
        }
        this.notificationListTotal.push(notify);
      }
      if (!this.isInitialized) {
        this.isInitialized = true;
        this.notificationRefreshTrigger.next(true);
      }
      this.startPolling(jwt);
    }, (e: HttpErrorResponse) => {
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'warn';
      this.errorHandler.msgTitle = 'Benachrichtigungsservice';
      this.errorHandler.msgBody = 'Die Initialisierung des Services ist fehlgeschlagen. Sollte dies wiederholt auftreten, so wenden sie sich bitte an den Support.<br>Fehler:' + e.message;
      console.log(e);
      this.notificationRefreshTrigger.next(false);
      this.stopPolling();
    });
  }

  /** Polling des Benachrichtigungsservices starten
   * @param {string} jwt
   */
  startPolling(jwt: string) {
    this.pollInterval = 15000;
    this.pollTimer = interval(this.pollInterval);
    this.pollTimerSubscription = this.pollTimer.subscribe( () => {
      this.pollServiceSubscription = this.checkNewNotifications(jwt).subscribe( result => {
        if (result) {
          this.notificationIncomeTrigger.next(true);
        }
        this.pollServiceSubscription.unsubscribe();
      }, (e: HttpErrorResponse) => {
        console.log(e);
        this.pollServiceSubscription.unsubscribe();
      });
    });
  }

  /** Polling des Benachrichtigungsservices stoppen */
  stopPolling() {
    if (this.pollTimerSubscription) {
      this.pollTimerSubscription.unsubscribe();
    }
  }

  /** REST-Service Aufruf zur Prüfung ob neue Nachrichten vorhanden sind
   * @param {string} jwt
   * @returns {Observable<boolean>}
   */
  checkNewNotifications(jwt: string): Observable<boolean> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<boolean>( this.endpointRoot + 'notification/user/hasnew', { headers });
  }

  /** Die während der Session neu eingegangenen Notifications abrufen
   * @param {string} jwt
   * @returns {Observable<NotificationData[]>}
   */
  getNewNotifications(jwt: string): Observable<NotificationData[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<NotificationData[]>( this.endpointRoot + 'notification/user/getnew', { headers });
  }

  /** Die zuvor über den Abonennten (topbar.component.ts) abgefragten neuen Benachrichtigungen
   * müssen zur Anzeige in der Topbar über diese Funktion an den Anfang der NotificationListen
   * eingefügt werden
   * @param {NotificationData[]} newNotifications
   */
  addNewNotifications(newNotifications: NotificationData[]) {
    this.notificationListTotal = [...newNotifications, ...this.notificationListTotal];
    this.notificationListUnread = [...newNotifications, ...this.notificationListUnread];
  }

  /** Neue abgeholte Notifications als "abgeholt" amrkieren
   * @param {string} jwt
   * @returns {Observable<NotificationData[]>}
   */
  markNotificationsAsFetched(jwt: string) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get( this.endpointRoot + 'notification/user/markasfetched', { headers }).subscribe( result => {
      /** ggf. TODO */
      if (result) {
        /* Nachrichten wurden erfolgreich als abgeholt markiert */
      } else {
        /* Nachrichten konnten nicht erfolgreich als abgeholt markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als gelesen markieren (rekursiv bei mehreren Notifications)
   * @param {string} jwt
   * @param {number[]} nfID
   * @param {number} index
   */
  markNotificationAsRead(jwt: string, nfID: number[], index: number = 0) {
    let maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get( this.endpointRoot + 'notification/user/markasread/' + nfID[index], { headers }).subscribe( result => {
      if (result) {
        if (index < maxIndex) {
          index++;
          this.markNotificationAsRead(jwt, nfID, index);
        } else {
          this.stopPolling();
          this.initializeUserNotifications(jwt);
        }
      } else {
        /* TODO Nachrichten konnten nicht als gelesen markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als ungelesen markieren (rekursiv bei mehreren Notifications)
   * @param {string} jwt
   * @param {number[]} nfID
   * @param {number} index
   */
  markNotificationAsUnread(jwt: string, nfID: number[], index: number = 0) {
    let maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get( this.endpointRoot + 'notification/user/markasunread/' + nfID[index], { headers }).subscribe( result => {
      if (result) {
        if (index < maxIndex) {
          index++;
          this.markNotificationAsUnread(jwt, nfID, index);
        } else {
          this.stopPolling();
          this.initializeUserNotifications(jwt);
        }
      } else {
        /* TODO Nachrichten konnten nicht als ungelesen markiert werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Benachrichtigung als gelöscht markieren (rekursiv bei mehreren Notifications)
   * @param {string} jwt
   * @param {number[]} nfID
   * @param {number} index
   */
  markNotificationAsDeleted(jwt: string, nfID: number[], index: number = 0) {
    let maxIndex: number = nfID.length - 1;
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get( this.endpointRoot + 'notification/user/markasdeleted/' + nfID[index], { headers }).subscribe( result => {
      if (result) {
        if (index < maxIndex) {
          index++;
          this.markNotificationAsDeleted(jwt, nfID, index);
        } else {
          this.stopPolling();
          this.initializeUserNotifications(jwt);
        }
      } else {
        /* TODO Nachrichten konnten nicht gelöscht werden, ggf. das Polling stoppen und Hinweis bringen */
      }
    });
  }

  /** Den Event für den Click eif ein Notification-Menu-Item (Glocke) triggern
   * @param {number} nfID
   */
  triggerMenuItemClick(nfID: number) {
    this.notificationItemClickedTrigger.next(nfID);
  }
}
