/** Import der Core-Komponenten und Utils */
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { filter } from 'rxjs/operators';

/** Import der Models */
import { UserData } from '../../../interfaces/user-data';
import { Comp2compMessage } from '../../../classes/comp2comp-message';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';

/** Import der Services */
import { AuthorizationService } from '../../../services/authorization.service';
import { ComponentCommunicationService } from '../../../services/component-communication.service';
import { FfbSettings } from '../../../classes/ffb-settings';
import { AppSettings } from '../../../interfaces/app-settings';
import { SettingsService } from '../../../services/settings-service';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { environment } from '../../../../environments/environment';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { DeepLinkService } from '../../../services/deep-link.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LandingPageSubinfo } from '../../../classes/landing-page-subinfo';

// declare var jQuery: any;
// declare var $: any;

/** Metadaten für die Komponente */
@Component({
  selector: 'pfp-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})

export class StartComponent implements OnInit, OnDestroy {

  /** App-Version aus dem Environment */
  version: string;

  /** Error-Handler */
  errorHandler: FfbErrorHandler = new FfbErrorHandler();

  /** Menü-Steuerung für Primeng-UI-Template */
  menuClick: boolean;
  menuButtonClick: boolean;
  topbarMenuButtonClick: boolean;
  topbarMenuClick: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: Element;
  layoutStatic: boolean;
  sidebarActive: boolean;
  mobileMenuActive: boolean;

  /** Flags für Authentifizierung */
  performLogin: boolean = false;
  isLoggedIn: boolean;

  /** Anwenderdaten */
  userData: UserData;
  salutation: string;

  /** JSON-Webtoken für Service-Authentifizierung */
  jwt: string;

  /** Subscriptions für die SSO-Authentifizierung */
  qpSub1: Subscription;  // Query-Parameter-Subscription 1;
  qpSub2: Subscription;  // Query-Parameter-Subscription 2;

  /** Subscription für die C2C-Communication */
  msgSub: Subscription;  // Message-Service Subscription
  /** Deep-Link */
  executeDeepLink: boolean;
  /** Subscription für die DeepLink-Verification */
  verifyDL: Subscription;
  /** Flag zur Anzeige der Passwortabfrage beim öffnen eines DeepLinks */
  showDeepLinkPassword: boolean = false;
  password: string; // Model für Passwortabfrage
  passwordTries: number; // Anzahl der Versuche zur Passworteingabe

  constructor(private route: ActivatedRoute,
              private router: Router,
              public breadcrumbService: BreadcrumbService,
              private messageService: MessageService,
              private authService: AuthorizationService,
              private deeplinkService: DeepLinkService,
              private msgService: ComponentCommunicationService,
              private optService: SettingsService,
              public ffbSettings: FfbSettings,
              public tableStateProvider: TableStateProvider,
              private subInfo: LandingPageSubinfo,
              private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'body-image');
  }

  ngOnInit() {
    /** App-Version*/
    this.version = environment.version;

    /** Application-Settings aus dem Resolver übergeben */
    if (this.route.snapshot.data['options']) {
      let settings: AppSettings = this.route.snapshot.data['options'];
      if (settings.success === true) {
        this.ffbSettings.initApp(settings);
      } else {
        this.messageService.add({life: 3500, severity: 'error', summary: 'ACHTUNG!', detail: 'Die Programmeinstellungen (Settings) konnten nicht ausgelesen werden.<br>Bitte beenden Sie PFP und wenden Sie sich an den Support.'});
     }
    }
    this.msgSub = this.msgService.getMessage().subscribe( msg => {
      this.messageResolver(msg);
    });
    /** Prüfung auf angemeldeten User bei Initialisierung der Komponente */
    this.isLoggedIn = this.authService.checkLogin();
    /* Zuerst die User-Settings laden */
    if (this.isLoggedIn) {
      if (this.ffbSettings.success) {
        this.optService.getUserSettings().subscribe( udata => {
          this.ffbSettings.initUser(udata);
          /* Table State Provider ggf. aktivieren/deaktivieren */
          this.tableStateProvider.activated = (this.ffbSettings.getOptionValue('SAVE_TABLE_STATE', true) === 'true');
          this.router.navigate(['/home'], {replaceUrl: true});
        });
      }
    }
    if (!this.isLoggedIn) {
      /** Prüfung auf SSOID oder DLID als Query-Parameter in der URL
       * wenn der User nicht angemeldet ist
       * SSOID = SingleSignOn-ID
       * DLID = DeepLink-ID */
      this.qpSub1 = this.route.queryParams.subscribe(data => {
        /** Flag für DeepLink zurücksetzen */
        this.executeDeepLink = false;
        /** Auf sso-ID oder DeepLink-ID prüfen; eine ID muss gesetzt sein */
        if (isNullOrUndefined(data.ssoid) && isNullOrUndefined(data.dlid)) {
          this.errorHandler.hasError = true;
          this.errorHandler.errType = 'info';
          this.errorHandler.msgTitle = 'Information zum Single-Sign-On Verfahren.';
          this.errorHandler.msgBody = 'Bitte melden Sie sich in der FFB an und starten Sie PFP über das Anwendungsmenü...' +
              '<br>Bei Rückfragen stehen wir Ihnen unter...';
          this.router.navigate(['/landing/nosso'], {replaceUrl: true});
        } else {
          this.errorHandler = new FfbErrorHandler();
        }
      });
      /** Falls beim Aufruf des Auth-Services kein Fehler aufgetreten ist, dann
       * kann der Login-Prozess angestoßen werden  */
      if (this.authService.errorHandler.hasError) {
        this.errorHandler = this.authService.errorHandler;
      } else {
        this.qpSub2 = this.route.queryParams.pipe(filter(params => params.ssoid || params.dlid))
            .subscribe(params => {
              /* Standard-Aufruf mit SSO-ID */
              if (params.ssoid !== '' && params.ssoid !== null && params.ssoid !== undefined) {
                this.performLogin = true;
                this.authService.login(params.ssoid);
              }
              /* Aufruf mit DeepLink-ID */
              if (params.dlid !== '' && params.dlid !== null && params.dlid !== undefined) {
                this.performLogin = true;
                this.deeplinkService.reset();
                this.deeplinkService.deeplinkID = params.dlid;
                this.executeDeepLink = true;
              }
            });
      }
      /** getLoginDone ist ein Observable des Auth-Services
       * nach Abschluss des Login-Prozesses wird der Login-Status
       * zurückgeliefert - wird auch bei DeepLinks verwendet (siehe unten) */
      this.authService.getLoginDone().subscribe( ls => {
        if (ls === true) {
          if (!this.authService.errorHandler.hasError) {
            this.isLoggedIn = this.authService.checkLogin();
          }
          if (this.authService.errorHandler.hasError) {
            this.performLogin = false;
            /** Beim Login-Prozess ist ein Fehler aufgetreten */
            this.errorHandler = this.authService.errorHandler;
            this.messageService.add({sticky: true, severity: 'error', summary: 'Fehler beim Single-Sign-On', detail: this.authService.errorHandler.msgTitle + '<br>' + this.authService.errorHandler.msgBody});
            setTimeout( () => {
              this.router.navigate(['/landing/ssoerr'], {replaceUrl: true});
            }, 2000);
          } else {
            /** Der Login-Prozess war erfolgreich */
            this.userData = this.authService.userData;
            this.buildUserData();
            this.qpSub1.unsubscribe();
            this.qpSub2.unsubscribe();
            /** User-Settings und Gruppen-Settings abrufen, an App-Settings übergeben und zu Home verzweigen
             * die DeepLink Verifizierungs-Daten im Local-Storage aktualisieren und zu Home verzweigen
             */
            this.optService.getUserSettings().subscribe( udata => {
              this.ffbSettings.initUser(udata);
              /* Table State Provider ggf. aktivieren/deaktivieren */
              this.tableStateProvider.activated = (this.ffbSettings.getOptionValue('SAVE_TABLE_STATE', true) === 'true');
              if (+this.userData.result.institute_id > 0) {
                this.optService.getGroupSettings().subscribe( gdata => {
                  this.ffbSettings.initGroupSettings(gdata);
                  this.performRouting();
                });
              } else {
                this.ffbSettings.initGroupSettings([] );
                this.performRouting();
              }
            });
          }
        }
      });

      if (this.executeDeepLink) {
        /** Aufruf des DeepLink-Services und Validierung der DeepLink-Parameter und Berechtigungen */
        this.deeplinkService.getDeepLinkData().subscribe( data => {
          this.deeplinkService.deeplink = data;
          if (this.deeplinkService.hasLoginStorageData) {
            this.verifyDL = this.deeplinkService.verifyDeepLinkAccess().subscribe( result => {
              this.verifyDL.unsubscribe();
              let checkDL  = result;
              if (!checkDL.success) {
                /* DeepLink-Verifizierung war nicht erfolgreich - Routing zur Landing-Page */
                this.subInfo.type = 901;
                this.subInfo.desc = 'Der DirektLink konnte nicht aufgerufen werden:<br><span style="color:red">' + checkDL.msg + '</span>';
                this.messageService.add({
                  life: 4900,
                  severity: 'warn',
                  summary: 'DirektLink kann nicht aufgerufen werden!',
                  detail: checkDL.msg + '<br>Sie werden zur Startseite weitergeleitet.'}
                );
                setTimeout( () => {
                  this.router.navigate(['/landing/dlerr'], {replaceUrl: true});
                }, 5000);
              }
            });
          } else {
            this.subInfo.type = 901;
            this.subInfo.desc = 'Auf diesem Rechner wurden keine Daten zur automatischen Kontoverifizierung gefunden.<br>Bitte melden Sie sich in [App] über die FFB an und öffnen Sie den DirektLink dann erneut.';
            this.router.navigate(['/landing/dlerr'], {replaceUrl: true});
          }
        }, (e: HttpErrorResponse) => {
          console.log(e);
          this.subInfo.type = e.status;
          this.subInfo.desc = e.error;
          this.router.navigate(['/landing/dlerr'], {replaceUrl: true});
        });
      }
    } else {
      this.userData = this.authService.userData;
      this.buildUserData();
      this.router.navigate(['/home'], {replaceUrl: true});
    }
  }

  /** Destroy-Methode der Komponente,
   * alle Subscriptions werden storniert
   */
  ngOnDestroy() {
    if (this.qpSub1) {
      this.qpSub1.unsubscribe();
    }
    if (this.qpSub2) {
      this.qpSub1.unsubscribe();
    }
    if (this.msgSub) {
      this.msgSub.unsubscribe();
    }
    if (this.verifyDL) {
      this.verifyDL.unsubscribe();
    }
    this.renderer.removeClass(document.body, 'body-image');
  }

  /** Bei benötigter Passwort-Verifizierung zum Öffnen eines DeepLinks wird
   * hier vor dem Routing das eingegebene Passwort überptüft
   */
  checkDeepLinkPassword() {
    this.deeplinkService.checkUserPassword(this.password).subscribe( result => {
      if (result.success) {
        this.showDeepLinkPassword = false;
        this.performLogin = false;
        this.router.navigate([this.deeplinkService.routeBuilder()], {replaceUrl: true});
      } else {
        this.passwordTries -= 1;
        if (this.passwordTries === 0) {
          this.cancelDeepLink();
        } else {
          this.messageService.add({
            life: 3500,
            severity: 'warn',
            summary: 'DeepLink-Passwortprüfung',
            detail: result.msg + '<br>(Verbleibende Versuche: ' + this.passwordTries.toString() + ')'}
          );
        }
      }
    });
  }

  /** DeepLink-Aufruf wird im Formular für die Passwortabfrage abgebrochen */
  cancelDeepLink() {
    this.showDeepLinkPassword = false;
    this.performLogin = false;
    this.deeplinkService.reset();
    this.router.navigate(['/logout'], {replaceUrl: true});
  }

  /** Routing durchführen */
  private performRouting() {
    /* DeepLink-ID im LocalStorage aktualisieren */
    this.deeplinkService.updateDeepLinkUserID(this.userData.result.deeplink_id);
    if (this.executeDeepLink && this.deeplinkService.verified) {
      if (this.ffbSettings.getGroupSetting('DLNK_FORCE_PW', '0') === '1') {
        this.passwordTries = 3;
        this.showDeepLinkPassword = true;
      } else {
        this.performLogin = false;
        this.router.navigate([this.deeplinkService.routeBuilder()], {replaceUrl: true});
      }
    } else {
      this.performLogin = false;
      this.router.navigate(['/home'], {replaceUrl: true});
    }
  }

  /** Anrede aus den Userdaten zusammensetzen und
   * JSON-Webtoken bereitstellen
   */
  private buildUserData(): void {
    this.salutation = this.userData.result.firstname + ' ' + this.userData.result.lastname;
    this.jwt = this.userData.result.jwt;
  }

  /** Nachrichten des Messageservices auflösen und ggf. bearbeiten
   * (wenn Receiver = 'start' oder 'all' ist)
   */
  messageResolver(msg: Comp2compMessage): void {
    // TODO
  }

  /** Menüsteuerung für PrimeNG-Komponenten */
  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.topbarMenuActive = false;
      this.activeTopbarItem = null;
    }

    this.menuClick = false;
    this.menuButtonClick = false;
    this.topbarMenuClick = false;
    this.topbarMenuButtonClick = false;

    /*Toolbar-Submenü-Steuerung*/
    this.breadcrumbService.resetSubmenu(true);
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;

    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    }

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }

  onTopbarRootItemClick(event: Event, item: Element) {
    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null; } else {
      this.activeTopbarItem = item; }

    event.preventDefault();
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.layoutStatic = !this.layoutStatic;
  }


  isMobile() {
    return window.innerWidth < 1240;
  }

}
