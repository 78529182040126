import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LandingPageSubinfo } from '../../../classes/landing-page-subinfo';

@Component({
    selector: 'ffb-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})

export class LandingPageComponent implements OnInit {

    @Input() subMessage: string;

    /* Flags für die individuelle Templates */
    showLoginLink: boolean;
    apoURL: string = environment.apourl;

    /* Spezielle Ressourcen für die jeweilige App */
    appBranding: string;
    appLogo: string;
    infoTitle: string;
    infoMessage: string;
    appSupportMail: string;

    constructor(private route: ActivatedRoute,
                private subInfo: LandingPageSubinfo) {
    }

    ngOnInit() {

        this.appBranding = '../../../../assets/images/logo_ffb_pfp.svg';
        this.appLogo = '../../../../assets/images/logo_pfp.svg';
        this.appSupportMail = 'support@die-formularfabrik.de';
        this.infoTitle = 'Sie haben sich erfolgreich abgemeldet.';
        this.infoMessage = 'Alle für Sie freigeschalteten Apps erreichen Sie wie gewohnt über das Anwendungsmenü unserer Formularfabrik.';

        this.route.params.subscribe(params => {
            switch (params.mode) {
                case 'nosso':
                    this.infoTitle = 'Anmeldung ist nur mit SSO-Schlüssel möglich';
                    this.infoMessage = 'Sie müssen PFP über das Anwendungsmenü der Formularfabrik starten.';
                    break;
                case 'ssoerr':
                    this.infoTitle = 'Die Anmeldung an PFP ist fehlgeschlagen.';
                    this.infoMessage = 'Der Start von PFP über das Anwendungsmenü der Formularfabrik ist fehlgeschlagen. Bitte wenden Sie sich an den Support.';
                    break;
                case 'dlerr':
                    this.infoTitle = 'Aufruf mit DirektLink ist fehlgeschlagen';
                    switch (this.subInfo.type) {
                        case 422:
                            this.infoMessage = this.subInfo.desc + ' (' + this.subInfo.type.toString() + ')';
                            break;
                        case 901:
                            this.infoMessage = this.subInfo.desc;
                            this.showLoginLink = true;
                            break;
                        default:
                            this.infoMessage = 'Die DirektLink-Daten konnten nicht abgerufen werden!<br>(' + this.subInfo.type.toString() + ')';
                    }
            }
        });
    }
}
