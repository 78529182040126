import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem, Table } from 'primeng';
import { Observable, Subject, Subscription } from 'rxjs';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';
import { HelperClass } from '../../../classes/helper-class';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { XlsTableExport } from '../../../classes/xls-table-export';
import { FormData } from '../../../interfaces/form-data';
import { FormsCompanyAssignResign } from '../../../interfaces/forms-company-assign-resign';
import { LastSelectedRow } from '../../../interfaces/last-selected-row';
import { PublicInstituteData } from '../../../interfaces/public-institute-data';
import { TableColumn } from '../../../interfaces/table-column';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { AuthorizationService } from '../../../services/authorization.service';
import { FormsService } from '../../../services/forms.service';
import { WindowResizeService } from '../../../services/window-resize.service';
import { ZapService } from '../../../services/zap.service';
import { TableFixedState } from '../../custom/table-title-fixed-columns/table-fixed-state';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

export enum ViewMode {
  VIEW = 'VIEW',
  ASSIGN = 'ASSIGN',
  BATCHASSIGN = 'BATCHASSIGN'
}

@Component({
  selector: 'pfp-config-view-clients',
  templateUrl: './config-view-clients.component.html',
  styleUrls: ['./config-view-clients.component.css']
})

export class ConfigViewClientsComponent implements OnInit, AfterViewInit, OnDestroy {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Höhe des Wrappers z.B. zur Anzeige des Loading-Spinners */
  wrapperHeightPx: string;

  /** Flags */
  initializing: boolean;                        // Initialisierung wird durchgeführt
  loading: boolean;                             // Loading-Flag der Komponente für die HTML-Templates
  blockPage: boolean;                           // Block-UI Flag
  restoringTableState: boolean;                 // beim Wiederherstellen des TableState müssen die Change-Subscriptions der tabelle unterdrückt werden, sonst treten Event-Konflikte ein
  viewMode: ViewMode = ViewMode.VIEW;           // Ansichts-Modus (siehe enum ViewMode)
  lastViewMode: ViewMode = ViewMode.VIEW;       // Zuletzt aktiver Ansichts-Modus
  viewModeType = ViewMode;                      // ViewMode Objekt für das HTML-Template

  /** Parameter für die Turbo-Table */
  frozen: boolean;                      // Tabelle hat fixierte Spalten
  colHeight: string;                    // Style für Zeilenhöhe bei Tabelle mit fixierten Spalten
  dtrows: number;                       // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];              // Mögliche Auswahl für Anzahl der Zeilen pro Seite aus den Settings
  dtScrollVertical: boolean;            // Turbo-Table scollt Vertikal - Header bleiben statisch

  /** Daten für die TurboTable */
  @ViewChildren('ttable') turboTable: QueryList<Table>;
  ttID: string = 'ttconfiginstitutesview';                    // Eindeutige ID der Tabelle für den Table-State-Provider
  ttStateStorage: string = 'session';                         // Storage für den TableState
  ttTitle: string = 'Mandantenübersicht';                     // Tabellenüberschrift, kann sich je nach ViewMode ändern
  tableChangedSubscription: Subscription;                     // Änderungs-Subscription auf die DataTable - wegen Lazy-Loading und fixierter Spalten
  scrollHeight: string;                                       // Bei Tabelle mit fixer Höhe (Table-Header scrollt nicht aus dem Bildschirm) muss die Höhe des Sceollbereichs festgelegt werden
  columns: TableColumn[];                                     // Array mit den Tabellenspalten
  frozenColumns: TableColumn[] = [];                          // Array mit den fixierten Tabellenspalten
  frozenWidth: string;                                        // Breite der fixierten Spalten (Summe) wird von Primeng-Table benötigt
  frozenHeaderHeight: string;                                 // Höhe der Header bei Fixierung (Hähe muss statisch sein, sonst gibt es einen Versatz bei mehrzeiligen Zellen)
  totalRecords: number = 0;                                   // Anzahl der Records in der Table insgesamt
  filteredRecords: number = 0;                                // Anzahl der Records bei aktiven Filtern
  first: number = 0;                                          // Nummer des ersten aktuell angezeigten Records in der Table (zur Berechnng der Seite benötigt - für paging)
  institutesData: PublicInstituteData[] = [];                 // Array mit den Tabellendaten
  filteredData: PublicInstituteData[] = [];                   // Array mit den gefilterten Tabellendaten
  selectedInstitute: PublicInstituteData = null;              // Daten der angewählten Tabellenzeile
  lastSelectedRow: LastSelectedRow;                           // Zuletzt angewählte Zeile (Für den TableState-Restore)
  ynOptions: SelectItem[] = [];                               // ja/nein Filter für Tabelle

  /* Subject für das Observable beim Abrufen der Foormularanzahl je Institut */
  private faCountFetched = new Subject<boolean>();

  /** Daten für das Formular-Batch_assignment */
  assignFormId: string;                   // Formular-ID für das batch-Assignment
  assignFormData: FormData;
  assignedCompanyIDs: number[] = [];

  /** Settings für CSV Export*/
  csvDelimiter: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public authService: AuthorizationService,
              public breadcrumbService: BreadcrumbService,
              public tableStateProvider: TableStateProvider,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public ffbSettings: FfbSettings,
              private resizeService: WindowResizeService,
              private formsService: FormsService,
              private zapService: ZapService) {
  }

  ngOnInit() {
    /** View-Mode über Parameter Subscriben und die Initialisierung durchführen */
    this.route.params.subscribe(params => {
      if (params.mode) {
        switch (params.mode) {
          case 'view':
            this.viewMode = ViewMode.VIEW;
            this.ttTitle = 'Mandantenübersicht';
            this.initComponent();
            break;
          case 'assign':
            this.viewMode = ViewMode.ASSIGN;
            this.ttTitle = 'Auswahl Mandant für Zuweisung';
            this.initComponent();
            break;
          case 'batchassign':
            this.viewMode = ViewMode.BATCHASSIGN;
            this.ttTitle = 'Auswahl Mandanten für Zuweisung';
            if (params.formid) {
              this.assignFormId = params.formid;
              const subscription: Subscription = this.formsService.getForm(this.authService.getJWT(), this.assignFormId).subscribe(data => {
                subscription.unsubscribe();
                this.assignFormData = data;
                this.ttTitle += ' (Forms-ID: ' + this.assignFormData.dsvsId + ')';
              });
            } else {
              this.messageService.add({
                life: 3500,
                severity: 'warn',
                summary: 'Formularzuweisung',
                detail: 'Ungültige Formular-ID - Formularzuweisung nicht möglich.'
              });
              this.viewMode = ViewMode.VIEW;
              this.ttTitle = 'Mandantenübersicht';
            }
            this.initComponent();
            break;
          default:
            this.router.navigate(['/home'], {replaceUrl: true});
        }
      } else {
        this.router.navigate(['/home'], {replaceUrl: true});
      }
    });
  }

  /** Initialisierung der Komponente auf Basis des View-Modes */
  private initComponent() {
    /** Während die Komponente initialisiert wird - keine HTML-Template-Anzeige */
    this.loading = true;
    /** Höhen für den Wrapper (Loading-Spinner) kalkulieren */
    this.wrapperHeightPx = this.helper.getWrapperHeight(window.innerHeight, -300);
    /** Prüfen ob sich der ViewMode geändert hat, falls ja, dann muss der TableState ggf. zurückgesetzt werden */
    if (this.lastViewMode !== this.viewMode || this.viewMode === ViewMode.ASSIGN || !this.tableStateProvider.activated) {
      this.tableStateProvider.ttID = this.ttID;
      this.tableStateProvider.clearTableState(true);
      this.tableStateProvider.firstSessionCall(this.ttID, true, false);
    } else {
      this.tableStateProvider.firstSessionCall(this.ttID, false, false);
    }
    /** Tabellenparameter */
    this.dtrows = +this.ffbSettings.getOptionValue('DTROWS_PFP', 10);
    this.dtScrollVertical = (this.ffbSettings.getOptionValue('DTSCROLL_PFP', false) === 'true');
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.ffbSettings.getOptionParameters('DTROWS_PFP', [5, 10, 25, 100]).map(value => +value);
    /* Falls der User die Tabellenhöhe auf die Contenthöhe beschränken will (Tabelle scrollt vertikal)... */
    if (this.dtScrollVertical) {
      this.scrollHeight = this.helper.getTableScrollHeight(window.innerHeight);
    } else {
      this.scrollHeight = 'auto';
    }
    /** Filter für Boolean Spalten */
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: true},
      {label: 'nein', value: false}
    ];
    /** Definition der Tabellenspalten abrufen
     *  Unterscheidung je nach Modus fixiert/nicht fixiert
     */
    let fixedState: TableFixedState = this.tableStateProvider.getTableFrozenState(this.ttID);
    this.frozen = fixedState.fixed;
    this.colHeight = fixedState.colHeight;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    } else {
      this.frozenColumns = [];
    }
    /** Breadcrumb - Initialisierung / Einblenden erst nach erfolgreicher Datenabfrage */
    /**Toolbar initialisierung*/
    switch (this.viewMode) {
      case ViewMode.VIEW:
        this.breadcrumbService.setMenuItems([
          {label: 'Administration'},
          {label: 'Mandantenübersicht'}
        ]);
        break;
      case ViewMode.ASSIGN:
        this.breadcrumbService.setMenuItems([
          {label: 'Administration'},
          {label: 'Formularpool', routerLink: '/configformspool'},
          {label: 'Mandantenauswahl'}
        ]);
        break;
      case ViewMode.BATCHASSIGN:
        this.breadcrumbService.setMenuItems([
          {label: 'Administration'},
          {label: 'Formularpool', routerLink: '/configformspool'},
          {label: 'Formular erstellen'},
          {label: 'Mandantenzuweisung'}
        ]);
        break;
    }
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(false);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /** Die vom Resize-Service ausgelösten Resize-Events abonnieren, falls der user in den Settings die Tabellenhöhe beschränkt hat */
    if (this.dtScrollVertical) {
      this.resizeSubscription = this.resizeService.onResize$.subscribe(win => {
        this.wrapperHeightPx = this.helper.getWrapperHeight(window.innerHeight, -300);
        this.scrollHeight = this.helper.getTableScrollHeight(win.innerHeight);
      });
    }

    /** Hier kein lazy-Loading also Daten immer abrufen */
    this.getInstituteData();
  }

  /** Mittels Timeout dieses Event in einem eigenen Thread ausführen, damit gewährleistet ist
   *  dass zuvor alle HTML-Elemente vollständig aufgebaut werden konnten.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        if (!this.restoringTableState) {
          this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
        }
      });
    });
  }

  ngOnDestroy(): void {
    /** Save Table State */
    this.tableStateProvider.saveTableState(this.frozen, this.colHeight, this.lastSelectedRow);
    /** WICHTIG!!! - alle Subscriptions zurücksetzen */
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    if (this.tableChangedSubscription) {
      this.tableChangedSubscription.unsubscribe();
    }
  }

  /** Institutsdaten aus ZAP abrufen */
  private getInstituteData() {
    const subscription: Subscription = this.zapService.getInstitutesList(this.authService.getJWT()).subscribe(data => {
      subscription.unsubscribe();
      this.institutesData = data;
      /* Wenn die Daten im Batchassign initialisiert werden (loading = true) dann muss der isAssigned Flag einmalig mit false initialisiert werden */
      if (this.viewMode === ViewMode.BATCHASSIGN && this.loading) {
        this.institutesData.map(item => {
          item.isAssigned = false;
        });
      }
      this.totalRecords = this.institutesData.length;
      this.filteredRecords = this.totalRecords;
      this.breadcrumbService.setToolbarVisible(true);
      /** Anzahl der zugewiesenen FA je Institut abrufen (rekursive Funktion */
      let getFaSubscription: Subscription = this.getAssignedFormsCount2().subscribe(() => {
        getFaSubscription.unsubscribe();
        this.loading = false;
      });
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        this.error.hasError = true;
        this.error.msgBody = 'Die Mandantenliste konnte nicht abgerufen werden!';
        this.breadcrumbService.setToolbarVisible(true);
        this.loading = false;
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Fehler beim Abrufen der Mandantenliste',
          detail: 'Beim Abrufen der Mandantendaten ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** Rekursive Funktion um für jedes Institut die Anzahl der zugewiesenen Formulare abzurufen
   *  Variante 1 - 1 Request je Institut (Variante 2 ist momentan aktiv)
   * @param index
   */
  getAssignedFormsCount(index: number): Observable<boolean> {
    if (this.institutesData.length === 0) {
      setTimeout(() => {
        this.faCountFetched.next(true);
      }, 50);
    } else {
      let formsCountSubscription: Subscription = this.formsService.getClientFormsCount(this.authService.getJWT(), this.institutesData[index].id).subscribe(data => {
        formsCountSubscription.unsubscribe();
        this.institutesData[index].formsCount = data.size;
        index++;
        if (index === this.institutesData.length) {
          this.faCountFetched.next(true);
        } else {
          this.getAssignedFormsCount(index);
        }
      }, (e: HttpErrorResponse) => {
        formsCountSubscription.unsubscribe();
        this.blockPage = false;
        if (e.status === 401) {
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Abfrage Formularzuweisungen',
            detail: 'Beim Ermitteln der Anzahl der zugewiesenen Formulare ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
          this.faCountFetched.next(false);
        }
      });
    }
    return this.faCountFetched.asObservable();
  }

  /** Rekursive Funktion um für jedes Institut die Anzahl der zugewiesenen Formulare abzurufen
   *  Variante 2 - nur ein HTTP-Request (diese Variante ist momentan aktiv)
   */
  getAssignedFormsCount2(): Observable<boolean> {
    if (this.institutesData.length === 0) {
      setTimeout(() => {
        this.faCountFetched.next(true);
      }, 50);
    } else {
      let idList: number[] = [];
      this.institutesData.map(client => {
        idList.push(+client.id);
      });
      let formsCountSubscription: Subscription = this.formsService.getClientFormsCount2(this.authService.getJWT(), idList).subscribe(data => {
        formsCountSubscription.unsubscribe();
        this.institutesData.map(client => {
          client.formsCount = data[client.id];
        });
        this.faCountFetched.next(true);
      }, (e: HttpErrorResponse) => {
        formsCountSubscription.unsubscribe();
        this.blockPage = false;
        if (e.status === 401) {
          this.messageService.add({
            life: 3500,
            severity: 'error',
            summary: 'Session ist ungültig oder abgelaufen',
            detail: 'Sie werden automatisch abgemeldet.'
          });
          setTimeout(() => {
            this.router.navigate(['logout']);
          }, 3500);
        } else {
          console.log(e);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Abfrage Formularzuweisungen',
            detail: 'Beim Ermitteln der Anzahl der zugewiesenen Formulare ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
          });
          this.faCountFetched.next(false);
        }
      });
    }
    return this.faCountFetched.asObservable();
  }

  /** Bestätigung der Formularzuweisung im Batchassign-Mode
   * @private
   */
  private confirmBatchAssign() {
    this.confirmationService.confirm({
      message: 'Die Zuweisung der Formularanwendung wird gespeichert.<br>Sind Sie sicher?',
      header: 'Bestätigung',
      icon: '',
      accept: () => {
        this.batchAssignForms();
      }
    });
  }

  /** Speichern der Formularzuweisung
   * @private
   */
  private batchAssignForms() {
    this.blockPage = true;
    let formCompanies: FormsCompanyAssignResign = {
      entityId: [{
        id: this.assignFormId,
        assign: true
      }],
      companyId: this.getSelectedCompanyIds()
    };
    const subscription: Subscription = this.formsService.assignResignFormsToCompany(this.authService.getJWT(), formCompanies).subscribe(result => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (result.successful) {
        this.assignedCompanyIDs.push(...formCompanies.companyId);
        this.breadcrumbService.setToolbarItemDisabled('SAVE_BATCHASSIGN', true);
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Formularanwendungen zuweisen',
          detail: 'Die Zuweisung der Formularanwendungen wurde erfolgreich gespeichert.'
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formularanwendungen zuweisen',
          detail: 'Beim Speichern der Zuweisung ist ein Fehler aufgetreten:<br>' + result.comment + '<br>Sollte dies wiederholt auftreten, so wenden Sie sich bitte an den Support'
        });
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      this.blockPage = false;
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'
        });
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formularanwendungen zuweisen',
          detail: 'Beim Speichern der Zuweisung ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /** im Batchmode wurde ein Mandant für die Formularzuweisung an-/abgewählt -
   *  prüfen ob noch ein Mandant angewählt ist und Speichern Tool sperren/freigeben
   */
  batchAssignmentChanged() {
    if (this.institutesData.filter(item => item.isAssigned === true).length > this.assignedCompanyIDs.length) {
      this.breadcrumbService.setToolbarItemDisabled('SAVE_BATCHASSIGN', false);
    } else {
      this.breadcrumbService.setToolbarItemDisabled('SAVE_BATCHASSIGN', true);
    }
  }

  /** Prüfen ob im Batchassign-Modus der Company das Formular bereits zugewiesen wurde
   *
   */
  companyIsAssigned(companyid: string) {
    return !!this.assignedCompanyIDs.find(id => id === +companyid);
  }

  /** Liste der ID's der angewählten Companies für das Batchassign zurückliefern
   * @private
   */
  private getSelectedCompanyIds(): number[] {
    let companies: number[] = [];
    this.institutesData.map(item => {
      if (item.isAssigned && !this.assignedCompanyIDs.find(id => id === +item.id)) {
        companies.push(+item.id);
      }
    });
    return companies;
  }

  /** Die Mandantenübersicht
   * als CSV-Datei exportieren */
  exportClients2Csv(filtered: boolean) {
    let filename: string = 'PFP-Mandantenübersicht-' + this.helper.DateToString(new Date());
    let exportData: PublicInstituteData[];
    filtered ? exportData = this.filteredData : exportData = this.institutesData;
    if (exportData.length > 0) {
      /** Klasse für Excel-Export initialisieren, die Spalten-Definitionsliste übergeben */
      let frozenFlag: boolean = this.frozen;
      this.frozen = false;
      let csvExp: XlsTableExport = new XlsTableExport(this.defineTableColumns(false));
      for (let row of exportData) {
        csvExp.addRow(row);
      }
      csvExp.createExportFile(filename, this.csvDelimiter);
      this.frozen = frozenFlag;
    } else {
      this.messageService.add({
        life: 3500,
        severity: 'warn',
        summary: 'CSV-Datenexport',
        detail: 'Es sind keine Daten für den Export verfügbar!'
      });
    }
  }

  /** Die Zellenhöhe in der fixierten Tabellenansicht wurde geändert
   * @param colHeight
   */
  onColHeightChanged(colHeight) {
    this.colHeight = colHeight;
  }

  /** Funktionen für die Werkzeugleiste
   * @param item
   */
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.getInstituteData();
        break;
      case 'UNFILTER':
        this.tableStateProvider.clearTableState();
        this.changeToolbarItemStatus(true);
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
        break;
      case 'ASSIGN':
        this.router.navigate(['/configformspool/' + this.selectedInstitute.id]);
        break;
      case 'SAVE_BATCHASSIGN':
        this.confirmBatchAssign();
        break;
      case 'ADD_FORM':
        /* im Batchassign-Modus weitere Formularanwendung hinzufügen */
        this.router.navigate(['/formdetails/create']);
        break;
      case 'EXPORT_FIlTERED':
        this.exportClients2Csv(true);
        break;
      case 'EXPORT_ALL':
        this.exportClients2Csv(false);
        break;
    }
  }

  /** Restore-Event wird von der Primeng Table getriggered */
  onTableStateRestore() {
    this.restoringTableState = true;
    if (this.tableStateProvider.activated) {
      setTimeout(() => {
        this.tableStateProvider.restoreTableState();
        if (this.selectedInstitute !== null && this.selectedInstitute !== undefined) {
          this.changeToolbarItemStatus(false);
        }
        this.restoringTableState = false;
      }, 100);
    } else {
      /* TableState zurücksetzen */
      this.restoringTableState = false;
      setTimeout(() => {
        this.tableStateProvider.clearTableState();
        this.filteredRecords = this.totalRecords;
      }, 100);
    }
  }

  /** Event - Tabellenzeile wurde angewählt - im Mandanten-Auswahlmodus (ASSIGN) kann dann
   *  sofort zur Zuweisung im Formularpool navigiert werden
   * @param event
   */
  onRowSelect(event) {
    if (this.viewMode === ViewMode.ASSIGN) {
      this.router.navigate(['/configformspool/' + this.selectedInstitute.id]);
    } else {
      this.changeToolbarItemStatus(false);
    }
  }

  /** Event - Tabellenzeile wurde abgewählt */
  onRowUnselect(event) {
    this.changeToolbarItemStatus(true);
  }

  /** Filter-Event wurde ausgelöst
   * Object selectedCr muss zurückgesetzt werden */
  onFilter(event) {
    if (!this.restoringTableState) {
      this.selectedInstitute = null;
    }
    this.changeToolbarItemStatus(true);
    this.filteredData = event.filteredValue;
    this.filteredRecords = event.filteredValue.length;
    if (this.filteredRecords !== this.totalRecords) {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
      this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', false, true);
    } else {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
      this.breadcrumbService.setToolbarItemDisabled('EXPORT_FIlTERED', true, true);
    }
  }

  /** Sort-Event wurde ausgelöst
   */
  onSort() {
    this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
  }

  /** Frozen-Columns (fixierte Spalten) wurde aktiviert/deaktiviert
   * @param state
   */
  onFixedChanged(state) {
    this.tableChangedSubscription.unsubscribe();
    /** dtrows zwischenspeichern und nach dem TableStateClear wiederherstellen */
    let dtrowsSave: number = this.tableStateProvider.ttable._rows;
    this.tableStateProvider.clearTableState();
    this.frozen = state.fixed;
    this.columns = this.defineTableColumns(false);
    if (this.frozen) {
      this.frozenColumns = this.defineTableColumns(true);
    }
    this.colHeight = state.colHeight;
    /** dtrows wiederherstellen */
    this.dtrows = dtrowsSave;
    setTimeout(() => {
      this.tableChangedSubscription = this.turboTable.changes.subscribe(_ => {
        this.tableStateProvider.initTable(this.ttID, this.turboTable.first, [...this.frozenColumns, ...this.columns]);
      });
    }, 100);
  }

  /** Toolbar-Item Status generell setzen bei verschiedenen Events, z.B.
   *  beim Anwählen/Abwählen eines Records
   * @param disabled
   */
  private changeToolbarItemStatus(disabled: boolean) {
    this.breadcrumbService.setToolbarItemDisabled('ASSIGN', disabled);
  }

  /** Initialisierung der Toolbar items */
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'REFRESH', icon: 'fas fas-refresh', disabled: false, tooltip: 'Übersicht aktualisieren', faicon: true});
    tbItems.push({id: 'UNFILTER', iconstack: ['fas fas-filter', 'fas fas-times fas-stacked'], disabled: true, tooltip: 'Alle Filter und Sortierung zurücksetzen', faicon: true});
    if (this.authService.hasRight('PFP_CONFIG_ASSIGN_FORMS') && this.viewMode === ViewMode.VIEW) {
      tbItems.push({id: 'ASSIGN', icon: 'playlist_add_check', disabled: true, tooltip: 'Zur Formularzuweisung (Formularpool) für den angewählten Mandanten', faicon: false});
    }
    if (this.authService.hasRight('PFP_CONFIG_CHANGE_FORMS_ASSIGN') && this.viewMode === ViewMode.BATCHASSIGN) {
      tbItems.push({id: 'SAVE_BATCHASSIGN', icon: 'save', disabled: true, tooltip: 'Formularzuweisung für die angewählten Mandanten speichern', faicon: false});
    }
    if (this.viewMode === ViewMode.BATCHASSIGN) {
      if (this.authService.hasRight('PFP_CONFIG_ADD_FORMS')) {
        tbItems.push({id: 'ADD_FORM', icon: 'note_add', disabled: false, tooltip: 'Weitere Formularanwendung hinzufügen', faicon: false});
      }
    }
    /** CSV-Export */
    subItems.push({id: 'EXPORT_FIlTERED', iconstack: ['fas fas-file-excel-o', 'fas fas-filter fas-stacked'], disabled: true, tooltip: 'Gefilterte Tabelle exportieren', faicon: true});
    subItems.push({id: 'EXPORT_ALL', iconstack: ['fas fas-file-excel-o', 'fas fas-plus fas-stacked'], disabled: false, tooltip: 'Vollständige Tabelle exportieren', faicon: true});
    tbItems.push({id: 'EXPORT', icon: 'fas fas-file-excel-o', disabled: false, tooltip: 'Mandantenübersicht exportieren (csv)', faicon: true, submenu: subItems});
    subItems = [];

    return tbItems;
  }

  /** Tabellenspalten-Initialisierung (Unterscheidung zwischen fixierten und dynamischen Spalten).
   *  Die Primeng Turbotable benötigt die Breite der fixierten Spalten
   *  (frozenWidth wird über die helper-Klasse automatisch berechnet)
   * @param frozenCols
   */
  private defineTableColumns(frozenCols: boolean): TableColumn[] {
    this.frozenWidth = null;
    let cols: TableColumn[] = [];
    if ((this.frozen && frozenCols) || (!this.frozen && !frozenCols)) {
      cols.push({field: 'id', header: 'ID', sort: false, filter: '', hidden: true, style: 'left', width: '0'});
      if (this.viewMode === ViewMode.BATCHASSIGN) {
        let disabled: boolean = !this.authService.hasRight('PFP_CONFIG_CHANGE_FORMS_ASSIGN');
        cols.push({field: 'isAssigned', header: 'Zuweisen', sort: false, filter: 'BOOL', format: 'CHECK', hidden: false, disabled: disabled, style: 'center', filterModel: null, width: '120px', tooltip: 'Neue Formularanwendung diesem Mandanten zuweisen'});
      }
      cols.push({field: 'inst_name', header: 'Name Mandant', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '580px'});
      this.frozenWidth = this.helper.getFrozenColumnsWidth(cols);
      this.frozenHeaderHeight = '40px';
    }
    if (!frozenCols) {
      cols.push(
        {field: 'inst_strasse', header: 'Straße Nr.', sort: true, filter: 'STR', hidden: false, style: 'left', filterModel: '', width: '380px'},
        {field: 'inst_plz', header: 'PLZ', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '100px'},
        {field: 'inst_ort', header: 'Ort', sort: true, filter: 'STR', filterModel: '', hidden: false, style: 'left', width: '400px'},
        {field: 'formsCount', header: 'Anzahl FA', sort: true, filter: '', filterModel: '', hidden: false, style: 'left', width: '110px', tooltip: 'Anzahl der zugewiesenen Formularanwendungen'}
      );
    }
    return cols;
  }

}
