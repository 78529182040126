import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormDetailsReadComponent } from './components/content/form-details-read/form-details-read.component';
import { FormDetailsDeactivateGuard } from './components/content/form-details/form-details-deactivate.guard';
import { FormDetailsComponent } from './components/content/form-details/form-details.component';

import { StartComponent } from './components/content/start/start.component';
import { LogoutComponent } from './components/content/logout/logout.component';
import { LandingPageComponent } from './components/content/landing-page/landing-page.component';

import { AuthorizationGuard } from './services/authorization.guard';
import { SettingsResolver } from './resolver/settings-resolver';
import { SettingsComponent } from './components/content/settings/settings.component';
import { SettingsDeactivateGuard } from './components/content/settings/settings-deactivate.guard';
import { UserInfoComponent } from './components/content/user-info/user-info.component';
import { HomeComponent } from './components/content/home/home.component';
import { UnderConstructionComponent } from './components/content/under-construction/under-construction.component';
import { NotificationViewComponent } from './components/content/notification-view/notification-view.component';
import { ImprintComponent } from './components/content/imprint/imprint.component';
import { PrivacyComponent } from './components/content/privacy/privacy.component';
import { ConfigFormsPoolComponent } from './components/content/config-forms-pool/config-forms-pool.component';
import { FormsStockViewComponent } from './components/content/forms-stock-view/forms-stock-view.component';
import { ConfigViewClientsComponent } from './components/content/config-view-clients/config-view-clients.component';
import { ConfigFormsPoolDeactivateGuard } from './components/content/config-forms-pool/config-forms-pool-deactivate.guard';
import { GlobalNotificationSysComponent } from './components/sysadmin/global-notification-sys/global-notification-sys.component';
import { ConfigViewClientsDeactivateGuard } from './components/content/config-view-clients/config-view-clients-deactivate.guard';
import { ConfigFormsArchiveComponent } from './components/content/config-forms-archive/config-forms-archive.component';
import { ConfigViewFormClientsComponent } from './components/content/config-view-form-clients/config-view-form-clients.component';
import { ConfigMailArchiveComponent } from './components/content/config-mail-archive/config-mail-archive.component';
import { FormsAddMultiComponent } from './components/content/forms-add-multi/forms-add-multi.component';

const appRoutes: Routes = [
  {path: '', component: StartComponent, resolve: { options: SettingsResolver },
    children: [
      {path: 'home', component: HomeComponent, canActivate: [AuthorizationGuard]},
      {path: 'settings', component: SettingsComponent, canActivate: [AuthorizationGuard], canDeactivate: [SettingsDeactivateGuard]},
      {path: 'formsstockview', component: FormsStockViewComponent, canActivate: [AuthorizationGuard]},
      {path: 'notifications', component: NotificationViewComponent, canActivate: [AuthorizationGuard]},
      {path: 'notifications/:id', component: NotificationViewComponent, canActivate: [AuthorizationGuard]},
      {path: 'userinfo', component: UserInfoComponent, canActivate: [AuthorizationGuard]},
      {path: 'configformspool', component: ConfigFormsPoolComponent, canActivate: [AuthorizationGuard]},
      {path: 'configformspool/:instid', component: ConfigFormsPoolComponent, canActivate: [AuthorizationGuard], canDeactivate: [ConfigFormsPoolDeactivateGuard]},
      {path: 'configviewclients/:mode', component: ConfigViewClientsComponent, canActivate: [AuthorizationGuard]},
      {path: 'configviewclients/:mode/:formid', component: ConfigViewClientsComponent, canActivate: [AuthorizationGuard], canDeactivate: [ConfigViewClientsDeactivateGuard]},
      {path: 'configviewformclients/:formid', component: ConfigViewFormClientsComponent, canActivate: [AuthorizationGuard]},
      {path: 'configformsarchive', component: ConfigFormsArchiveComponent, canActivate: [AuthorizationGuard]},
      {path: 'configmailarchive', component: ConfigMailArchiveComponent, canActivate: [AuthorizationGuard]},
      {path: 'uc', component: UnderConstructionComponent, canActivate: [AuthorizationGuard]},
      {path: 'imprint', component: ImprintComponent, canActivate: [AuthorizationGuard]},
      // deaktiviert, Datenschutzerklärung nur noch in der APO
      // {path: 'privacy', component: PrivacyComponent, canActivate: [AuthorizationGuard]},
      {path: 'formdetails/:mode', component: FormDetailsComponent, canActivate: [AuthorizationGuard], canDeactivate: [FormDetailsDeactivateGuard]},
      {path: 'formdetails/:mode/:id', component: FormDetailsComponent, canActivate: [AuthorizationGuard], canDeactivate: [FormDetailsDeactivateGuard]},
      {path: 'formdetailsread/:id', component: FormDetailsReadComponent, canActivate: [AuthorizationGuard]},
      {path: 'formsaddmulti', component: FormsAddMultiComponent, canActivate: [AuthorizationGuard]},
      {path: 'globalnotificationsys', component: GlobalNotificationSysComponent, canActivate: [AuthorizationGuard]},
    ]},
  {path: 'logout', component: LogoutComponent},
  {path: 'landing', component: LandingPageComponent, pathMatch: 'full'},
  {path: 'landing/:mode', component: LandingPageComponent, pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
