<p-confirmDialog header="Achtung" icon="" acceptLabel="Ja" rejectLabel="Nein" [closable]="false"
                 [style]="{'max-width':'400px','max-height':'200px'}"></p-confirmDialog>

<div class="ui-g" *ngIf="!loading">
  <div class="ui-lg-8 ui-md-10 ui-sm-12 ui-lg-offset-2 ui-md-offset-1">
    <div class="card">
      <h4>Formulardaten anzeigen</h4>
      <p-panel>
        <form #editForm="ngForm" id="editForm">
          <div class="mt-5 mb-2">
            <div class="ui-g ui-fluid form-group">

              <div class="ui-g-12">
                <label style="color: #264b94">Forms-ID</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.dsvsId}}</div>
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">Formulartitel</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.title}}</div>
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">PDF-Content</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.khId}}</div>
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">PDF-Version</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.khVersion}}</div>
                </span>
              </div>
              <!--
              <div class="ui-g-12">
                <label style="color: #264b94">Forms-Version</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.formsAssistantVersion}}</div>
                </span>
              </div>
              -->
              <div class="ui-g-12">
                <label style="color: #264b94">Standard-Version</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.formsAssistantVersion}}</div>
                </span>
              </div>
              <div class="ui-g-12">
                <label style="color: #264b94">Individual-Version</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.iversion}}</div>
                </span>
              </div>

              <div class="ui-g-12">
                <label style="color: #264b94">Release Notes</label>
                <span class="md-inputfield mt-5">
                  <div>{{formData?.releaseNotes}}</div>
                </span>
              </div>

            </div>

            <div class="flex-card ui-corner-all  ui-g-12" style="padding-top: 1.25em; padding-bottom: 1.25em">
              <div class="ui-g ui-g-nopad">
                <div class="ui-g-12">
                  <span style="font-size: 14px;color: #264b94">Formularanwendung</span>
                  <table class="table-border mt-5">
                    <tbody>
                    <tr>
                      <td width="160px" class="label">
                        Dateiname
                      </td>
                      <td style="word-wrap: break-word">
                        <span *ngIf="formData?.zipFile">{{ formData?.zipFile.originalFileName }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="160px" class="label">
                        Bereitgestellt am
                      </td>
                      <td>
                                                <span
                                                    *ngIf="formData?.zipFile">{{ formData?.zipFile.createdDate | date: 'dd.MM.yyyy' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <button pButton type="button" [disabled]="!formData?.zipFile"
                                (click)="downloadFile()"
                                class="ui-button-text-icon-left dsvs-btn ui-corner-all" style="width: 100%"
                                label="Formularanwendung herunterladen"
                                icon="fa ui-icon-file-download"></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="ui-g-12 mt-2">
              <div class="ui-g-3">
                <button
                    pButton
                    type="button"
                    class="ui-button-text dsvs-btn ui-corner-all mr-btn"
                    label="zurück"
                    (click)="closeDetails()"
                    icon="fa ui-icon-undo">
                </button>
              </div>

            </div>

            <div style="clear:both">
            </div>

          </div>

        </form>

      </p-panel>
    </div>
  </div>
</div>
