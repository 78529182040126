<p-toast [style]="{marginTop: '120px'}">
    <ng-template let-message pTemplate="message">
        <ng-container [ngSwitch]="message.severity">
            <span *ngSwitchCase="'success'" class="ui-toast-icon pi-check"></span>
            <span *ngSwitchCase="'info'" class="ui-toast-icon pi-info-circle"></span>
            <span *ngSwitchCase="'warn'" class="ui-toast-icon pi-exclamation-triangle"></span>
            <span *ngSwitchCase="'error'" class="ui-toast-icon pi-times"></span>
        </ng-container>
        <div class="ui-toast-message-text-content">
            <div class="ui-toast-summary">{{ message.summary }}</div>
            <div class="ui-toast-detail" [innerHTML]="message.detail"></div>
        </div>
    </ng-template>
</p-toast>
<div class="layout-wrapper" (click)="onWrapperClick()"
     [ngClass]="{'layout-wrapper-static': layoutStatic,
                'layout-wrapper-active': mobileMenuActive}">

    <ffb-menu [hideModel]="performLogin"></ffb-menu>

    <div class="layout-main">

        <ffb-topbar></ffb-topbar>
        <ffb-breadcrumb></ffb-breadcrumb>

        <div *ngIf="!performLogin" class="layout-content">
            <div *ngIf="isLoggedIn; then granted else denied"></div>
            <ng-template #granted>
                <router-outlet></router-outlet>
            </ng-template>
            <ng-template #denied>
                <div class="ui-g">
                    <div class="ui-g-12">
                        <div class="card">
                            <div><h2>Login zum PFP muss über das Anwendungsportal erfolgen!</h2></div>
                            <div *ngIf="errorHandler.hasError">
                                <span>Fehlertyp: {{errorHandler.errType}}</span><br>
                                <span>Fehlertitel: {{errorHandler.msgTitle}}</span><br>
                                <span>Fehlertext: </span>
                                <span [innerHTML]="errorHandler.msgBody"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

        <div *ngIf="showDeepLinkPassword" class="layout-content">
            <div class="ui-g" style="margin-top: 5%">
                <div class="ui-g-12 ui-lg-4 ui-md-6 ui-lg-offset-4 ui-md-offset-3">
                    <form #deepLinkPW="ngForm" (ngSubmit)="checkDeepLinkPassword()" >
                        <div class="card card-w-title ui-shadow-1">
                            <h4 style="color:red; margin-block-end: 0.7em">PFP-DirektLink</h4>
                            <span>
                                Das Öffnen eines DirektLinks erfordert die Eingabe Ihres FFB-Passworts:
                            </span>
                            <hr style="margin: 1.5em 0">
                            <div class="mt-5 ui-g ui-fluid">
                                <div class="ui-inputgroup ui-g-12">
                                    <span class="ui-inputgroup-addon"><i class="material-icons">lock</i></span>
                                    <input type="password" pInputText name="password" [(ngModel)]="password" placeholder="Passwort" required minlength="8" autocomplete="new-password">
                                </div>
                            </div>
                            <div class="mt-6" style="text-align: right">
                                <button pButton type="submit" class="dsvs-btn ui-corner-all mr-btn" label="DirektLink öffnen" icon="fa ui-icon-link" iconPos="right" [disabled]="deepLinkPW.invalid"></button>
                                <button pButton type="button" class="dsvs-btn ui-corner-all mr-btn" (click)="cancelDeepLink()" label="Abbrechen" icon="fa ui-icon-cancel" iconPos="right"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <ffb-footer></ffb-footer>
        <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>

    </div>

</div>
