import { Component } from '@angular/core';
import { StartComponent } from '../../content/start/start.component';

@Component({
    selector: 'ffb-footer',
    template: `
        <div class="layout-footer clearfix">
            <img alt="logo-colored" src="../../../../assets/images/smslogo.svg" class="footer-logo"/>
            <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>{{today | date : "yyyy"}} S-Management Services GmbH, Stuttgart&nbsp;&nbsp;&nbsp;<small>v{{ app.version }}</small></span>
            </span>
        </div>
    `
})
export class AppFooterComponent {

    constructor(public app: StartComponent) {
    }

    today = Date.now();

}
