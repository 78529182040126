import { Injectable } from '@angular/core';
import { DeeplinkType } from '../interfaces/deeplink-type';
import { DeeplinkParam } from '../interfaces/deeplink-param';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DeeplinkData } from '../interfaces/deeplink-data';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { RequestResult } from '../interfaces/request-result';
import { AuthorizationService } from './authorization.service';
import { retry } from 'rxjs/operators';
import { LoginStorageData } from '../interfaces/login-storage-data';
import { UserData } from '../interfaces/user-data';
import { HelperClass } from '../classes/helper-class';
import { DeeplinkUserId } from '../interfaces/deeplink-user-id';
import { FfbSettings } from '../classes/ffb-settings';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();

  private scope: string = environment.scope;
  private zapUrl: string = environment.zapurl;
  private zapInit: string = environment.zapinit;

  private type: DeeplinkType;
  private route: string;
  private expireDate: Date;
  private params: DeeplinkParam[];
  private users: number[];
  private _deeplinkUserID: string;
  private _deeplinkID: string;
  private _deeplink: DeeplinkData;
  private _hasLoginStorageData: boolean = false;
  private _loginStorageData: LoginStorageData = null;
  private userData: UserData = null;

  private verificationResult: Subject<RequestResult> = new Subject<RequestResult>();
  private _verified: boolean = false;

  constructor(private http: HttpClient,
              private authService: AuthorizationService,
              private ffbSettings: FfbSettings) {
    this.getLoginStorageData();
    this.zapInit = this.helper.createZAPinit(this.zapInit);
  }


  get deeplinkUserID(): string {
    return this._deeplinkUserID;
  }
  set deeplinkUserID(value: string) {
    this._deeplinkUserID = value;
  }
  get deeplinkID(): string {
    return this._deeplinkID;
  }
  set deeplinkID(value: string) {
    this._deeplinkID = value;
  }
  get deeplink(): DeeplinkData {
    return this._deeplink;
  }
  set deeplink(value: DeeplinkData) {
    this._deeplink = value;
  }
  get hasLoginStorageData(): boolean {
    return this._hasLoginStorageData;
  }
  get loginStorageData(): LoginStorageData {
    return this._loginStorageData;
  }
  get verified(): boolean {
    return this._verified;
  }

  public reset() {
    this.type = null;
    this.route = null;
    this.expireDate = null;
    this.params = [];
    this.users = [];
    this.deeplink = null;
    this._verified = false;
  }

  public init(type: DeeplinkType, route: string, expireDate: Date = null) {
    this.reset();
    this.type = type;
    this.route = route;
    if (expireDate === null) {
      this.expireDate = new Date();
      this.expireDate.setDate(this.expireDate.getDate() + +this.ffbSettings.getGroupSetting('DLNK_EXPIRE', '90'));
    } else {
      this.expireDate = expireDate;
    }
  }

  public addParam(param: DeeplinkParam) {
    this.params.push(param);
  }

  public addUser(userID: number) {
    this.users.push(userID);
  }

  generateDeepLink(jwt: string): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this._deeplink = {
      creatorID: +this.authService.getUserData().result.id,
      type: this.type,
      expire: formatDate(this.expireDate, 'yyyy-MM-dd', 'de'),
      scope: this.scope,
      route: this.route,
      params: this.params,
      users: this.users
    };
    const body = JSON.stringify(this._deeplink);
    return this.http.put<RequestResult>( this.zapUrl + 'deeplink/create/pfp/', body, { headers });
  }

  /** Zusammenbauen der Vollständigen URL (z.B. zu Einbinden ind eine E-Mail
   * @param deepLinkToken
   */
  buildDeepLinkURL(deepLinkToken: string) {
    this.reset();
    return this.ffbSettings.getAppURL() + '/?dlid=' + deepLinkToken;
  }

  getDeepLinkData(): Observable<DeeplinkData>  {
    /** Auth-Key als base-64 encoded string vorbereiten */
    const authKey = btoa(this.scope + ':' + this.zapInit);
    let headers = new HttpHeaders().set('authorization', `Basic ${authKey}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<DeeplinkData>(this.zapUrl + 'deeplink/getdata/pfp/' + this.scope + '/' + this.deeplinkID, { headers } ).pipe(retry(3));
  }

  getDeepLinkUserData(): Observable<UserData>  {
    /** Auth-Key als base-64 encoded string vorbereiten */
    const authKey = btoa(this.scope + ':' + this.zapInit);
    let headers = new HttpHeaders().set('authorization', `Basic ${authKey}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<UserData>(this.zapUrl + 'deeplink/getuserdata/pfp/' + this.scope + '/' + this.deeplinkUserID, { headers } ).pipe(retry(3));
  }

  checkUserPassword(pw: string): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.authService.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    let checkData: DeeplinkUserId = {
      id: this.loginStorageData.uid,
      deeplinkID: this.deeplinkUserID,
      pw: pw
    };
    const body = JSON.stringify(checkData);
    return this.http.put<RequestResult>(this.zapUrl + 'deeplink/checkpassword/pfp/' + this.authService.getUserData().result.id, body, { headers });
  }

  updateDeepLinkUserID(dluserid: string) {
    /** Die DeepLink-ID wurde mit den User-Daten mitgeliefer und wird jetzt im LocalStorage aktualisiert
     */
    this.deeplinkUserID = dluserid;
    if (dluserid === null || dluserid === undefined) {
      try {
        localStorage.removeItem('DLNK');
      } catch (e) {
      }
    } else {
      localStorage.setItem('DLNK', dluserid);
      this.getLoginStorageData();
    }
  }

  /** Überprüfen ob alle Voraussetzungen für den Aufruf des DeepLinks erfüllt sind */
  verifyDeepLinkAccess(): Observable<RequestResult> {
    this._verified = false;
    let verificationResult: RequestResult = {
      userid: this.loginStorageData.uid.toString(),
      success: false,
      msg: 'Unbekannter Fehler, bitte wenden Sie sich an den Support!'
    };

    /** 1. Prüfen ob der DeepLink noch gültig ist
     */
    if (this.helper.StringToDate(this.deeplink.expire) < new Date(Date.now())) {
      verificationResult.success = false;
      verificationResult.msg = 'Die Gültigkeit des DirektLinks ist abgelaufen!';
      this.verificationResult.next(verificationResult);
    } else {
      /** 2. Prüfen ob der User mit der id aus dem LocalStorage
       * in der UserListe in den DeepLink-Daten enthalten ist
       */
      if (!this.deeplink.users.includes(this.loginStorageData.uid)) {
        verificationResult.success = false;
        verificationResult.msg = 'Dieser DirektLink wurde nicht für Ihr Benutzerkonto freigegeben!';
        this.verificationResult.next(verificationResult);
      } else {
        /** 3. User-Daten über die DeepLink-ID als Schlüssel abrufen
         */
        this.getDeepLinkUserData().subscribe( data => {
          this.userData = data;
          if (this.userData.success) {
            /** 4. Prüfen ob der User über die benötigten Rechte verfügt
             */
            let hasDeepLinkRights: boolean = true;
            /* Grundsätzliche DeepLink-Recht für diesen Scope */
            if (!this.userData.result.userrights.includes(this.scope.toUpperCase() + '_EXECUTE_DEEPLINK')) {
              hasDeepLinkRights = false;
            }
            /* Weitere benötigte Rechte für die Route */
            if (hasDeepLinkRights) {
              for (let dlPerm of this.deeplink.permissions) {
                if (!this.userData.result.userrights.includes(dlPerm)) {
                  hasDeepLinkRights = false;
                  break;
                }
              }
            }
            if (!hasDeepLinkRights) {
              verificationResult.success = false;
              verificationResult.msg = 'Ihnen fehlen die benötigten Rechte, um diesen DirektLink aufzurufen!';
              this.verificationResult.next(verificationResult);
            } else {
              /** 6. Autologin im Authorization-Service anstoßen
               */
              this._verified = true;
              verificationResult.success = true;
              verificationResult.msg = 'Auto-Login wird durchgeführt!';
              this.verificationResult.next(verificationResult);
              this.authService.autoLogin(this.userData);
            }
          } else {
            verificationResult.success = false;
            verificationResult.msg = this.userData.msg;
            this.verificationResult.next(verificationResult);
          }
        }, (e: HttpErrorResponse) => {
          verificationResult.success = false;
          if (e.status === 401) {
            verificationResult.msg = 'Keine Berechtigung zum Abrufen der Anwenderdaten über die DirektLink-ID!';
          } else {
            verificationResult.msg = 'Fehler beim Abrufen der Anwenderdaten über die DirektLink-ID!';
          }
          this.verificationResult.next(verificationResult);
        });
      }
    }

    return this.verificationResult.asObservable();
  }

  /** Die endgültige Route (ggf. erweitert um die Routenparameter) zusammensetzen
   */
  routeBuilder(): string {
    let dlRoute: string = this.deeplink.route;
    for (let param of this.deeplink.params) {
      switch (param.name) {
        case 'ROUTESUFFIX':
          dlRoute += '/' + param.value;
          break;
        case 'QUERYPARAM':
          if (dlRoute.includes('?')) {
            dlRoute += '&' + param.value;
          } else {
            dlRoute += '/?' + param.value;
          }
      }
    }
    return dlRoute;
  }



  /** Die DeepLink-ID aus dem Local Storage auslesen und in das JSON-Objekt umwandeln
   */
  private getLoginStorageData() {
    if (localStorage.getItem('DLNK')) {
      try {
        this._hasLoginStorageData = true;
        this.deeplinkUserID = localStorage.getItem('DLNK');
        this._loginStorageData = JSON.parse(atob(this.deeplinkUserID));
      } catch (e) {
        this._hasLoginStorageData = false;
        this._loginStorageData = null;
      }
    } else {
      this._hasLoginStorageData = false;
      this._loginStorageData = null;
    }
  }
}
