import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../services/authorization.service';
import { UserData } from '../../../interfaces/user-data';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  userdata: UserData;
  constructor(private authService: AuthorizationService,
              public breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.setToolbarVisible(false);
    this.userdata = this.authService.getUserData();
  }

}
