import { Component, OnInit } from '@angular/core';
import { AttachmentFile } from '../../../classes/attachment-file';
import { FileUpload, MessageService } from 'primeng';
import { FileType } from '../../../enums/file-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../../../services/authorization.service';
import { FfbSettings } from '../../../classes/ffb-settings';
import { FormsService } from '../../../services/forms.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { MultiUploadLog } from '../../../interfaces/multi-upload-log';
import * as JSZip from 'jszip';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormData } from '../../../interfaces/form-data';
import { HttpErrorResponse } from '@angular/common/http';
import { JsonData } from '../../../interfaces/json-data';

@Component({
  selector: 'pfp-forms-add-multi',
  templateUrl: './forms-add-multi.component.html',
})
export class FormsAddMultiComponent implements OnInit {

  /** Flags */
  loading: boolean = true;
  inProcess: boolean = false;
  processCanceled: boolean = false;

  /** Dateien-Liste */
  uploadFiles: AttachmentFile[] = [];

  /** Log */
  processValue: number = 0;
  processLog: MultiUploadLog[] = [];

  /** Subjects für Metadata-Observer */
  metadataFetched = new Subject<boolean>()
  formCreated = new Subject<boolean>()
  fileUploaded = new Subject<boolean>()

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthorizationService,
              private messageService: MessageService,
              public ffbSettings: FfbSettings,
              private formsService: FormsService,
              private fileUploadService: FileUploadService) {
  }

  ngOnInit() {

    this.loading = false;
  }

  /** Mime-Types der freigegebenen Dateitypen */
  getDocTypes(): string {
    return 'application/zip,.zip';
  }

  initDocs(event, docBrowser: FileUpload) {
    this.uploadFiles = [];
    if (event.currentFiles.length > 0) {
      this.initDoc(event.currentFiles, 0);
    }
  }

  /** File initialisieren - rekursiv */
  initDoc(files: File[], index: number = 0) {
    let upFile: AttachmentFile = new AttachmentFile();
    upFile.initFile(files[index]);
    if (upFile.fileType === FileType.Invalid) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Ungültiges Dateiformat: ' + upFile.fileName,
        detail: 'Dieses Dateiformat wird von FFB PFP für diesen Prozess nicht unterstützt.'
      });
      index++;
      if (index < files.length) {
        this.initDoc(files, index);
      }
    } else if (upFile.fileSize > 20480) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Ungültige Dateigröße: ' + upFile.fileName,
        detail: 'Die Größe der Datei überschreitet die maximal zulässige Größe von 20 MB.'
      });
      index++;
      if (index < files.length) {
        this.initDoc(files, index);
      }
    } else if (upFile.fileSize === 0) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Ungültige Dateigröße: ' + upFile.fileName,
        detail: 'Die Datei hat keinen Inhalt'
      });
      index++;
      if (index < files.length) {
        this.initDoc(files, index);
      }
    } else {
      const subscription: Subscription = this.extractMetaFromZip(upFile).subscribe(success => {
        subscription.unsubscribe();
        if (success) {
          this.uploadFiles.push(upFile);
        }
        index++;
        if (index < files.length) {
          this.initDoc(files, index);
        }
      })
    }
  }

  /** zip-Datei wird entfernt */
  removeDoc(event) {
    if (this.inProcess) {
      event.stopPropagation();
    } else {
      this.uploadFiles = this.uploadFiles.filter(upFile => upFile.fileObject !== event.file)
    }
  }

  /** Massenupload durchführen
   * Formularanwendungen (fa) bereitstellen
   */
  processUpload(docBrowser: FileUpload, index: number = 0) {
    if (index === 0) {
      this.inProcess = true;
      this.processValue = 0;
    }
    const fa: AttachmentFile = this.uploadFiles[index];
    let log: MultiUploadLog = {};
    this.processLog.push(log);
    log.fileName = fa.fileName;
    if (this.processCanceled) {
      log.createOk = 'nein';
      log.uploadOk = 'nein';
      log.message = 'Massenanlage abgebrochen'
      this.messageService.add({
        severity: 'warn',
        summary: 'Massenanlage',
        detail: 'Die Massenanlage wurde abgebrochen.'
      });
    } else {
      /** Formular erstellen */
      const subscription: Subscription = this.createForm(fa.metaData[0], log).subscribe(success => {
        subscription.unsubscribe();
        if (success) {
          /** zip-Upload */
          const subscription: Subscription = this.uploadZip(fa.fileObject, log).subscribe(success => {
            subscription.unsubscribe();
            index++;
            this.processValue = Math.floor((index / this.uploadFiles.length) * 100);
            if (index === this.uploadFiles.length) {
              this.messageService.add({
                severity: 'success',
                summary: 'Massenanlage',
                detail: 'Die Massenanlage ist abgeschlossen.'
              });
              this.inProcess = false;
              this.processCanceled = false;
              this.uploadFiles = []
              docBrowser.clear();
            } else {
              this.processUpload(docBrowser, index);
            }
          });
        } else {
          index++;
          this.processValue = Math.floor((index / this.uploadFiles.length) * 100);
          if (index === this.uploadFiles.length) {
            this.messageService.add({
              severity: 'success',
              summary: 'Massenanlage',
              detail: 'Die Massenanlage ist abgeschlossen.'
            });
            this.inProcess = false;
            this.processCanceled = false;
            this.uploadFiles = [];
            docBrowser.clear();
          } else {
            this.processUpload(docBrowser, index);
          }
        }
      });
    }
  }

  /** Metadaten direkt aus der Formularanwendung (zip) auslesen
   *
   * @private
   */
  private extractMetaFromZip(upFile: AttachmentFile): Observable<boolean> {
    JSZip.loadAsync(upFile.fileObject).then(zip => {
      if (zip.file('metadaten.json')) {
        try {
          zip.file('metadaten.json').async('blob').then(metadata => {
            const reader: FileReader = new FileReader();
            reader.readAsText(metadata);
            reader.onloadend = ((e: any) => {
              const jsonData: string = e.target.result;
              upFile.metaData = JSON.parse(jsonData);
            });
            this.metadataFetched.next(true);
          });
        } catch (e) {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Formularanwendung: ' + upFile.fileName,
            detail: 'Beim Lesen der Metadaten-Datei ist ein Fehler aufgetreten.<br>'
          });
          this.metadataFetched.next(false);
        }
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Formularanwendung: ' + upFile.fileName,
          detail: 'Beim Lesen der Metadaten-Datei ist ein Fehler aufgetreten.<br>'
        });
        this.metadataFetched.next(false);
      }
    });
    return this.metadataFetched.asObservable();
  }

  /** Formular(daten) anlegen
   *
   * @param metaData
   * @param log
   * @private
   */
  private createForm(metaData: JsonData, log: MultiUploadLog): Observable<boolean> {
    let newForm: FormData;
    newForm = {
      dsvsId: metaData.formsid,
      title: metaData.titel,
      khId: metaData.pdfcontent,
      khVersion: metaData.pdfversion,
      formsAssistantVersion: metaData.version,
      releaseNotes: metaData.releasenotes,
      isStandard: metaData.standard === 'ja',
      composerVersion: metaData.composerversion,
      iversion: metaData.iversion
    };
    const subscription: Subscription = this.formsService.createForm(this.authService.getJWT(), newForm).subscribe((data) => {
      subscription.unsubscribe();
      log.createOk = 'ja';
      log.id = data.id;
      this.formCreated.next(true);
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      if (e.status === 405 || e.status === 500) {
        log.createOk = 'nein';
        log.message = 'Die Forms-ID existiert bereits.'
        this.formCreated.next(false);
      } else {
        console.log(e);
        log.createOk = 'nein';
        log.message = e.status + ': ' + e.message
        this.formCreated.next(false);
      }
    });
    return this.formCreated.asObservable();
  }

  /** Zip-Datei hochladen
   *
   * @param upFile
   * @param log
   * @private
   */
  private uploadZip(upFile: File, log: MultiUploadLog): Observable<boolean> {
    const subscription: Subscription = this.fileUploadService.uploadFAFile(this.authService.getJWT(), upFile, log.id).subscribe(result => {
      subscription.unsubscribe();
      if (result.successful) {
        log.uploadOk = 'ja';
        log.message = 'Der Formularassistent wurde angelegt'
        this.fileUploaded.next(true);
      } else {
        log.uploadOk = 'nein';
        log.message = 'Uploadfehler: ' + result.comment
        this.fileUploaded.next(false);
      }
    }, (e: HttpErrorResponse) => {
      subscription.unsubscribe();
      console.log(e);
      log.uploadOk = 'nein';
      log.message = 'Uploadfehler: ' + e.message
      this.fileUploaded.next(false);
    });
    return this.fileUploaded.asObservable();
  }

}
