import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {

  /** Pipe zur Transformation der Notification-Types für die tabellenanzeige
   * @param {string} value
   * @returns {string}
   */
  transform(value: string): string {
    if ( value === 'DOWN') {
      return 'Download';
    } else if (value === 'SYS') {
      return 'Systemnachricht';
    }
  }

}
