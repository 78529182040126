import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { UserData } from '../interfaces/user-data';
import { FfbErrorHandler } from '../classes/ffb-error-handler';
import { InstituteData } from '../interfaces/institute-data';
import { MenuItem } from 'primeng/api';
import { HelperClass } from '../classes/helper-class';


@Injectable()
export class AuthorizationService {

  /** Hilfsklasse */
  private helper = new HelperClass();
  /** Error-Handling */
  errorHandler: FfbErrorHandler;
  private scope: string = environment.scope;
  /** Basis-URL und Parameter zum ZAP REST-Services für die Authentifizierung (SSO) */
  private zapUrl: string = environment.zapurl + 'getssodata/portal/' + this.scope + '/';
  private zapInit: string = environment.zapinit;

  /** Services URL für Backend */
  private endpointRoot: string = environment.backend;

  /** Flag und Subject für Login-Status */
  isLoggedIn: boolean;
  private loginState = new Subject();
  /** Anwenderdaten  */
  userData: UserData;


  constructor(private http: HttpClient) {
    this.zapInit = this.helper.createZAPinit(this.zapInit);
  }

  /** Methode zur Abfrage des aktuellen Login-Zustands und der
   * Prüfung des Status bei erfolgtem Login
   * @returns {boolean}
   */
  checkLogin(): boolean {
    this.errorHandler = new FfbErrorHandler();
    if (sessionStorage.getItem('currentUser')) {
      /** UserData-Object in SessionStorage verfügbar */
      this.userData = JSON.parse(sessionStorage.getItem('currentUser'));
      if (this.userData.success) {
        this.isLoggedIn = true;
        sessionStorage.setItem('jwt', this.userData.result.jwt);
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        this.errorHandler.hasError = true;
        this.errorHandler.errType = 'error';   // ZAP-Anmeldung fehlgeschlagen
        this.errorHandler.msgTitle = 'Die Anmeldung im ZAP ist fehlgeschlagen.';
        this.errorHandler.msgBody = this.userData.msg;
      }
      return this.isLoggedIn;
    } else {
      return false;
    }
  }

  /** Methode für das Nutzerlogin
   * @param {string} ssoid die vom APO übergebene SSOID*/
  login(ssoid: string) {
    this.errorHandler = new FfbErrorHandler();
    this.setLoginDone(false);
    /** Auth-Key als base-64 encoded string vorbereiten */
    const authKey = btoa(this.scope + ':' + this.zapInit);
    let headers = new HttpHeaders().set('authorization', `Basic ${authKey}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get<UserData>(this.zapUrl + ssoid, {headers}).subscribe(udata => {
      this.userData = udata;
      sessionStorage.setItem('currentUser', JSON.stringify(this.userData));
      this.isLoggedIn = this.userData.success;
    }, (e: HttpErrorResponse) => {
      this.isLoggedIn = false;
      this.errorHandler.hasError = true;
      this.errorHandler.errType = 'error';
      this.errorHandler.msgTitle = 'Beim Aufruf des ZAP-Sevices für das Single-Sign-On ist ein Fehler aufgetreten:';
      this.errorHandler.msgBody = e.message;
      this.setLoginDone(true);
    }, () => {
      this.setLoginDone(true);
    });
  }

  /** Auto-Login durchführen (z.B. für Aufruf über DeepLink)
   * @param udata
   */
  autoLogin(udata: UserData) {
    this.setLoginDone(false);
    this.userData = udata;
    sessionStorage.setItem('currentUser', JSON.stringify(this.userData));
    this.isLoggedIn = this.userData.success;
    this.setLoginDone(true);
  }

  /** Methode zur Initialisierung des Observables für
   * das Triggern des Abschlusses des Login-Prozesses */
  setLoginDone(state: boolean) {
    this.loginState.next(state);
  }

  /** Observable für die Überwachung des Login-Prozesses */
  getLoginDone(): Observable<any> {
    return this.loginState.asObservable();
  }

  /** Methode für das Logout des angemeldeten Nutzers */
  logout(): Promise<boolean> {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('jwt');
    this.isLoggedIn = false;
    this.errorHandler.clear();
    /* ggf. TODO Backend über Logout informieren */
    return Promise.resolve(this.isLoggedIn);
  }

  /** Nutzerdaten auf Basis des UserData-Interfaces aus dem Session-Storage abrufen */
  getUserData(): UserData {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  /** Institutsdaten auf Basis des UserData-Interfaces aus dem Session-Storage abrufen */
  getInstituteData(): InstituteData {
    const udata: UserData = JSON.parse(sessionStorage.getItem('currentUser'));
    return udata.result.institute;
  }

  /** JSON Web-Token abrufen */
  getJWT(): string {
    return sessionStorage.getItem('jwt');
  }

  /** Nutzer-Anrede in der Form "Vorname Nachname" abrufen */
  getSalutation(): string {
    if (this.checkLogin()) {
      return this.getUserData().result.firstname + ' ' + this.getUserData().result.lastname;
    } else {
      return 'Gast';
    }
  }

  /** Die Liste mit allen Nutzerrechten abrufen */
  getUserRights(): string[] {
    if (this.getUserData().success) {
      return this.getUserData().result.userrights;
    }
  }

  /** Ein spezifisches Nutzerrecht abfrageb */
  hasRight(right: string): boolean {
    return (this.getUserData().result.userrights.indexOf(right.toUpperCase()) > -1);
  }

  /** Auf das Vorhandensein eines der übergebenen Rechte prüfen */
  hasRightInList(rightsList: string[]): boolean {
    for (let right of rightsList) {
      if (this.getUserData().result.userrights.indexOf(right.toUpperCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  /** Auf Basis der User-Berechtigungen wird die Menüstruktur
   * des Hauptmenüs aufgebaut.
   * Menüpunkte für die der Anwender keine Berechtigung hat werden
   * nicht in das Model aufgenommen.
   */
  getMenuModel(): MenuItem[] {

    let menuModel: MenuItem[] = [];

    /** Home wird zz. grundsätzlich zugelassen, ggf anpassen
     * ----------------------------------------------------- */
    let menuItem: MenuItem = {label: 'Home', icon: 'home', routerLink: ['/home']};
    menuModel.push(menuItem);

    let subItems: MenuItem[] = [];

    /** Formularpool
     * ---------------*/
    /*
    subItems = [];
    if (this.hasRight('PFP_VIEW_FORMS_POOL')) {
      subItems.push({label: 'Gesamtübersicht', icon: 'search', routerLink: ['/formspoolview']});
    }
    if (this.hasRight('PFP_VIEW_FORMS_STOCK')) {
      subItems.push({label: 'Bestandsübersicht', icon: 'check_circle', routerLink: ['/formsstockview']});
    }
    if (this.hasRightInList(['PFP_VIEW_FORMS_POOL'])) {
      menuItem = {label: 'Formularpool', icon: 'storage', items: subItems};
      menuModel.push(menuItem);
    }
    */
    if (this.hasRight('PFP_VIEW_FORMS_STOCK')) {
      menuItem = {label: 'Bestandsübersicht', icon: 'storage', routerLink: ['/formsstockview']};
      menuModel.push(menuItem);
    }

    /** Notification
     * ---------------*/
    menuItem = {label: 'Benachrichtigungen', icon: 'notifications', routerLink: ['/notifications']};
    menuModel.push(menuItem);


    /** Administration
     * ---------------*/
    subItems = [];
    if (this.hasRight('PFP_CONFIG_VIEW_FORMS_POOL')) {
      subItems.push({label: 'Formularpool', icon: 'library_books', routerLink: ['/configformspool']});
    }
    if (this.hasRight('PFP_CONFIG_VIEW_CLIENTS')) {
      subItems.push({label: 'Mandantenübersicht', icon: 'group', routerLink: ['/configviewclients/view']});
    }
    if (this.hasRight('PFP_CONFIG_VIEW_FORMS_ARCHIVE')) {
      subItems.push({label: 'Formulararchiv', icon: 'archive', routerLink: ['/configformsarchive']});
    }
    if (this.hasRight('PFP_CONFIG_VIEW_INFOMAIL_ARCHIVE')) {
      subItems.push({label: 'Info-Mail Archiv', icon: 'email', routerLink: ['/configmailarchive']});
    }
    if (this.hasRight('PFP_DEVTEST_NOTIFICATION_DUMMY')) {
      subItems.push({label: 'Dummy-Notification', icon: 'message', command: (event) => { this.createDummyNotification(); } });
    }
    if (this.hasRight('PFP_CONFIG')) {
      menuItem = {label: 'Administration', icon: 'settings_application', items: subItems};
      menuModel.push(menuItem);
    }

    /** Impressum und Datenschutz
     * --------------------------*/
    menuItem = {label: 'Impressum', icon: 'business', routerLink: ['/imprint']};
    menuModel.push(menuItem);
    // deaktiviert, Datenschutzerklärung nur noch in der APO
    /*menuItem = {label: 'Datenschutz', icon: 'block', routerLink: ['/privacy']};
    menuModel.push(menuItem);*/

    /** System-Administration
     * ----------------------*/
    subItems = [];
    if (this.hasRight('PFP_SYS_COMMON_NOTIFICATION')) {
      subItems.push({label: 'Global-Notification', icon: 'notifications_active', routerLink: ['/globalnotificationsys']});
    }
    if (this.hasRight('PFP_CONFIG_SYS')) {
      menuItem = {label: 'System-Administration', icon: 'build', items: subItems};
      menuModel.push(menuItem);
    }

    /* Model ausliefern */
    return menuModel;

  }

  /** !!!!!!!!!!!!!!!!!!!!!!! ENTWICKLERTESTS !!!!!!!!!!!!!!!!!!!!!!!!!
   * NOPRODUCTION
   */
  createDummyNotification() {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    this.http.get<boolean>(this.endpointRoot + 'notification/user/generatedefault', {headers}).subscribe();
  }

}
