<p-confirmDialog header="Bestätigung" icon="" acceptLabel="Ja" rejectLabel="Nein" [closable]="false"></p-confirmDialog>
<div class="ui-g">
  <div class="ui-lg-6 ui-md-8 ui-sm-12 ui-lg-offset-3 ui-md-offset-2">
    <div class="card">
      <p-panel header="In-App Benachrichtigung an alle PFP-Anwender">
        <form #globalMessageForm="ngForm">
          <div class="ui-g ui-fluid">
            <div class="ui-g-12 ui-md-6">
              <div class="mb-2">
                <label  class="form-label">Benachrichtigungs-Status</label>
              </div>
              <p-dropdown [options]="severityOptions" name="severity" [(ngModel)]="severity" optionLabel="label" title="Benachrichtigungs-Status (Farbe)" [required]="true"></p-dropdown>
            </div>
            <div class="ui-g-12 ui-md-6 mt-check">
              <p-checkbox name="isSticky" [(ngModel)]="isSticky" binary="true" label="Nachricht bleibt angepinnt (sticky)"></p-checkbox>
            </div>
            <div class="ui-g-12 ui-md-6 mt-5">
              <div class="ui-g ui-fluid ui-g-nopad">
                <div class="ui-g-12 ui-g-nopad">
                  <span class="md-inputfield">
                    <input type="text" pInputText name="msgTitle" [(ngModel)]="msgTitle" maxlength="35" [required]="true">
                    <label [ngClass]="{'text-red': globalMessageForm.form.controls.isSticky?.invalid}">Titel</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-g-nopad mt-5">
                  <div class="mb-2">
                    <label class="form-label" [ngClass]="{'text-red': globalMessageForm.form.controls.expireDate?.invalid}">Ablaufdatum</label>
                  </div>
                  <p-calendar name="expireDate" [(ngModel)]="expireDate" dateFormat="dd.mm.yy" [locale]="de" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearrange"></p-calendar>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6 mt-2">
              <div class="mb-2">
                <label  class="form-label" [ngClass]="{'text-red': globalMessageForm.form.controls.msgBody?.invalid}">Benachrichtigung</label>
              </div>
              <p-editor name="msgBody" [(ngModel)]="msgBody" [style]="{'height':'200px'}" [required]="true">
                <p-header>
                  <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold" title="Fett"></button>
                      <button class="ql-italic" aria-label="Italic" title="Kursiv"></button>
                      <button class="ql-underline" aria-label="Underline" title="Unterstrichen"></button>
                  </span>
                </p-header>
              </p-editor>
            </div>
          </div>
        </form>
      </p-panel>
    </div>
  </div>
</div>
