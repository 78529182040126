import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../services/authorization.service';
import { StartComponent } from '../../content/start/start.component';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { NotificationData } from '../../../interfaces/notification-data';
import { FileDownloadService } from '../../../services/file-download.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';

@Component({
  selector: 'ffb-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit, OnDestroy {

  /** Subscriptions für den Notification-Service */
  notificationRefresh: Subscription;
  notificationAlert: Subscription;

  /** Liste der neu eingegangenen Nachrichten zum Einblenden als Growl-Messages */
  newNotifications: NotificationData[] = [];

  constructor(public app: StartComponent,
              private router: Router,
              private authService: AuthorizationService,
              private messageService: MessageService,
              public notificationService: NotificationService,
              private downloadService: FileDownloadService) { }

  ngOnInit() {
    /** Notificatione Refresh-Trigger abonnieren um festzustellen ob
     * nach der Initialisierung neue oder ungelesene Notifications verfübgar sind
     * Falls es die erste Initialisierung ist muss auch noch der Benachrichtigungs-
     * Alarm abonniert werden*/
    this.notificationRefresh = this.notificationService.notificationRefresh.subscribe( result => {
      if (result === true) {
        /** Ungelesene Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.notificationListUnread.length > 0) {
          let singleMulti: string = 'Benachrichtigung';
          if (this.notificationService.notificationListUnread.length > 1) {
            singleMulti += 'en';
          }
          this.messageService.add({sticky: true, severity: 'info', summary: 'PFP-Anwenderinformation', detail: 'Sie haben insgesamt ' + this.notificationService.notificationListUnread.length + ' ungelesene ' + singleMulti});
        }
        /** Neue Nachricht(en) (einzahl/mehrzahl) */
        if (this.notificationService.newNotificationCount > 0) {
          let singleMulti: string = 'Benachrichtigung';
          if (this.notificationService.newNotificationCount > 1) {
            singleMulti += 'en';
          }
          this.messageService.add({sticky: true, severity: 'success', summary: 'Benachrichtigungsservice', detail: 'Sie haben ' + this.notificationService.newNotificationCount + ' neue ' + singleMulti});
        }
        if (!this.notificationAlert) {
          /** Notificatione Alarm abonnieren um festzustellen ob neue Notifications verfübgar sind */
          this.notificationAlert = this.notificationService.notificationAlert.subscribe( result => {
            if (result) {
              /** Die neu eingegangenen Nachrichten abholen */
              this.notificationService.getNewNotifications(this.authService.getJWT()).subscribe( data => {
                /** Einzelne Notifications anzeigen */
                if (data.length > 0) {
                  /** Polling stoppen */
                  this.notificationService.stopPolling();
                  /** Neue Nachrichten als abgehlt markieren */
                  this.notificationService.markNotificationsAsFetched(this.authService.getJWT());
                  this.newNotifications = [];
                  /** Die einzelnen neuen Nachrichten je nach Typ anzeigen */
                  for (let notification of data) {
                    /** als ungelesen markieren und zur Liste hunzufügen */
                    notification.readType = 0;
                    this.newNotifications.push(notification);
                    let detail: string = notification.msg_text;
                    /** HTML Injection funktioniert nicht mit Sicherheitsrelevanten Tags/Script
                    if (notification.msg_has_link) {
                      // Je nach Notification-Type wird ein anderer Link aufgebaut (Download, router-Link...)
                      switch (notification.type) {
                        case 'DOWN':
                          if (notification.file) {
                            detail = detail.concat('<br><br>Direkt zum Dateidownload:<br>', '<a href="" routerLink="/notifications/', notification.file.id.toString(), '">', notification.msg_link_text, '</a>');
                            console.log(detail);
                          }
                          break;
                      }
                    }
                    */
                    this.messageService.add({sticky: true, severity: 'info', summary: notification.msg_title, detail: detail});
                  }
                  /** Die neuen Nachrichten zu den Listen hinzufügen */
                  this.notificationService.addNewNotifications(this.newNotifications);
                  /** Polling wieder starten */
                  this.notificationService.startPolling(this.authService.getJWT());
                }
              });
            }
          });
        }
        this.notificationRefresh.unsubscribe();
      } else {
        if (this.notificationService.errorHandler.hasError) {
          this.messageService.add({sticky: true, severity: this.notificationService.errorHandler.errType, summary: this.notificationService.errorHandler.msgTitle, detail: this.notificationService.errorHandler.msgBody});
          this.notificationService.errorHandler.clear();
          if (this.notificationAlert) {
            this.notificationAlert.unsubscribe();
            this.notificationService.stopPolling();
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.notificationRefresh) {
      this.notificationRefresh.unsubscribe();
    }
    if (this.notificationAlert) {
      this.notificationAlert.unsubscribe();
    }
  }

  /** Eine Notification wurde im Menü angewählt */
  onNotificationClick(nfID: number) {
    let notification = this.notificationService.notificationListTotal.find(item => item.id === nfID);
    /** je nach Art der Notification werden andere Actions ausgelöst */
    if (notification) {
      /** Notification Growls ausblenden) */
      this.messageService.clear();
      /** Notification als gelesen markieren */
      let nfIDs: number[] = [nfID];
      this.notificationService.markNotificationAsRead(this.authService.getJWT(), nfIDs);
      /** Falls die Notification-View Komponente aktiv ist muss kein Routing mehr erfolgen,
       * aber der Click auf das MenuItem muss getriggert werden, damit die Notification-View
       * aktualisiert werden kann (Item in der Tabelle wird selected und expanded)
       */
      if (this.notificationService.isInNotificationView) {
        this.notificationService.triggerMenuItemClick(nfID);
      } else {
        switch (notification.type) {
          case 'SYS':
            this.router.navigate(['/notifications/' + nfID] );
            break;
          case 'DOWN':
            this.router.navigate(['/notifications/' + nfID] );
            break;
        }
      }
    }
  }

  /** Service-Implementierung zum direkten Download von Dateien aus der
   * Notification - über den generierten Direktlink
   */
  directDownload(fileID: number) {
    this.downloadService.downloadFile(this.authService.getJWT(), fileID.toString(), 'route');
  }
}
