import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { MenuItem } from 'primeng';

@Component({
  selector: 'ffb-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnDestroy {

  /** Breadcrumb MenuItems Subscription zur Steuerung des BreadCrumbs */
  bcItemsSubscription: Subscription;
  /** Toolbar MenuItems Subscription zur Steuerung des BreadCrumbs */
  tbItemsSubscription: Subscription;
  /** Toolbar visible Subscription zur Steuerung der Toolbar */
  tbSubscription: Subscription;
  /** Toolbar Submenü Reset */
  tbResetSubscription: Subscription;

  menuItems: MenuItem[];
  toolbarItems: ToolbarItem[];
  toolbarMainItem: ToolbarItem;
  showToolbar: boolean = false;
  showToolbarSubmenu: boolean = false;
  isHome: boolean = true;

  constructor(public breadcrumbService: BreadcrumbService) {
    this.bcItemsSubscription = breadcrumbService.menuItemsHandler.subscribe(response => {
      this.menuItems = response;
      this.isHome = (this.menuItems.length === 0);
    });
    this.tbItemsSubscription = breadcrumbService.toolbarItemsHandler.subscribe(response => {
      this.toolbarItems = response;
    });
    this.tbSubscription = breadcrumbService.showToolbarHandler.subscribe(response => {
      this.showToolbar = response;
      this.toolbarMainItem = null;
      this.showToolbarSubmenu = false;
    });
    this.tbResetSubscription = breadcrumbService.resetToolbarSubmenuHandler.subscribe( response => {
      if (response) {
        this.toolbarMainItem = null;
        this.showToolbarSubmenu = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.bcItemsSubscription) {
      this.bcItemsSubscription.unsubscribe();
    }
    if (this.tbItemsSubscription) {
      this.tbItemsSubscription.unsubscribe();
    }
    if (this.tbSubscription) {
      this.tbSubscription.unsubscribe();
    }
    if (this.tbResetSubscription) {
      this.tbResetSubscription.unsubscribe();
    }
  }

  /** Den Click-Event mit dem Toolbar-Item für die Subscriber im
   * BreadCrumb auslösen
   * @param {ToolbarItem} item
   */
  toolClicked(item: ToolbarItem) {
    this.showToolbarSubmenu = false;
    this.toolbarMainItem = null;
    this.breadcrumbService.raiseToolClickedEvent(item);
  }

  /** Click-Event auf ein Toolbar-Item mit Submenüs
   *  @param {ToolbarItem} item */
  toggleToolbarSubmenu(item: ToolbarItem) {
    if (this.toolbarMainItem) {
       if (this.toolbarMainItem === item) {
         this.showToolbarSubmenu = false;
         this.toolbarMainItem = null;
         this.breadcrumbService.mainToolbarItemClicked = false;
       } else {
        this.breadcrumbService.mainToolbarItemClicked = true;
         this.toolbarMainItem = item;
       }
    } else {
      this.toolbarMainItem = item;
      this.showToolbarSubmenu = true;
      this.breadcrumbService.mainToolbarItemClicked = true;
    }
  }
}
