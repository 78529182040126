<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
    <meta name="apple-mobile-web-app-capable" content="yes"/>
    <title>Die Formularfabrik logout</title>
</head>
<body class="landing-body">
<div class="landing-wrapper">
    <div id="header">
        <div>
            <img [src]="appBranding" class="logo-colored landing-logo" style="margin-left: 0 !important;"/>
            <a id="landing-menu-button" href="#">
                <i class="material-icons">menu</i>
            </a>
        </div>
    </div>
    <div id="introduction">
        <div class="content">
            <div class="ui-g login-logout ui-g-nopad">
                <div class="ui-lg-4 ui-md-6 ui-sm-12 image-box ui-g-nopad">
                    <div class="card card-nopad">
                        <div class="app-logo">
                            <img alt="logo-colored" [src]="appLogo" class="logo"/>
                        </div>
                        <div class="logout-msg">
                            <span class="text-red" style="font-size: 1.4em">{{ infoTitle }}</span>
                        </div>
                        <div class="logout-info">
                            <p [innerHTML]="infoMessage | safeHtml"></p>
                            <div *ngIf="showLoginLink" class="ui-g ui-fluid ui-g-nopad">
                                <div class="ui-g-12 ui-g-nopad">
                                    <a class="ui-button ui-corner-all dsvs-btn" [href]="apoURL">
                                        <span class="ui-button-text"><i class="material-icons"
                                                                        style="float: right; margin: 4px 2px 0 0">send</i>zum FFB-Login</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui-lg-4 ui-md-8 ui-sm-12" style="padding-top: 2%; margin-left: 3%">
                    <img src="../../../../assets/images/back_text.png">
                </div>
            </div>
        </div>
    </div>

    <div id="features">
        <div>
            <div class="feature-title">
                <span>Wir sind für Sie da</span>
            </div>

            <div class="ui-g">
                <div class="ui-g-12 ui-md-4 feature-box">
                    <div>
                        <h2>Kontakt</h2>
                        <p style="margin: 8px 0 0;">
                            S-Management Services GmbH<br>
                            Am Wallgraben 115<br>
                            70565 Stuttgart<br>
                            Deutschland<br>
                            Telefon: +49 711 782 129-00<br>
                            E-Mail: <a href="mailto:info@s-management-services.de">info@s-management-services.de</a><br>
                        </p>
                    </div>
                </div>

                <div class="ui-g-12 ui-md-4 feature-box">
                    <div>
                        <h2>Support</h2>
                        <div class="frame div-h-center mt-5">
                            <i class="material-icons">email</i>
                            <span style="margin-left: 0.5em;">{{ appSupportMail }}</span>
                        </div>
                    </div>
                </div>

                <div class="ui-g-12 ui-md-4 feature-box">
                    <div>
                        <h2>Karriere</h2>
                        <p style="margin: 8px 0 0;">
                            Personalmanagement<br>
                            Deutscher Sparkassen Verlag GmbH<br>
                            Am Wallgraben 115<br>
                            70565 Stuttgart<br>
                            E-Mail: personal@dsv-gruppe.de
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="footer">
        <div class="div-h-center">
            <span>© S-Management Services GmbH. Alle Rechte vorbehalten.<br>Vervielfältigung nur mit Genehmigung der S-Management Services GmbH.</span>
            <div class="footer-link">
                <a href="https://www.s-management-services.de/agb.html" target="_blank" rel="noopener noreferrer">AGB</a>
                <a href="https://www.s-management-services.de/datenschutz/" target="_blank"
                   rel="noopener noreferrer">Datenschutz</a>
                <a href="https://www.s-management-services.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a>
            </div>
        </div>
    </div>

</div>
</body>
</html>
