<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}"
     (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
  <div class="sidebar-logo" (click)="app.onToggleMenuClick($event)">
    <a class="navicon-button" title="Toggle Menu" [ngClass]="{'open':app.layoutStatic}"></a>
  </div>
  <div #layoutMenuScroller class="nano">
    <div class="nano-content sidebar-scroll-content">
      <div class="layout-menu-container" (click)="updateNanoScroll()">
        <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" [reset]="reset"></ul>
      </div>
    </div>
  </div>
</div>
