<div class="ui-g" *ngIf="!loading">
  <div class="ui-lg-10 ui-md-12 ui-lg-offset-1">
    <div class="card">
      <h4>Massenanlage: neue Formularanwendungen</h4>
      <div class="ui-g">
        <div class="ui-g-12">
          <p-fileUpload #docBrowser
                        class="ui-button-text dsvs-btn ui-corner-all"
                        [accept]="getDocTypes()"
                        (onSelect)="initDocs($event, docBrowser)"
                        (onRemove)="removeDoc($event)"
                        [multiple]="true"
                        [showUploadButton]="false"
                        [showCancelButton]="false"
                        styleClass="inline-file-list"
                        chooseLabel="Datei(en) auswählen"
                        invalidFileTypeMessageSummary="{0}: Ungültiger Dokumententyp."
                        invalidFileTypeMessageDetail="Erlaubte Typen: *.zip"
                        [disabled]="inProcess">
          </p-fileUpload>
        </div>
        <div class="ui-g-12">
          <div style="display: flex; justify-content: space-between">
            <button
                pButton
                type="button"
                class="ui-button dsvs-btn ui-corner-all"
                label="Massenanlage starten"
                [disabled]="uploadFiles.length === 0 || inProcess"
                (click)="processUpload(docBrowser)">
            </button>
            <button
                pButton
                type="button"
                class="ui-button dsvs-btn ui-corner-all"
                label="Massenanlage abbrechen"
                [disabled]="!inProcess"
                (click)="processCanceled = true">
            </button>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="inProcess">
          <p-progressBar [value]="processValue"></p-progressBar>
        </div>
        <div class="ui-g-12">
          <p-table [value]="processLog" [scrollable]="true" scrollHeight="240px">
            <ng-template pTemplate="caption">
              <div>
                <span>Protokoll der Massenanlage</span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Datei</th>
                <th style="width: 100px" >Anlage OK</th>
                <th>uuid</th>
                <th style="width: 100px">Upload OK</th>
                <th>Info</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
              <tr>
                <td>{{log.fileName}}</td>
                <td style="width: 100px" [ngClass]="{'text-green': log.createOk === 'ja', 'text-red': log.createOk === 'nein'}">{{log.createOk}}</td>
                <td>{{log.id}}</td>
                <td style="width: 100px" [ngClass]="{'text-green': log.uploadOk === 'ja', 'text-red': log.uploadOk === 'nein'}">{{log.uploadOk}}</td>
                <td>{{log.message}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
