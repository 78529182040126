import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceResult } from '../interfaces/service-result';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FileUploadService {

  private endpointRoot: string = environment.backend;

  constructor(private http: HttpClient) { }


  /** Service für den Upload einer Formularanwendung
   * @param {string} jwt
   * @param {File} file
   * @param {string} id
   * @returns {Observable<ServiceResult>}
   */
  uploadFAFile(jwt: string, file: File, id: string) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    let formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<ServiceResult>( this.endpointRoot + 'files/upload/' + id, formdata, { headers, responseType: 'json' });
  }

  /** Service für den Datei-Upload
   * @param {string} jwt
   * @param {File} file
   * @param {string} id
   * @returns {Observable<ServiceResult>}
   */
  uploadFile(jwt: string, file: File, id: string): Observable<ServiceResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    let formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<ServiceResult>( this.endpointRoot + 'formular/company/init/update/uploadfile/' + id, formdata, { headers, responseType: 'json' });
  }

  /** Service für den Dokumentenvergleich auf Basis einer via Upload bereitgestellten XDP-Vergleichsvorlage
   * @param {string} jwt
   * @param {File} file
   * @param {string} id
   * @returns {Observable<ServiceResult>}
   */
  compareXdpUploadFile(jwt: string, file: File, id: string): Observable<ServiceResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    let formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<ServiceResult>( this.endpointRoot + 'function/company/function/comparexdponeupload/' + id + '/aktiv', formdata, { headers, responseType: 'json' });
  }

}
