<div class="ui-g">
  <div class="ui-lg-2 ui-md-4 ui-sm-10 ui-lg-offset-5 ui-md-offset-4 ui-sm-offset-1">
    <p-card title="PFP Entwicklerhinweis" subtitle="Willkommen auf der PFP Baustelle" class="ui-shadow-2" styleClass="ui-shadow-3">
      <p-header>
        <img src="../../../../assets/images/under_construction.png">
      </p-header>
      <div class="text-red">
        Diese Komponente wird in einem der kommenden Sprints umgesetzt.<br>Alle Informationen zu den Sprints und zum Backlog werden über JIRA gesteuert.
      </div>
      <p-footer>
        <i>Ich bin nicht faul, das ist mein Energiesparmodus!</i>
      </p-footer>
    </p-card>
  </div>
</div>
