import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtmlTags'
})
export class StripHtmlTagsPipe implements PipeTransform {

  /** Pipe um alle HTML-Tags aus einem String zu entfernen - zur
   * Anzeige von System-Notifications in der Benachrichtigungstabelle
   * @param value
   */
  transform(value: string): string {
    if (value === null) {
      return '';
    } else {
      return value.replace(/<[^>]*>/g, '');
    }
  }

}
