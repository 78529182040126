import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkToYesNo'
})
export class CheckToYesNoPipe implements PipeTransform {

  /** Pipe zur Transformation true,false -> ja,nein für Tabellen
   * @param {boolean} value
   * @returns {string}
   */
  transform(value: string): string {
    switch (value) {
      case '1':
        return 'ja';
      case '2':
        return 'nein';
      default:
        return '';
    }
  }

}
