<div class="ui-g">
    <div class="ui-lg-6 ui-md-10 ui-sm-12 ui-lg-offset-3 ui-md-offset-1">
        <div class="card">
            <p-panel header="Impressum">
                <div class="ui-g ui-fluid">
                    <div class="ui-lg-6 ui-md-6 ui-sm-12">
                        <p style="line-height: 1.3"><strong>S-Management Services GmbH</strong><br>
                            Am Wallgraben 115<br>
                            70565 Stuttgart<br>
                            Deutschland<br>
                            Telefon: +49 711 782 129-00<br>
                            E-Mail: <a href="mailto:info@s-management-services.de">info@s-management-services.de</a><br>
                            Website: <a href="https://www.s-management-services.de" target="_blank">www.s-management-services.de</a>
                        </p>
                        <p style="line-height: 1.3"><strong>Geschäftsführung:</strong><br>
                            Rainer Birkle, Oliver Gutmann</p>
                        <p style="line-height: 1.3"><strong>Sitz der Gesellschaft:</strong><br>
                            Stuttgart</p>
                    </div>
                    <div class="ui-lg-6 ui-md-6 ui-sm-12">
                        <p style="line-height: 1.3"><strong>Handelsregister:</strong><br>
                            Amtsgericht Stuttgart<br>
                            HRB 720136</p>
                        <p style="line-height: 1.3"><strong>Bankverbindung</strong><br>
                            Baden-Württembergische Bank<br>
                            IBAN: DE49 6005 0101 0002 2331 81<br>
                            BIC: SOLADEST600</p>
                        <p style="line-height: 1.3"><strong>USt-IdNr.:</strong><br>
                            DE205835350</p>
                    </div>
                </div>
            </p-panel>
        </div>
    </div>
</div>

