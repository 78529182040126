import { FileType } from '../enums/file-type.enum';
import { JsonData } from '../interfaces/json-data';

export class AttachmentFile {

  fileObject: File;
  fileType: FileType;
  fileName: string;
  preSelectName: string;
  fileSize: number;
  modifiedDate: Date;
  fileContent: Blob;
  isTBX: boolean;
  error: string;

  /** Für Formularanwendung (bei Massenupload) */
  metaData: JsonData[] = [];

  initFile(file: File) {
    this.fileObject = file;
    this.fileName = file.name;
    this.fileSize = file.size / 1024;
    this.fileType = this.getFileType(file.type);
    this.preSelectName = this.getPreSelectName();
    this.modifiedDate = new Date(file.lastModified);
    this.metaData = null;
  }

  /** Anhand des Mime-Types den Dateityp festlegen
   * hierzu muss noch fuer Browser ohne Minetypes-plugin (wie bei Windows)
   * noch extra nach der file extension nach gefragt werden */
  private getFileType(mimeType: string): FileType {
    switch (mimeType) {
      case 'application/zip':
        return FileType.Zip;
      default:
        if (this.fileName.toLowerCase().endsWith('.zip')) {
          return FileType.Zip;
        }
        return FileType.Invalid;
    }
  }

  /** Bei Pdf-Formularen und Xdp-Vorlagen wird versucht die Formularnummer/
   * den Bausteinnamen aus dem Dateinamen zu extrahieren. Wenn die Nummer etc.
   * extrahiert werden konnte, dann wird diese verwendet um in der Importtabelle
   * ggf. den passenden Importdatensatz anhand der Nummer/Name vorzuselektieren
   */
  getPreSelectName(): string {
    switch (this.fileType) {
      case FileType.Xdp:
      case FileType.Pdf:
        let cleanName: string;
        cleanName = this.fileName.slice(0, -4);
        let underScore: number;
        underScore = this.fileName.indexOf('_');
        if (underScore > 0) {
          cleanName = cleanName.slice(0, underScore);
        }
        return cleanName;
      default:
        return '';
    }
  }
}
