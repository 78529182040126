<p-confirmDialog header="Confirmation" icon="" [style]="{'max-width': '640px'}" acceptLabel="Ja" rejectLabel="Nein" responsive="true"></p-confirmDialog>

<div class="ui-g">
  <div class="ui-g-12 card card-w-title">
    <div style="height: 40px;">
      <h3 style="display: inline">Benachrichtigungen</h3>
    </div>
    <p-table #ttable [first]="first" [loading]="loading" loadingIcon="fa ui-icon-sync" [scrollable]="true" [scrollHeight]="scrollHeight" [columns]="columns"
             [value]="notificationService.notificationListTotal" [rows]="dtrows" [(selection)]="selectedNotification" [autoLayout]="true"
             selectionMode="multiple" [metaKeySelection]="true" (onRowSelect)="onRowSelect($event)" sortField="createDate" [sortOrder]="-1"
             (onRowUnselect)="onRowUnselect($event)" dataKey="id" (onFilter)="onFilter($event)" (onSort)="onSort()" [expandedRowKeys]="expandedRows"
             [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="dtrowsPerPage" [responsive]="true" [stateStorage]="ttStateStorage" [stateKey]="ttID">
      <ng-template pTemplate="caption">
        <div>
          <span>Benachrichtigungen</span>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col width="38px;">
          <col *ngFor="let col of columns" [hidden]="col.hidden" [width]="col.width">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 38px;">
            <i class="fas fas-expand text-red" aria-hidden="true" pTooltip="Tabellenzeile über '&gt;' ausklappen - für erweiterte Daten zur Vorlage"></i>
            <!-- <i class="material-icons text-red" title="Ausklappen für erweiterte Vorlagendaten" style="cursor: default">note_add</i> -->
          </th>
          <th *ngFor="let col of columns" [hidden]="col.hidden" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sort">
            {{col.header}}
            <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th style="width: 38px;"></th>
          <th *ngFor="let col of columns" [hidden]="col.hidden" [ngSwitch]="col.filter">
              <span *ngSwitchCase="'STR'">
                <input pInputText type="text" [(ngModel)]="col.filterModel" (input)="ttable.filter($event.target.value, col.field,'contains')" style="width: calc(100% - 1.25rem)"><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
              </span>
            <span *ngSwitchCase="'BOOL'">
                <p-dropdown [options]="ynOptions" [(ngModel)]="col.filterModel" (onChange)="ttable.filter($event.value,col.field,'equals')" appendTo="body" styleClass="tt-dropdown-filter"></p-dropdown><i class="fas fas-filter tt-filter-icon" aria-hidden="true"></i>
              </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [ngClass]="rowData.readType === 0 ? 'unread' : 'read'" [pSelectableRowIndex]="rowIndex">
          <td>
            <a href="#" [pRowToggler]="rowData">
              <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
            </a>
          </td>
          <td *ngFor="let col of columns" [hidden]="col.hidden">
            <span *ngIf="!col.format">{{rowData[col.field]}}</span>
            <span *ngIf="col.format=='HTML'">{{rowData[col.field] | stripHtmlTags}}</span>
            <span *ngIf="col.format=='DATE'">{{rowData[col.field] | date : 'dd.MM.yyyy'}}</span>
            <span *ngIf="col.format=='YN'">{{rowData[col.field] | boolToYesNo }}</span>
            <span *ngIf="col.format=='NF_TYPE'">{{rowData[col.field] | notificationType }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr>
          <td [attr.colspan]="columns.length - 1" class="ui-expanded-row-content">
            <div *ngIf="rowData['type'] === 'DOWN' || rowData['type'] === 'XRIGHT'">
              <span class="text-red" *ngIf="rowData['type'] !== 'DOWN' && rowData['type'] !== 'XRIGHT'">Keine Datei zum Download verfügbar! </span>
              <button *ngIf="rowData['type'] === 'DOWN' || rowData['type'] === 'XRIGHT'" pButton type="button" class="ui-button dsvs-btn ui-corner-all" label="Datei herunterladen" icon="ui-icon-file-download" (click)="downloadFile(rowData['id'])"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">
            Keine Benachrichtigungen gefunden...
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="mt-4 text-dsvs tt-footer-info">
      <span style="margin-right: 1rem">Benachrichtigungen insgesamt: {{ totalRecords }}</span>
      <span *ngIf="filteredRecords !== totalRecords" style="margin-left: 1rem">
          <i class="fas fas-filter tt-filter-icon"></i>: {{ filteredRecords }}
        </span>
    </div>
  </div>
</div>
