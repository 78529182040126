import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayLength'
})
export class ArrayLengthPipe implements PipeTransform {

  transform(value: any[]): number {
    if (value === null || value === undefined) {
      return 0;
    } else {
      return value.length;
    }
  }

}
