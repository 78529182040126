<div class="layout-breadcrumb">
  <ul>
    <li *ngIf="isHome"><i class="material-icons">home</i></li>
    <li *ngIf="!isHome"><a routerLink="/home"><i class="material-icons">home</i></a></li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="menuItems">
      <li>
        <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
        <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>

  <div class="layout-breadcrumb-options">
    <a routerLink="/logout" pTooltip="Abmelden" tooltipPosition="bottom">
      <i class="material-icons">power_settings_new</i>
    </a>
  </div>

  <div *ngIf="showToolbar" class="layout-breadcrumb-options toolbar-menu">
    <span>Werkzeuge:</span>
    <ng-container *ngFor="let tbItem of toolbarItems">
      <a *ngIf="!tbItem.submenu" [pfpToolbarTool]="tbItem" (toolbarItemClick)="toolClicked($event)" [pTooltip]="tbItem.tooltip" tooltipPosition="bottom" [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
        <div *ngIf="tbItem.iconstack; then multi else single"></div>
        <ng-template #single>
          <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
          <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
        </ng-template>
        <ng-template #multi>
          <i [class]="tbItem.iconstack[0]"></i>
          <i [class]="tbItem.iconstack[1]"></i>
        </ng-template>
      </a>
      <ng-container *ngIf="tbItem.submenu">
        <a [pfpToolbarTool]="tbItem" (toolbarItemClick)="toggleToolbarSubmenu($event)" [pTooltip]="tbItem.tooltip" tooltipPosition="bottom" [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
          <div *ngIf="tbItem.iconstack; then multi else single"></div>
          <ng-template #single>
            <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
            <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
          </ng-template>
          <ng-template #multi>
            <i [class]="tbItem.iconstack[0]"></i>
            <i [class]="tbItem.iconstack[1]"></i>
          </ng-template>
        </a>
        <div class="toolbar-submenu" [ngClass]="{'active-toolbar-submenu':showToolbarSubmenu}" [ngStyle]="{'left':toolbarMainItem?.offsetLeft}">
          <div *ngFor="let tbItem of toolbarMainItem?.submenu">
            <a class="toolbar-subitem" [pfpToolbarTool]="tbItem" (toolbarItemClick)="toolClicked($event)" [pTooltip]="tbItem.tooltip" tooltipPosition="bottom" [ngClass]="{'tool-disabled': tbItem.disabled, 'tool-enabled': !tbItem.disabled, 'fas-stack': tbItem.iconstack}">
              <div *ngIf="tbItem.iconstack; then multi else single"></div>
              <ng-template #single>
                <i *ngIf="tbItem.faicon" [class]="tbItem.icon"></i>
                <i *ngIf="!tbItem.faicon" class="material-icons">{{ tbItem.icon }}</i>
              </ng-template>
              <ng-template #multi>
                <i [class]="tbItem.iconstack[0]"></i>
                <i [class]="tbItem.iconstack[1]"></i>
              </ng-template>
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>


