import { Injectable } from '@angular/core';
import { AppSettings } from '../interfaces/app-settings';
import { Resolve } from '@angular/router';
import { SettingsService } from '../services/settings-service';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsResolver implements Resolve<AppSettings> {

  constructor(private optService: SettingsService) { }

  /** Resolver zum Preload der Anwendungseinstellungen
   * @returns {Observable<AppSettings>}
   */
  resolve(): Observable<AppSettings> {
    return this.optService.getAppSettings();
  }

}
