import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ServiceResult } from '../interfaces/service-result';
import { FfbMessage } from '../interfaces/ffb-message';

@Injectable({
  providedIn: 'root'
})
export class SysAdminService {

  /** Services-URL für Backend */
  private endpointRoot: string = environment.backend;

  constructor(private http: HttpClient) { }

  sendGlobalNotification(jwt: string, message: FfbMessage): Observable<ServiceResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.put<ServiceResult>( this.endpointRoot + 'notification/global/create', JSON.stringify(message), { headers });
  }
}
