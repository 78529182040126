import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormData } from '../interfaces/form-data';
import { ResponseData } from '../interfaces/response-data';
import { ServiceResult } from '../interfaces/service-result';
import { FormsCompanyAssignResign } from '../interfaces/forms-company-assign-resign';
import { RequestResult } from '../interfaces/request-result';

@Injectable()
export class FormsService {

  /** Services-URL für Backend */
  private endpointRoot: string = environment.backend;

  constructor(private http: HttpClient) {
  }

  /** Get single form
   * @param {string} jwt
   * @param {string} id
   * @returns {Observable<ServiceResult>}
   */
  getForm(jwt: string, id: string): Observable<FormData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.get<FormData>(this.endpointRoot + 'forms/' + id, {headers, responseType: 'json'});
  }

  /** Formularassistent create service
   * @param {string} jwt
   * @param {formData} body
   * @returns {Observable<ArticleData>}
   */
  createForm(jwt: string, body: FormData) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.post<FormData>(this.endpointRoot + 'forms', body, {headers, responseType: 'json'});
  }

  /** Formularassistent update service
   * @param {string} jwt
   * @param {formData} body
   * @returns {Observable<ArticleData>}
   */
  saveForm(jwt: string, id: string, body: FormData ) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.put<FormData>(this.endpointRoot + 'forms/' + id, body, {headers, responseType: 'json'});
  }

  deleteForm(jwt: string, id: string) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.delete<ServiceResult>( this.endpointRoot + 'forms/' + id, { headers });
  }

  deleteFormHard(jwt: string, id: string, pw: string) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    /** nach aktuellem Stand kein Masterpasswort mehr
     *
     let params = new HttpParams().set('pw', pw);
     return this.http.delete<ServiceResult>( this.endpointRoot + 'forms/' + id, { headers, params });
     */
    return this.http.delete<ServiceResult>( this.endpointRoot + 'forms/deleteform/' + id, { headers });
  }

  /** Formulararchive - Formular reaktivieren
   * @param jwt
   * @param id
   */
  reactivateForm(jwt: string, id: string ) {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.put<ServiceResult>(this.endpointRoot + 'forms/reactivate/' + id, null , {headers, responseType: 'json'});
  }

  /** Formulare für die Formularübersicht abrufen (LazyLoading)
   * @param jwt
   * @param lazySearchParams
   */
  getFormsLazy(serviceRoute: string, jwt: string, lazySearchParams: string): Observable<ResponseData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    let params = new HttpParams().set('search', lazySearchParams);
    return this.http.get<ResponseData>(this.endpointRoot + serviceRoute, {headers, params});
  }

  /** Eines oder mehrere Formularpakete an einen oder mehrere mandanten zuordnen */
  assignResignFormsToCompany(jwt: string, formsCompany: FormsCompanyAssignResign): Observable<ServiceResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.put<ServiceResult>( this.endpointRoot + 'forms/assign', formsCompany, { headers });
  }

  /** Anzahl der zugewiesenen Formulare eines mandanten abrufen
    * @param jwt
   * @param id
   */
  getClientFormsCount(jwt: string, id: string): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.get<RequestResult>(this.endpointRoot + 'forms/count/' + id, {headers, responseType: 'json'});
  }

  getClientFormsCount2(jwt: string, idList: number[]): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    let params = new HttpParams().set('companyIds', JSON.stringify(idList));
    return this.http.get<RequestResult>(this.endpointRoot + 'forms/assigned', {headers, params, responseType: 'json'});
  }

  /** Einer FA zugeordnete Mandantenliste
   * @param jwt
   * @param id
   */
  getFormClientList(jwt: string, id: string): Observable<ResponseData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    return this.http.get<ResponseData>(this.endpointRoot + 'forms/institutes/' + id, {headers, responseType: 'json'});
  }

    /** Benachrichtigung der Mandanten
     * @param jwt
     * @param id
     */
    notifyInstitutes(jwt: string, id: string): Observable<ServiceResult> {
        let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
        headers = headers.append('content-type', 'application/json; charset=utf-8');
        return this.http.post<ServiceResult>(this.endpointRoot + 'forms/notify/' + id, null,{headers, responseType: 'json'});
    }

  /** E-Mails für das E-Mail-Archiv abrufen (LazyLoading)
   * @param serviceRoute
   * @param jwt
   * @param lazySearchParams
   */
  getMailsLazy(jwt: string, lazySearchParams: string): Observable<ResponseData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    let params = new HttpParams().set('search', lazySearchParams);
    return this.http.get<ResponseData>(this.endpointRoot + 'mails', {headers, params});
  }
}
