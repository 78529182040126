import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FormDetailsReadComponent } from './components/content/form-details-read/form-details-read.component';
import { FormDetailsDeactivateGuard } from './components/content/form-details/form-details-deactivate.guard';
import { StartComponent } from './components/content/start/start.component';
import { LandingPageComponent } from './components/content/landing-page/landing-page.component';
import { LogoutComponent } from './components/content/logout/logout.component';
import { SettingsComponent } from './components/content/settings/settings.component';
import { UserInfoComponent } from './components/content/user-info/user-info.component';

import { MenuComponent, AppSubMenuComponent } from './components/layout/menu/menu.component';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { AppFooterComponent } from './components/layout/footer/app.footer.component';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/layout/breadcrumb/breadcrumb.service';
import { BoolToSuccessPipe } from './pipes/bool-to-success.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { AuthorizationService } from './services/authorization.service';
import { FileDownloadService } from './services/file-download.service';
import { ComponentCommunicationService } from './services/component-communication.service';
import { SettingsService } from './services/settings-service';
import { SettingsResolver } from './resolver/settings-resolver';
import { FfbSettings } from './classes/ffb-settings';

import { AuthorizationGuard } from './services/authorization.guard';
import { SettingsDeactivateGuard } from './components/content/settings/settings-deactivate.guard';
import { HomeComponent } from './components/content/home/home.component';
import { ToolbarToolDirective } from './directives/toolbar-tool.directive';
import { UnderConstructionComponent } from './components/content/under-construction/under-construction.component';
import { BoolToYesNoPipe } from './pipes/bool-to-yes-no.pipe';
import { FileUploadService } from './services/file-upload.service';
import { NotificationService } from './services/notification.service';
import { NotificationViewComponent } from './components/content/notification-view/notification-view.component';
import { NotificationTypePipe } from './pipes/notification-type.pipe';
import { CheckToYesNoPipe } from './pipes/check-to-yes-no.pipe';
import { ToastModule } from 'primeng/toast';
import { PrivacyComponent } from './components/content/privacy/privacy.component';
import { ImprintComponent } from './components/content/imprint/imprint.component';
import { StripHtmlTagsPipe } from './pipes/strip-html-tags.pipe';
import { TableStateProvider } from './classes/table-state-provider';
import { WindowResizeService } from './services/window-resize.service';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DeepLinkService } from './services/deep-link.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LandingPageSubinfo } from './classes/landing-page-subinfo';
import { TableTitleFixedColumnsComponent } from './components/custom/table-title-fixed-columns/table-title-fixed-columns.component';
import {
    AccordionModule,
    AutoCompleteModule, BlockUIModule,
    BreadcrumbModule,
    ButtonModule, CalendarModule,
    CardModule, CarouselModule, CheckboxModule,
    ChipsModule, ConfirmationService,
    ConfirmDialogModule, ContextMenuModule, DataViewModule, DialogModule, DragDropModule,
    DropdownModule, EditorModule, FieldsetModule, FileUploadModule, InputMaskModule,
    InputSwitchModule,
    InputTextareaModule, InputTextModule,
    ListboxModule,
    MegaMenuModule, MenubarModule,
    MenuModule, MessageModule, MessageService,
    MessagesModule,
    MultiSelectModule, OrderListModule, OverlayPanelModule,
    PaginatorModule,
    PanelMenuModule,
    PanelModule,
    PasswordModule,
    PickListModule, ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule, ScrollPanelModule,
    SelectButtonModule,
    SharedModule, SlideMenuModule,
    SliderModule,
    SpinnerModule, SplitButtonModule, StepsModule,
    TableModule,
    TabMenuModule, TabViewModule, TieredMenuModule, ToggleButtonModule,
    ToolbarModule, TooltipModule,
    TreeModule,
    TreeTableModule, TriStateCheckboxModule
} from 'primeng';
import { FormsStockViewComponent } from './components/content/forms-stock-view/forms-stock-view.component';
import { SelectKeyToValuePipe } from './pipes/select-key-to-value.pipe';
import { ArrayLengthPipe } from './pipes/array-length.pipe';
import { FormDetailsComponent } from './components/content/form-details/form-details.component';
import { FormsService } from './services/forms.service';
import { ConfigFormsPoolComponent } from './components/content/config-forms-pool/config-forms-pool.component';
import { ConfigViewClientsComponent } from './components/content/config-view-clients/config-view-clients.component';
import { GlobalNotificationSysComponent } from './components/sysadmin/global-notification-sys/global-notification-sys.component';
import { SysAdminService } from './services/sys-admin.service';
import { ConfigFormsArchiveComponent } from './components/content/config-forms-archive/config-forms-archive.component';
import { ConfigViewFormClientsComponent } from './components/content/config-view-form-clients/config-view-form-clients.component';
import { ConfigMailArchiveComponent } from './components/content/config-mail-archive/config-mail-archive.component';
import { FormsAddMultiComponent } from './components/content/forms-add-multi/forms-add-multi.component';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    MenuComponent,
    AppSubMenuComponent,
    TopbarComponent,
    AppFooterComponent,
    BreadcrumbComponent,
    LogoutComponent,
    LandingPageComponent,
    SettingsComponent,
    UserInfoComponent,
    HomeComponent,
    ToolbarToolDirective,
    AutofocusDirective,
    UnderConstructionComponent,
    BoolToYesNoPipe,
    CheckToYesNoPipe,
    NotificationTypePipe,
    NotificationViewComponent,
    NotificationTypePipe,
    StripHtmlTagsPipe,
    SafeHtmlPipe,
    SelectKeyToValuePipe,
    ArrayLengthPipe,
    ImprintComponent,
    PrivacyComponent,
    TableTitleFixedColumnsComponent,
    FormDetailsComponent,
    FormDetailsReadComponent,
    FormsStockViewComponent,
    ConfigFormsPoolComponent,
    ConfigViewClientsComponent,
    GlobalNotificationSysComponent,
    ConfigFormsArchiveComponent,
    ConfigViewFormClientsComponent,
    ConfigMailArchiveComponent,
      BoolToSuccessPipe,
      SafePipe,
      FormsAddMultiComponent
  ],
    imports: [
        BlockUIModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CheckboxModule,
        ChipsModule,
        ConfirmDialogModule,
        SharedModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MessageModule,
        MultiSelectModule,
        OrderListModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        HttpClientModule,
        AppRoutingModule,
        TriStateCheckboxModule,
        EditorModule,
        ScrollPanelModule
    ],
  providers: [
    SettingsService,
    SettingsResolver,
    BreadcrumbService,
    WindowResizeService,
    MessageService,
    AuthorizationService,
    AuthorizationGuard,
    ConfirmationService,
    ComponentCommunicationService,
    NotificationService,
    SysAdminService,
    FormsService,
    FileDownloadService,
    FileUploadService,
    FfbSettings,
    DeepLinkService,
    TableStateProvider,
    SettingsDeactivateGuard,
    LandingPageSubinfo,
    FormDetailsDeactivateGuard,
    {provide: LOCALE_ID, useValue: 'de'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
