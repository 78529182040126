import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { FormDetailsComponent } from './form-details.component';

@Injectable({
  providedIn: 'root'
})

export class FormDetailsDeactivateGuard implements CanDeactivate<FormDetailsComponent> {

  constructor(private confirmationService: ConfirmationService) {
  }

  canDeactivate(component: FormDetailsComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.editForm.form.dirty && !component.formChanged) {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message: 'Die Daten wurden noch nicht gespeichert.<br>Wollen Sie diese Seite jetzt verlassen?',
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
            component.breadcrumbService.setToolbarVisible(true);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
}
