import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  openTabs: number[];

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Datenschutz'}
    ]);
    /** Gesamtes Dokument anzeigen */
    this.openTabs = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  }

}
