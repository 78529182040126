<div class="ui-g">
  <div class="ui-lg-6 ui-md-8 ui-sm-10 ui-lg-offset-3 ui-md-offset-2 ui-sm-offset-1">
    <div class="card">
      <p-panel header="Ihre persönlichen Daten">
        <div class="text-large">
          <span>{{userdata.result.title}}</span><br>
          <span>{{userdata.result.firstname}}&nbsp;{{userdata.result.lastname}}</span><br>
          <span *ngIf="userdata.result.institute.name">{{userdata.result.institute.name}}</span><br>
          <div class="div-h-center">
            <i class="material-icons">email</i>&nbsp;<span>{{userdata.result.email}}</span><br>
          </div>
          <div class="div-h-center">
            <i class="material-icons">phone</i>&nbsp;<span>{{userdata.result.phone}}</span><br>
          </div>
        </div>
        <p-footer>
          Änderungen an Ihren persönlichen Daten müssen zentral im Anwendungsportal durchgeführt werden.
        </p-footer>
      </p-panel>
    </div>
  </div>
</div>
