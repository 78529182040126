<p-confirmDialog header="Achtung" icon="" acceptLabel="Ja" rejectLabel="Nein" [closable]="false"></p-confirmDialog>
<div class="ui-g">
  <div class="ui-lg-6 ui-md-8 ui-sm-10 ui-lg-offset-3 ui-md-offset-2 ui-sm-offset-1">
    <div class="card">
      <p-panel header="Benutzereinstellungen">
        <form #settingsForm="ngForm" (ngSubmit)="updateSettings()" >
          <div class="mt-5 mb-5">
            <div class="ui-g ui-fluid">
              <div class="ui-g-12">
                <table>
                  <tr>
                    <th>Beschreibung</th>
                    <th>Wert</th>
                  </tr>
                  <ng-container *ngFor="let setting of settings.settings">
                    <tr *ngIf="setting.visible == 1" [ngSwitch]="setting.type">
                      <td>{{ setting.desc }}</td>
                      <td *ngSwitchCase="'BOOL'"><p-dropdown [options]="setting.options" [(ngModel)]="setting.selectvalue"  optionLabel="desc" [name]="setting.key"></p-dropdown></td>
                      <td *ngSwitchCase="'SELECT'"><p-dropdown [options]="setting.options" [(ngModel)]="setting.selectvalue"  optionLabel="desc" [name]="setting.key"></p-dropdown></td>
                      <td *ngSwitchCase="'TEXT'"><input type="text" pInputText [title]="setting.desc" [name]="setting.key" [(ngModel)]="setting.value" maxlength="200"></td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <button pButton type="submit" class="dsvs-btn ui-corner-all" style="margin-right: 1em" label="Übernehmen" icon="pi pi-check" [disabled]="settingsForm.invalid || !settingsForm.form.dirty"></button>
            <button pButton type="button" (click)="resetSettings()" class="dsvs-btn ui-corner-all" label="Zurücksetzen" icon="pi pi-refresh" [disabled]="!settingsForm.form.dirty"></button>
          </div>
        </form>
      </p-panel>
    </div>
  </div>
</div>
