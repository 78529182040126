export class FfbErrorHandler {

  /** ErrType
   * 'info'
   * 'warn'
   * 'error'
   */

  private _hasError: boolean;
  private _errType: string;
  private _msgTitle: string;
  private _msgBody: string;

  constructor() {
    this._hasError = false;
  }

  get hasError(): boolean {
    return this._hasError;
  }
  set hasError(value: boolean) {
    this._hasError = value;
  }
  get errType(): string {
    return this._errType;
  }
  set errType(value: string) {
    this._errType = value;
  }
  get msgTitle(): string {
    return this._msgTitle;
  }
  set msgTitle(value: string) {
    this._msgTitle = value;
  }
  get msgBody(): string {
    return this._msgBody;
  }
  set msgBody(value: string) {
    this._msgBody = value;
  }

  clear() {
    this._hasError = false;
    this._errType = '';
    this._msgTitle = '';
    this._msgBody = '';
  }
}
