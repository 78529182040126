import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { FileDownloadService } from '../../../services/file-download.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { Table } from 'primeng/table';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { TableColumn } from '../../../interfaces/table-column';
import { Subscription } from 'rxjs';
import { ToolbarItem } from '../../../interfaces/toolbar-item';
import { NotificationData } from '../../../interfaces/notification-data';
import { NotificationService } from '../../../services/notification.service';
import { TableStateProvider } from '../../../classes/table-state-provider';
import { HelperClass } from '../../../classes/helper-class';
import { WindowResizeService } from '../../../services/window-resize.service';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { FfbSettings } from '../../../classes/ffb-settings';

@Component({
  selector: 'fopo-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.css']
})
export class NotificationViewComponent implements OnInit, OnDestroy, AfterViewInit {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;
  /* Window Resize Events abonnieren */
  resizeSubscription: Subscription;
  /* Click auf Notification Menü-Item bei aktiver Komponente */
  notificationSubscription: Subscription;
  /** Flags */
  loading: boolean;

  /** Parameter für die DataTable */
  dtrows: number;                   // Anzahl der Zeilen pro Seite aus den Settings
  dtrowsPerPage: number[];          // Mögliche Auswahl für Anzahl der Zeilen pro Seite aus den Settings
  dtScrollVertical: boolean;            // Turbo-Table scollt Vertikal - Header bleiben statisch
  dtSetScrollable: boolean;             // nur wegen IE, edge und geringerer Tabellenbreite, damit die Headerspalten die richtige breite bekommen

  /** JSON Web-Token */
  private jwt: string;

  /** Daten für die TurboTable */
  @ViewChild('ttable') turboTable: Table;
  ttID: string = 'ttnotification';    // Eindeutige ID der Tabelle für den Table-State-Provider
  ttStateStorage: string = 'session'; // Storage für den TableState
  scrollHeight: string;
  columns: TableColumn[];
  totalRecords: number;
  filteredRecords: number;
  first: number = 0;
  selectedNotificationId: string;
  selectedNotification: NotificationData[] = [];
  ynOptions: SelectItem[] = [];
  expandedRows: { [key: string]: boolean } = {};

  constructor(private router: Router,
              private route: ActivatedRoute,
              public breadcrumbService: BreadcrumbService,
              private messageService: MessageService,
              private authService: AuthorizationService,
              public tableStateProvider: TableStateProvider,
              public ffbSettings: FfbSettings,
              public notificationService: NotificationService,
              private confirmationService: ConfirmationService,
              private fileDownloadService: FileDownloadService,
              private resizeService: WindowResizeService) {
  }

  ngOnInit() {
    this.loading = true;
    this.dtSetScrollable = false;
    /** Flag für die Topbar Komponente falls ein Notification-Item geklickt wird */
    this.notificationService.isInNotificationView = true;
    this.jwt = this.authService.getJWT();
    /** Selected Notifications initialisieren */
    this.selectedNotification = [];
    /** Tabellenparameter */
    this.dtrows = +this.ffbSettings.getOptionValue('DTROWS_PFP', 10);
    this.dtScrollVertical = (this.ffbSettings.getOptionValue('DTSCROLL_PFP', false) === 'true');
    /* any-Array zu number-array umwandeln */
    this.dtrowsPerPage = this.ffbSettings.getOptionParameters('DTROWS_PFP', [5, 10, 25, 100]).map(value => +value);
    /* Falls der User die Tabellenhöhe auf die Contenthöhe beschränken will (Tabelle Scrollt Vertikal)... */
    if (this.dtScrollVertical) {
      this.scrollHeight = this.helper.getTableScrollHeight(window.innerHeight);
    } else {
      this.scrollHeight = 'auto';
    }
    /* Definition der Tabellenspalten abrufen */
    /* Tabellenspalten definieren und TableStateProvider initialisieren */
    this.columns = this.defineTableColumns();
    this.tableStateProvider.initTable(this.ttID, this.turboTable, this.columns);
    /**Toolbar initialisierung*/
    this.breadcrumbService.setMenuItems([
      {label: 'Benachrichtigungen'}
    ]);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(true);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.toolbarItemClicked(item);
    });
    /** Die vom Resize-Service ausgelösten Resize-Events abonnieren, falls der user in den Settings die Tabellenhöhe beschränkt hat */
    if (this.dtScrollVertical) {
      this.resizeSubscription = this.resizeService.onResize$.subscribe(win => {
        this.scrollHeight = this.helper.getTableScrollHeight(win.innerHeight);
      });
    }
    /**Parameter für Boolean Filter in der Tabelle setzen*/
    this.ynOptions = [
      {label: '', value: null},
      {label: 'ja', value: 1},
      {label: 'nein', value: 0},
    ];
    /**Expanded auf 0 setzen aus Service übernehmen*/
    this.setExpandedRows();
    /**Falls eine id in der Route angegeben ist (Beispielsweise nach klick auf eine Notification über die Topbar)
     * wird die entsprechende Notification in der Tabelle ausgewählt*/
    if (this.route.snapshot.params['id']) {
      this.selectedNotification.push(this.notificationService.notificationListTotal.find(item => item.id === +this.route.snapshot.params['id']));
      this.first = Math.floor(this.notificationService.notificationListTotal.findIndex(item => item.id === +this.route.snapshot.params['id']) / this.dtrows) * this.dtrows;
      if (this.tableStateProvider.activated) {
        this.tableStateProvider.setSelectedRow(this.selectedNotification);
        this.tableStateProvider.setFirst(this.first);
      }
      this.changeToolbarItemStatus(false);
    }
    /*
    * Parameter für die Anzeige über die Anzahl der gefilterten Notifications in der Tabelle
    * **/
    this.totalRecords = this.notificationService.notificationListTotal.length;
    this.filteredRecords = this.totalRecords;
    /* Table-Status wiederherstellen */
    this.tableStateProvider.restoreTableState();
    setTimeout(() => {
      this.expandedRows[this.route.snapshot.params['id']] = true;
      if (this.tableStateProvider.activated) {
        this.tableStateProvider.setExpandedRows(this.expandedRows);
        this.turboTable.restoreState();
      } else {
        this.filteredRecords = this.totalRecords;
      }
    }, 350);
  }

  ngAfterViewInit() {
    /** Service für die Notification Auswahl im Menü (Glocke) wenn Komponente aktiv ist */
    setTimeout(() => {
      if (!this.notificationSubscription) {
        this.tableStateProvider.initTable(this.ttID, this.turboTable, this.columns);
        this.tableStateProvider.restoreTableState();
        this.notificationSubscription = this.notificationService.notificationItemClicked.subscribe(nfID => {
          this.selectedNotification = [];
          this.selectedNotification.push(this.notificationService.notificationListTotal.find(item => item.id === nfID));
          this.first = Math.floor(this.notificationService.notificationListTotal.findIndex(item => item.id === nfID) / this.dtrows) * this.dtrows;
          setTimeout(() => {
            this.expandedRows[nfID.toString()] = true;
            this.changeToolbarItemStatus(false);
            if (this.tableStateProvider.activated) {
              this.tableStateProvider.setFirst(this.first);
              this.tableStateProvider.setSelectedRow(this.selectedNotification);
              this.tableStateProvider.setExpandedRows(this.expandedRows);
              this.turboTable.restoreState();
            }
          }, 250);
        });
      }
    });
  }

  /**Unsubscribe auf die Toolbar*/
  ngOnDestroy() {
    /* Save Table State */
    this.tableStateProvider.saveTableState();
    /** Flag für die Topbar Komponente falls ein Notification-Item geklickt wird */
    try {
      this.notificationService.isInNotificationView = false;
    } catch (e) {
    }
    try {
      if (this.toolbarSubscription) {
        this.toolbarSubscription.unsubscribe();
      }
    } catch (e) {
    }
    try {
      if (this.resizeSubscription) {
        this.resizeSubscription.unsubscribe();
      }
    } catch (e) {
    }
    try {
      if (!this.notificationSubscription) {
        this.notificationSubscription.unsubscribe();
      }
    } catch (e) {
    }
  }

  /* Alle Zeilen einklappen */
  private setExpandedRows() {
    for (let notification of this.notificationService.notificationListTotal) {
      this.expandedRows[notification.id.toString()] = false;
    }
    if (this.tableStateProvider.activated) {
      this.tableStateProvider.setExpandedRows(this.expandedRows);
    }
    /** Da nun Daten vorhanden sind kann loading auf false gesetzt werden*/
    this.loading = false;
  }

  /** Beim klick auf den Löschen Button wird zunächst der Confirmation Service ausgeführt*/
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Wollen Sie die ausgewählten Benachrichtigungen löschen?',
      header: 'Bestätigung',
      icon: '',
      accept: () => {
        let IdDelete: number[] = [];
        for (let entry of this.selectedNotification) {
          IdDelete.push(entry.id);
        }
        this.notificationService.markNotificationAsDeleted(this.jwt, IdDelete);
        IdDelete = [];
      }
    });
  }

  /** Clickhandler für die Toolbar, aktuell wird falls notwendig die Tabelle mit einem timeout aktualisiert da die
   * korrekte Darstellung der änderung von der Response Zeit des Backends abhängt, der timeout wert muss eventuell angepasst werden*/
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'REFRESH':
        this.setExpandedRows();
        break;
      case 'UNFILTER':
        this.tableStateProvider.clearTableState();
        this.changeToolbarItemStatus(true);
        this.filteredRecords = this.totalRecords;
        this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
        break;
      case 'READ':
        let IdRead: number[] = [];
        for (let entry of this.selectedNotification) {
          IdRead.push(entry.id);
        }
        this.notificationService.markNotificationAsRead(this.jwt, IdRead);
        IdRead = [];
        break;
      case 'UNREAD':
        let IdUnread: number[] = [];
        for (let entry of this.selectedNotification) {
          IdUnread.push(entry.id);
        }
        this.notificationService.markNotificationAsUnread(this.jwt, IdUnread);
        IdUnread = [];
        break;
      case 'DELETE':
        this.confirmDelete();
        break;
      case 'EXPAND':
        this.expandAll();
        break;
      case 'COLLAPSE':
        this.collapseAll();
        break;
    }
  }

  /** Event - Tabellenzeile wurde angewählt */
  onRowSelect(event) {
    this.changeToolbarItemStatus(false);
    this.selectedNotificationId = event.data.id;
  }

  /** Event - Tabellenzeile wurde abgewählt */
  onRowUnselect(event) {
    if (this.selectedNotification.length < 1) {
      this.changeToolbarItemStatus(true);
    }
  }

  /** Ändert den Disabled Status der angegebenen toolbar items*/
  changeToolbarItemStatus(disabled: boolean) {
    this.breadcrumbService.setToolbarItemDisabled('READ', disabled,);
    this.breadcrumbService.setToolbarItemDisabled('UNREAD', disabled,);
    this.breadcrumbService.setToolbarItemDisabled('DELETE', disabled,);
  }

  /** Initialisierung der Toolbar items*/
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'REFRESH', icon: 'fas fas-refresh', disabled: false, tooltip: 'Tabelle aktualisieren', faicon: true});
    tbItems.push({id: 'UNFILTER', iconstack: ['fas fas-filter', 'fas fas-times fas-stacked'], disabled: true, tooltip: 'Alle Filter und Sortierung zurücksetzen', faicon: true});
    tbItems.push({id: 'READ', icon: 'fas fas-eye', disabled: true, tooltip: 'Als \'gelesen\' markieren', faicon: true});
    tbItems.push({id: 'UNREAD', icon: 'fas fas-eye-slash', disabled: true, tooltip: 'Als \'ungelesen\' markieren', faicon: true});
    tbItems.push({id: 'DELETE', icon: 'fas fas-trash-o', disabled: true, tooltip: 'Benachrichtigung löschen', faicon: true});
    tbItems.push({id: 'EXPAND', icon: 'fas fas-expand', disabled: false, tooltip: 'Alle Tabellenzeilen ausklappen', faicon: true});
    tbItems.push({id: 'COLLAPSE', icon: 'fas fas-compress', disabled: false, tooltip: 'Alle Tabellenzeilen zusammenklappen', faicon: true});
    return tbItems;
  }

  /** Alle Table-Rows ausklappen */
  expandAll() {
    for (let notification of this.notificationService.notificationListTotal) {
      this.expandedRows[notification.id.toString()] = true;
    }
    if (this.tableStateProvider.activated) {
      this.turboTable.saveState();
      this.tableStateProvider.setExpandedRows(this.expandedRows);
      this.turboTable.restoreState();
    }
  }

  /** Alle Table-Rows zusammenklappen */
  collapseAll() {
    for (let notification of this.notificationService.notificationListTotal) {
      this.expandedRows[notification.id.toString()] = false;
    }
    if (this.tableStateProvider.activated) {
      this.turboTable.saveState();
      this.tableStateProvider.setExpandedRows(this.expandedRows);
      this.turboTable.restoreState();
    }
  }

  onFilter(event) {
    this.selectedNotification = [];
    this.filteredRecords = event.filteredValue.length;
    if (this.filteredRecords !== this.totalRecords || (Object.keys(event.filters).length > 0)) {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
    } else {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', true);
    }
  }

  /** Sort-Event wurde ausgelöst
   */
  onSort() {
    if (this.totalRecords > 0) {
      this.breadcrumbService.setToolbarItemDisabled('UNFILTER', false);
    }
  }

  downloadFile(id: number) {
    this.fileDownloadService.downloadFile(this.jwt, id.toString(), 'notification/user/down/');
  }

  /** Tabellenspalten (werden ggf. später über die Settings eingelesen
   * und sind dann vom Anwender festlegbar
   * @returns {TableColumn[]} */
  private defineTableColumns(): TableColumn[] {
    let cols: TableColumn[] = [];
    cols.push(
        {field: 'id', header: 'id', sort: false, filter: '', hidden: true, width: '0'},
        {field: 'createDate', header: 'Eingang', sort: true, filter: '', hidden: false, format: 'DATE', width: '120px'},
        {field: 'type', header: 'Art', sort: true, filter: 'STR', filterModel: '', format: 'NF_TYPE', hidden: false, width: '180px'},
        {field: 'readType', header: 'gelesen', sort: true, filter: 'BOOL', filterModel: null, hidden: false, format: 'YN', width: '100px'},
        {field: 'menu_title', header: 'Titel', sort: false, filter: 'STR', filterModel: '', hidden: false, width: '40%'},
        {field: 'msg_text', header: 'Nachricht', sort: false, filter: 'STR', filterModel: '', hidden: false, format: 'HTML', width: '60%'},
        {field: 'expireDate', header: 'Verfügbar bis:', sort: true, filter: '', hidden: false, format: 'DATE', width: '140px'},
        {field: 'file', header: 'fileId', sort: false, filter: '', hidden: true, width: '0'}
    );
    return cols;
  }

}
