import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToYesNo'
})
export class BoolToYesNoPipe implements PipeTransform {

  /** Pipe zur Transformation true,false -> ja,nein für Tabellen
   * @param {boolean} value
   * @returns {string}
   */
  transform(value: boolean): string {
    if (value) {
      return 'ja';
    } else {
      return 'nein';
    }
  }

}
