<div class="ui-g" style="margin-top: 5%">
    <div class="ui-lg-8 ui-md-10 ui-sm-12 ui-lg-offset-2 ui-md-offset-1">
        <div class="card ui-shadow-1">
            <div class="card ui-shadow-1 mb-5"
                 style="display: flex; justify-content: space-between; align-items: center; height: auto">
                <span class="text-red" style="font-size: 1.3em">Herzlich willkommen, {{ authService.getSalutation() }}!</span>
                <img alt="logo" src="../../../../assets/images/logo_ffb_pfp.svg" class="mobile-logo" style="width: 160px;"/>
            </div>
            <div class="card card-fms ui-shadow-1" style="height: auto">
                <div class="text-dsvs mb-2" style="font-size: 1.15em;">Das neue Public Forms Portal (PFP) bietet Ihnen folgende
                    Funktionen:
                </div>
                <div class="ui-g ui-fluid ui-g-nopad" style="font-size: 1.025em">
                    <ul>
                        <li class="mb-1">Sie können Ihre erworbenen Formularanwendungen einfach und beliebig oft in Ihrer
                            Bestandsübersicht herunterladen
                        </li>
                        <li class="mb-1">Die Formularanwendungen sind immer auf dem neuesten Stand – kommt es doch einmal zu
                            Änderungen,
                            werden Sie automatisch per E-Mail darüber informiert und können sich die neue Version hier im PFP
                            herunterladen
                        </li>
                        <li class="mb-1">In der Bestandübersicht suchen und filtern Sie nach Ihren relevanten Schlagworten</li>
                        <li class="mb-1">Exportieren Sie sich Ihre gesamte oder gefilterte Bestandsübersicht als CSV-Datei</li>

                    </ul>
                </div>
                <div class="mt-2" style="font-size: 1.15em;">Details zu den einzelnen Funktionen können Sie dem <a class="text-dsvs"
                                                                                                                   href="https://zap.die-formularfabrik.de/files/docs/PFP_Anwender-Leitfaden.pdf"
                                                                                                                   target="_blank"><u>Anwenderleitfaden</u></a>
                    entnehmen.
                </div>

            </div>

            <br>
            <div class="card ui-shadow-1 mb-5" style="height: auto">
                <div class="contact-header mt-6">
                    Kontakt: <br>
                </div>
                <div class="contact-info clearfix mt-10 mb-10">
                    <ul>
                        <li>
                            <i class="material-icons">call</i>
                            <span>+49 711 782 114-14</span>
                        </li>
                        <li>
                            <i class="material-icons">email</i>
                            <a href="mailto:support@die-formularfabrik.de">support@die-formularfabrik.de&nbsp;</a>
                        </li>
                        <li>
                            <i class="material-icons">link</i>
                            <a href="https://www.s-management-services.de">www.s-management-services.de&nbsp;</a>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</div>
