import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HelperClass } from '../../../classes/helper-class';
import { FfbErrorHandler } from '../../../classes/ffb-error-handler';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { SysAdminService } from '../../../services/sys-admin.service';
import { FfbMessage } from '../../../interfaces/ffb-message';
import { HttpErrorResponse } from '@angular/common/http';
import { ToolbarItem } from '../../../interfaces/toolbar-item';

@Component({
  selector: 'pfp-global-notification-sys',
  templateUrl: './global-notification-sys.component.html',
  styleUrls: ['./global-notification-sys.component.css']
})
export class GlobalNotificationSysComponent implements OnInit, OnDestroy {

  /* Hilfsfunktionen */
  helper: HelperClass = new HelperClass();
  /* Error-Handler */
  error: FfbErrorHandler = new FfbErrorHandler();
  /* Toolbar Events abonnieren */
  toolbarSubscription: Subscription;

  /** Flags und Listen */
  loading: boolean;
  severityOptions: SelectItem[] = [];

  /* Formular */
  @ViewChild('globalMessageForm') formMessageData: NgForm;
  /** Subscription auf die Änderung von Formulardaten,
   * zur Steuerung der Werkzeugleiste und der Werkzeuge*/
  dataChangeSubscription: Subscription;

  /* Model für die Formulardaten */
  severity: SelectItem;
  isSticky: boolean;
  msgTitle: string;
  msgBody: string;
  expireDate: Date;

  /* Kalender-Einstellungen */
  yearrange: string;
  de: any;
  today: Date;

  constructor(private router: Router,
              public breadcrumbService: BreadcrumbService,
              public authService: AuthorizationService,
              private sysAdminService: SysAdminService,
              private messageService: MessageService,
              public confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.loading = true;
    this.de = this.helper.calendarDe();
    this.today = new Date();
    this.yearrange = (this.today.getFullYear()) + ':' + (this.today.getFullYear() + 1);
    /* Benachrichtigungsarten */
    this.severityOptions = [
      { label: 'Erfolg', value: 'success'},
      { label: 'Info', value: 'info'},
      { label: 'Warnung', value: 'warn'},
      { label: 'Fehler', value: 'error'},
    ];
    this.breadcrumbService.setMenuItems([
      {label: 'System-Administration'},
      {label: 'Global-Notification'}
    ]);
    this.breadcrumbService.setToolbarItems(this.initToolbar());
    this.breadcrumbService.setToolbarVisible(false);
    /** Die vom Breadcrumb-Service ausgelösten Toolbar-Events abonnieren */
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe( item => {
      this.toolbarItemClicked(item);
    });
    /* Daten vorbelegen */
    this.severity = this.severityOptions.find( item => item.value === 'info');
    this.isSticky = true;
    this.expireDate = this.today;
    this.expireDate.setDate(this.today.getDate() + 14);
    setTimeout(() => {
      this.breadcrumbService.setToolbarVisible(true);
      this.dataChangeSubscription = this.formMessageData.form.valueChanges.subscribe( data => {
        if (this.formMessageData.form.dirty && !this.formMessageData.form.invalid) {
          this.breadcrumbService.setToolbarItemDisabled('SEND', false);
          this.breadcrumbService.setToolbarItemDisabled('TEST', false);
        } else {
          this.breadcrumbService.setToolbarItemDisabled('SEND', true);
          this.breadcrumbService.setToolbarItemDisabled('TEST', true);
        }
      });
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.dataChangeSubscription) {
      this.dataChangeSubscription.unsubscribe();
    }
  }

  /** Benachrichtigung an alle FMS-User versenden */
  private sendNotification() {
    let message: FfbMessage = {
      sticky: this.isSticky,
      severity: this.severity.value,
      msg_title: this.msgTitle,
      msg_text: this.helper.replaceQuillTags(this.msgBody),
      expireDate: this.expireDate
    };
    this.sysAdminService.sendGlobalNotification(this.authService.getJWT(), message).subscribe( result => {
      if (result.successful) {
        this.resetForm();
        this.messageService.add({
          life: 3500,
          severity: 'success',
          summary: 'Globale Benachrichtigung',
          detail: 'Die Benachrichtigung wurde an alle PFP-Anwender versendet.' });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Globale Benachrichtigung',
          detail: 'Beim Versenden der Benachrichtigung ist ein Fehler aufgetreten:<br>' + result.comment + '<br>Bitte wenden Sie sich an den Support.'});
      }
    }, (e: HttpErrorResponse) => {
      if (e.status === 401) {
        this.messageService.add({
          life: 3500,
          severity: 'error',
          summary: 'Session ist ungültig oder abgelaufen',
          detail: 'Sie werden automatisch abgemeldet.'});
        setTimeout(() => {
          this.router.navigate(['logout']);
        }, 3500);
      } else {
        console.log(e);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Globale Benachrichtigung',
          detail: 'Beim Versenden der Benachrichtigung ist ein Fehler aufgetreten:<br>' + e.message + '<br>Bitte wenden Sie sich an den Support.'
        });
      }
    });
  }

  /* Formular zurücksetzen */
  private resetForm() {
    this.formMessageData.reset();
    setTimeout(() => {
      this.isSticky = true;
      this.severity = this.severityOptions.find( item => item.value === 'info');
      this.expireDate = this.today;
      this.expireDate.setDate(this.today.getDate() + 14);
    }, 100);
  }

  /** Funktionen für die Werkzeugleiste
   * @param item */
  private toolbarItemClicked(item: ToolbarItem) {
    switch (item.id) {
      case 'TEST':
        this.messageService.add({
          sticky: this.isSticky,
          life: 3500,
          severity: this.severity.value,
          summary: this.msgTitle,
          detail: this.helper.replaceQuillTags(this.msgBody) });
        break;
      case 'SEND':
        this.sendNotification();
        break;
      case 'RESET':
        this.resetForm();
        break;
    }
  }

  /** Initialisierung der Toolbar items*/
  private initToolbar(): ToolbarItem[] {
    let tbItems: ToolbarItem[] = [];
    let subItems: ToolbarItem[] = [];
    tbItems.push({id: 'TEST', icon: 'visibility', disabled: true, tooltip: 'Benachrichtigung testen'});
    tbItems.push({id: 'SEND', icon: 'send', disabled: true, tooltip: 'Benachrichtigung senden'});
    tbItems.push({id: 'RESET', icon: 'undo', disabled: false, tooltip: 'Formular zurücksetzen'});
    return tbItems;
  }

}
