import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicInstituteData } from '../interfaces/public-institute-data';
import { UserNameData } from '../interfaces/user-name-data';

@Injectable({
  providedIn: 'root'
})
export class ZapService {

  /** Services-URL für ZAP-Backend */
  private endpointRoot: string = environment.zapurl;

  constructor(private http: HttpClient) {
  }

  /** Liste aller Öffentlichen Auftraggeber aus ZAP
   * @param jwt
   */
  getInstitutesList(jwt: string): Observable<PublicInstituteData[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<PublicInstituteData[]>(this.endpointRoot + 'getpublicinstitutelist/pfp' , { headers });
  }

  /** Daten eines bestimmten Öffentlichen Auftraggebers aus ZAP
   * @param jwt
   */
  getInstituteData(jwt: string, id: string): Observable<PublicInstituteData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<PublicInstituteData>(this.endpointRoot + 'getinstitutedata/pfp/' + id, { headers });
  }

  /** Namens-Details eines Users abrufen
   * @param jwt
   * @param id
   */
  getUserName(jwt: string, id: number): Observable<UserNameData> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<UserNameData>(this.endpointRoot + 'getusername/pfp/' + id, { headers });
  }

  /** Namens-Details einer Liste von Usern abrufen
   * @param jwt
   * @param ids
   */
  getUserNameList(jwt: string, ids: string): Observable<UserNameData[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    let params = new HttpParams().set('userids', ids);
    return this.http.get<UserNameData[]>(this.endpointRoot + 'getusernamelist/pfp', { headers, params });
  }

}
