import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToSuccess'
})
export class BoolToSuccessPipe implements PipeTransform {

  /** Pipe zur Transformation true,false -> ja,nein für Tabellen
   * @param {boolean} value
   * @returns {string}
   */
  transform(value: boolean): string {
    if (value) {
      return 'erfolgreich';
    } else {
      return 'nicht erfolgreich';
    }
  }

}
