import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { FfbErrorHandler } from '../classes/ffb-error-handler';

@Injectable()
export class FileDownloadService {

    private endpointRoot: string = environment.backend;
    errorHandler: FfbErrorHandler = new FfbErrorHandler;

    constructor(private http: HttpClient) {
    }

    /** Service zum Download einer Datei (z.B. Formularpaket eine Formulars mit war und pdf als zip)
     * @param {string} jwt
     * @param {number} formId
     * @param {string} serviceRoot
     * @returns {Observable<boolean>}
     */
    downloadFile(jwt: string, formId: string, serviceRoot: string = 'files/download/'): Observable<boolean> {
        const success = new Subject<boolean>();
        let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
        headers = headers.append('content-type', 'application/json; charset=utf-8');
        let timeStamp = +new Date();
        this.http.get(this.endpointRoot + serviceRoot + formId + '/?tsp=' + timeStamp, {headers, responseType: 'blob', observe: 'response'}).subscribe(response => {
            success.next(this.saveToDisk(response));
        }, (e: HttpErrorResponse) => {
            this.errorHandler.hasError = true;
            this.errorHandler.errType = 'error';
            this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
            this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>File-ID:' + formId + '<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
            console.log(e);
            success.next(false);
        });
        return success.asObservable();
    }

    /** Support-Methode zum Speichern der Downloads
     * @param response
     * @param {number} fileID
     * @returns {boolean}
     */
    private saveToDisk(response: any): boolean {
        try {
            const contentDispositionHeader: string = response.headers.get('Content-Disposition');
            const parts: string[] = contentDispositionHeader.split(';');
            const filename = parts[1].split('=')[1];
            const fileBlob = response.body.slice(0);
            const blob = new Blob([fileBlob]);
            saveAs(blob, filename);
            return true;
        } catch (e) {
            this.errorHandler.hasError = true;
            this.errorHandler.errType = 'error';
            this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
            this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
            console.log(e);
            return false;
        }
    }

    /** Service zum Download einer Datei (z.B. Formularpaket eine Formulars mit war und pdf als zip)
     * @param {string} jwt
     * @param {number} fileId
     * @param {string} serviceRoot
     * @returns {Observable<boolean>}
     */
    downloadZipFile(jwt: string, serviceRoot: string = 'files/zip'): Observable<boolean> {
        const success = new Subject<boolean>();
        let headers = new HttpHeaders().set('authorization', `Bearer ${jwt}`);
        headers = headers.append('content-type', 'application/json; charset=utf-8');
        let timeStamp = +new Date();
        this.http.get(this.endpointRoot + serviceRoot + '/?tsp=' + timeStamp, {headers, responseType: 'blob', observe: 'response'}).subscribe(response => {
            success.next(this.saveToDisk(response));
        }, (e: HttpErrorResponse) => {
            this.errorHandler.hasError = true;
            this.errorHandler.errType = 'error';
            this.errorHandler.msgTitle = 'Fehler beim Datei-Download';
            this.errorHandler.msgBody = 'Beim Download der Datei ist ein Fehler aufgetreten.<br>Bitte wenden Sie sich an den Support.<br>' + e.message;
            console.log(e);
            success.next(false);
        });
        return success.asObservable();
    }
}
