import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsComponent } from './settings.component';
import { Observer } from 'rxjs';
import { ConfirmationService } from 'primeng';

@Injectable()
export class SettingsDeactivateGuard implements CanDeactivate<SettingsComponent> {

  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(component: SettingsComponent) {
    if (!component.settingsForm.dirty) {
      return true;
    }

    return Observable.create((observer: Observer<boolean>) => {
      this.confirmationService.confirm({
        message: 'Die letzten Änderungen wurden noch nicht gespeichert.<br>Wollen Sie diese Seite jetzt verlassen?',
        accept: () => {
          observer.next(true);
          observer.complete();
        },
        reject: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });

  }
}
