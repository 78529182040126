<div class="layout-topbar">
  <img alt="logo" src="../../../../assets/images/logo_ffb_pfp.svg" style="width: 260px" class="mobile-logo"/>

  <a href="#" class="menu-btn" (click)="app.onMenuButtonClick($event)">
    <i class="material-icons">&#xE5D2;</i>
  </a>

  <a href="#" class="topbar-menu-btn" (click)="app.onTopbarMobileMenuButtonClick($event)">
    <i class="material-icons">&#xE853;</i>
  </a>

  <div class="layout-topbar-menu-wrapper">
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-active': app.topbarMenuActive}" (click)="app.onTopbarMenuClick($event)">
      <li class="logo-colored">
        <a routerLink="/home" href="#" style="vertical-align: middle">
          <img src="../../../../assets/images/logo_ffb_pfp.svg" style="width: 260px"/>
        </a>
      </li>
      <li #profile class="profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}" (click)="app.onTopbarRootItemClick($event, profile)">
        <a href="#" style="vertical-align: middle">
          <i class="material-icons">account_circle</i>
          <span class="topbar-item-name profile-name">{{app.salutation}}</span>
        </a>
        <ul class="fadeInDown">
          <li role="menuitem">
            <a routerLink="/userinfo">
              <i class="material-icons">account_circle</i>
              <span>Benutzer</span>
            </a>
          </li>
          <li role="menuitem">
            <a routerLink="/settings">
              <i class="material-icons">settings_application</i>
              <span>Einstellungen</span>
            </a>
          </li>
          <li role="menuitem">
            <a routerLink="/logout">
              <i class="material-icons">exit_to_app</i>
              <span>Abmelden</span>
            </a>
          </li>
        </ul>
      </li>
      <li #notifications [ngClass]="{'active-topmenuitem':app.activeTopbarItem === notifications}" (click)="app.onTopbarRootItemClick($event, notifications)">
        <a href="#">
          <i class="topbar-icon material-icons">notifications</i>
          <span class="topbar-badge animated rubberBand">{{ notificationService.notificationListUnread.length }}</span>
          <span class="topbar-item-name">Notifications</span>
        </a>
        <ul *ngIf="notificationService.notificationListUnread.length === 0" class="fadeInDown">
          <li role="menuitem">
            <p class="text-dsvs" style="margin: 1rem">Sie haben zurzeit keine ungelesenen Benachrichtigungen.</p>
          </li>
        </ul>
        <ul *ngIf="notificationService.notificationListUnread.length > 0" class="fadeInDown">
          <li>
            <ul>
              <li *ngFor="let notify of notificationService.notificationListUnread" role="menuitem" class="notification-item">
                <a href="" (click)="onNotificationClick(notify.id)" [pTooltip]="notify.menu_title" tooltipZIndex="1008" tooltipPosition="left">
                  <i class="material-icons">{{ notify.menu_icon }}</i>
                  <span class="item-text">{{ notify.menu_title }}</span>
                </a>
                <!--
                <button pButton type="button" icon="ui-icon-visibility" pTooltip="Nachricht als gelesen kennzeichnen" tooltipZIndex="1008" class="dsvs-btn-notify"></button>
                -->
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <!--
      <li class="search-item">
        <span class="md-inputfield">
          <input type="text" pInputText />
          <label>Suchen</label>
          <i class="topbar-icon material-icons">search</i>
        </span>
      </li>
      -->
    </ul>
  </div>
</div>
