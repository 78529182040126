import { TableColumn } from '../interfaces/table-column';
import { AngularCsv } from 'angular7-csv';

export class XlsTableExport {

  /* Fehlermeldung */
  errorMsg: string;

  /* Spalten */
  private columns: TableColumn[];

  /* Zeilen */
  private rows: any[] = [];

  /** Initialisierung der Klasse mit Übergabe der Spalten und des Flags ob versteckte Spalten angezeigt werden sollen
   * @param columns
   * @param showHidden
   */
  constructor(columns: TableColumn[], showHidden: boolean = true) {
    if (showHidden) {
      this.columns = columns;
    } else {
      this.columns = columns.filter(item => item.hidden === false);
    }
    this.initColumns();
  }

  /* Spaltenheader initialisieren */
  private initColumns() {
    let csvHeader: {};
    csvHeader = {};
    for (let col of this.columns) {
      csvHeader[col.field] = col.header;
    }
    this.rows.push(csvHeader);
  }

  /** Zeile hinzufügen
   * @param tableDataRow
   */
  addRow(tableDataRow: {}) {
    let csvRow: {};
    csvRow = {};
    for (let col of this.columns) {
      if (tableDataRow[col.field] === undefined || tableDataRow[col.field] === null) {
        csvRow[col.field] = ' ';
      } else {
        switch (col.format) {
          case 'DATE':
            let date = new Date(tableDataRow[col.field]);
            csvRow[col.field] = date.toLocaleDateString();
            break;
          case 'SELECT':
            /* Listen-Key in Listen-Wert */
            if (col.selectList.find(item => item.value === tableDataRow[col.field])) {
              csvRow[col.field] = col.selectList.find(item => item.value === tableDataRow[col.field]).label;
            } else {
              csvRow[col.field] = '';
            }
            break;
          case 'YN':
            if (tableDataRow[col.field] === true) {
              csvRow[col.field] = 'ja';
            }
            if (tableDataRow[col.field] === false) {
              csvRow[col.field] = 'nein';
            }
            break;
          default:
            csvRow[col.field] = tableDataRow[col.field];
        }
      }
    }
    this.rows.push(csvRow);
  }

  /** Die eigentliche Erstellung der Exportdatei
   * @param filename
   * @param csvDelimiter = Trennzeichen (default = Semikolon)
   */
  createExportFile(filename: string, csvDelimiter: string = ';'): boolean {
    let options = {
      fieldSeparator: csvDelimiter,
      nullToEmptyString: true
    };
    try {
      new AngularCsv(this.rows, filename, options);
      return true;
    } catch (e) {
      this.errorMsg = e.message;
      return false;
    }
  }
}
