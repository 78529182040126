import { Injectable } from "@angular/core";
@Injectable()
export class LandingPageSubinfo {
  private _type: number;
  private _desc: string;


  get type(): number {
    return this._type;
  }

  set type(value: number) {
    this._type = value;
  }

  get desc(): string {
    return this._desc;
  }

  set desc(value: string) {
    this._desc = value;
  }
}
