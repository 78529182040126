import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';

@Component({
  selector: 'pfp-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    /* Breadcrumb und Werkzeugleiste */
    this.breadcrumbService.setMenuItems([
      {label: 'Impressum'}
    ]);
  }

}
