import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AppSettings } from '../interfaces/app-settings';
import { UserSettings } from '../interfaces/user-settings';
import { RequestResult } from '../interfaces/request-result';
import { AuthorizationService } from './authorization.service';
import { GroupSettings } from '../interfaces/group-settings';
import { HelperClass } from '../classes/helper-class';

@Injectable()
export class SettingsService {

  private helper = new HelperClass();
  private scope: string = environment.scope;
  private zapUrl: string = environment.zapurl;
  private zapInit: string = environment.zapinit;

  constructor(private http: HttpClient, private authService: AuthorizationService) {
    this.zapInit = this.helper.createZAPinit(this.zapInit);
  }

  /** Service zur Abfrage der globalen Anwendungseinstellungen (Settings)
   * @returns {Observable<AppSettings>}
   */
  getAppSettings(): Observable<AppSettings> {
    const authKey = btoa(this.scope + ':' + this.zapInit);
    let headers = new HttpHeaders().set('authorization', `Basic ${authKey}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<AppSettings>(this.zapUrl + 'getappsettings/pfp/' + this.scope, { headers });
  }

  /** Service zur Abfrage der spezifischen Benutzereinstellungen (Settings)
   * @returns {Observable<UserSettings[]>}
   */
  getUserSettings(): Observable<UserSettings[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.authService.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<UserSettings[]>(this.zapUrl + 'getusersettings/pfp/' + this.authService.getUserData().result.id, { headers });
  }

  /** Update-Service für die Benutzereinstellungen
   * @param {UserSettings[]} settings
   * @returns {Observable<RequestResult>}
   */
  updateUserSettings(settings: UserSettings[]): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.authService.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.put<RequestResult>(this.zapUrl + 'updateusersettings/pfp/' + this.authService.getUserData().result.id, settings, { headers });
  }

  /** Service zur Abfrage der spezifischen Gruppeneinstellungen (Group-Settings)
   * @returns {Observable<GroupSettings[]>}
   */
  getGroupSettings(): Observable<GroupSettings[]> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.authService.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.get<UserSettings[]>(this.zapUrl + 'getgroupsettings/pfp/' + this.authService.getUserData().result.institute_id, { headers });
  }

  /** Update-Service für die Gruppeneinstellungen
   * @param {UserSettings[]} settings
   * @returns {Observable<RequestResult>}
   */
  updateGroupSettings(settings: GroupSettings[]): Observable<RequestResult> {
    let headers = new HttpHeaders().set('authorization', `Bearer ${this.authService.getJWT()}`);
    headers = headers.append('content-type', 'application/json; charset=utf-8');
    return this.http.put<RequestResult>(this.zapUrl + 'updategroupsettings/pfp/' + this.authService.getUserData().result.institute_id, settings, { headers });
  }

}
